.hero-filter {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 10rem min-content;
    @include spacing(padding, bottom, map-get($map: $spacers, $key: section));

    .headline {
        text-align: center;
        margin-bottom: map-get($map: $spacers, $key: 3x);

        &-content {
            @include use-hero-default-ash($white);
        }
    }

    &-background {
        grid-column: 1;
        grid-row: 1 / span 2;
        @include object-fit(cover);
        height: clamp(450px, 80vh, 940px);

        .image,
        img {
            height: 100%;
        }

        img {
            @include object-fit(cover);
        }
    }

    .container {
        grid-column: 1;
        grid-row: 2 / span 2;
        position: relative;
        z-index: 1;
    }

    &-form {
        background-color: $primary;
        color: $white;
        min-height: 100%;
        @include rfs(2.5rem, padding);
        text-align: center;

        mask-image: url($bg-white);
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: top;

        &-headline {
            text-align: left;
            @include rfs(1.5rem, margin-bottom);
            @include use-section-ash($white);
        }

        form {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 1rem;

            .form-select {
                --bs-form-select-bg-img: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath fill=%27none%27 stroke=%27%230068b5%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E');
            }

            .form-control::placeholder {
                color: $primary;
            }

            input[type='text'] {
                height: 100%;
                width: 100%;
                color: $primary;
                padding: $form-select-padding-y $form-select-padding-x;
                border: 0;
            }

            .form-select,
            .form-control {
                background-color: $white;
                color: $primary;
                height: 100%;
            }

            .form-control,
            .form-select,
            input[type='submit'] {
                mask-image: url($bg-white);
                mask-repeat: no-repeat;
                mask-size: cover;
                mask-position: top;
            }

            input[type='submit'] {
                &:hover {
                    background-color: darken($secondary, 5%);
                    color: $white;
                }
            }

            select:invalid,
            select option[value=''] {
                color: $gray-200;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-form {
            form {
                grid-template-columns: 1fr 1fr;

                .fieldset-searchterm,
                input[type='submit'] {
                    grid-column: 1 / span 2;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-inline: 0;

        .container {
            padding-inline: 1.5rem;
        }

        &-form {
            form {
                grid-template-columns: 1fr;

                .fieldset-searchterm,
                input[type='submit'] {
                    grid-column: 1;
                }
            }
        }
    }
}

.filter {
    &-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        &-label {
            font-weight: 600;
            padding-right: 1rem;
            white-space: nowrap;
            padding-block: 0.5rem;
            align-self: flex-start;
        }

        &-clear {
            cursor: pointer;
            padding-inline: 1rem;
            padding-block: 0.5rem;
            background-color: $light-blue;
            mask-image: url($bg-white);
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: top;
            font-weight: 300;
        }
    }

    &-tag {
        padding-inline: 1rem;
        padding-block: 0.5rem;
        background-color: $light-blue;
        mask-image: url($bg-white);
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: top;
        color: $black;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        @include font-size(1rem);

        &-category {
            font-weight: 600;
        }

        &-remove {
            cursor: pointer;
        }
    }
}
