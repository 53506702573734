textarea.form-control {
    min-height: 7.5rem;
}

.form-select,
.form-control {
    border-radius: 0;
    background-color: transparent;
    line-height: 1;
    padding-inline: 1.0625rem;
    padding-block: 1.25rem;
    font-weight: 300;
    color: $white;

    &:focus {
        box-shadow: none;
        background-color: transparent;
        color: $input-placeholder-color;

        &::placeholder {
            opacity: 0.5;
        }
    }
}

.form-select {
    --bs-form-select-bg-img: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E');
    color: $input-placeholder-color;

    option:not(:first-of-type) {
        color: $body-color;
    }

    option[value=''][disabled] {
        display: none;
    }
}

.form-control {
    border-color: $white;
}

.form-label {
    @include use-body();
    margin-bottom: 0;
}

input[type='file'] {
    &::before {
        content: '\e09a';
        font-family: 'Font Awesome 5 Pro';
        padding-right: 2rem;
    }

    &::file-selector-button {
        padding-inline: map-get($map: $spacers, $key: lg);
        display: none;

        &::before {
            content: '\e09a';
            font-family: 'Font Awesome 5 Pro';
        }
    }
}

.accept-privacy {
    margin-top: 0.3em;

    &-text {
        @include use-body();
    }
}

.col-12:has(> input[id*='_function']) {
    display: none;

    input {
        display: none;
        pointer-events: none;
    }
}
