.headline {
    @include rfs(2rem, margin-bottom);

    &-accent {
        color: $primary;
    }

    &-content {
        &.section {
            @include use-section();
        }

        &.section-ash {
            @include use-section-ash();
        }
    }
}
