.link-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 1.5rem;

    &-card {
        background-color: $light-blue;
        height: 100%;

        .label {
            height: 100%;
            font-size: 1rem;
            font-weight: 700;
            color: $black;
            padding-inline: 1.875rem;
            padding-block: 0.9375rem;
            display: flex;
            hyphens: auto;
            justify-content: space-between;
            align-items: center;
            line-break: strict;
        }

        .link {
            height: 100%;

            a {
                height: 100%;
                font-size: 1rem;
                font-weight: 700;
                color: $black;
                padding-inline: 1.875rem;
                padding-block: 0.9375rem;
                display: flex;
                hyphens: auto;
                justify-content: space-between;
                align-items: center;
                line-break: strict;

                &::after {
                    content: '\f054';
                    font-family: 'Font Awesome 5 Pro';
                    color: $primary;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &-card .link a {
            @include font-size(0.875rem);
        }
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        gap: map-get($map: $spacers, $key: sm);

        &-card {
            .label {
                justify-content: center;
                text-align: center;
            }
        }
    }
}
