@mixin grid-layout($item-count) {
    $columns: calc($item-count / 2);

    @if $columns < 2 {
        $columns: 2;
    }

    display: grid;
    grid-template-columns: repeat(#{ceil($columns)}, minmax(0, 260px));
    grid-auto-rows: 1fr;

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, minmax(150px, 260px));
    }
}

$grid-item-counts: (
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24
);

.multistep-initiative-application {
    &-steps {
        background-color: $primary;
        color: $white;
        @include rfs(#{map-get($map: $spacers, $key: section)}, padding-block);

        // .container {
        //     display: flex;
        //     justify-content: center;
        // }

        .step {
            &-head {
                text-align: center;
                margin-bottom: map-get($map: $spacers, $key: 2x);

                h3 {
                    @include use-hero-subline-ash($white);
                }
            }

            &-progress {
                height: 4px;
                background-color: rgba($white, 0.3);
                margin-block: 3rem;
                display: flex;

                .progress-bar {
                    background-color: $white;
                }
            }

            &-body {
                display: grid;
                @include rfs(1.875rem, gap);
                justify-content: center;

                @each $count in $grid-item-counts {
                    &.grid-items-#{$count} {
                        @include grid-layout($count);
                    }
                }

                // &.career_level {
                //     grid-template-columns: repeat(3, minmax(0, 366px));
                //     grid-template-rows: repeat(2, 1fr);
                // }

                // &.locations {
                //     grid-template-columns: repeat(2, minmax(0, 366px));
                //     grid-template-rows: repeat(2, 1fr);
                // }

                // &.fields_of_activity {
                //     grid-template-columns: repeat(3, minmax(0, 366px));
                //     grid-template-rows: repeat(1, 1fr);
                // }

                // &.business_divisions {
                //     grid-template-columns: repeat(5, minmax(0, 366px));
                //     grid-template-rows: repeat(2, 1fr);
                // }
            }

            &-item {
                background-color: $white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $primary;
                gap: 1rem;
                @include rfs(2.125rem, padding-inline);
                @include rfs(3.375rem, padding-block);
                width: clamp(100px, 100%, 366px);
                text-align: center;
                position: relative;
                mask-image: url($bg-card);
                mask-repeat: no-repeat;
                mask-size: cover;
                mask-position: top;

                // &.active {
                //     background-color: $secondary;
                //     color: $white;
                // }

                &:hover {
                    background-color: $secondary;
                    color: $white;

                    a {
                        color: $white;
                    }
                }

                i {
                    @include font-size(3rem);
                }

                p {
                    hyphens: auto;
                }

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    font-size: 0px;
                    z-index: 2;
                    cursor: pointer;
                }

                input[type='radio'] {
                    display: none;
                }
            }

            &-buttons {
                display: grid;
                margin-top: 1rem;

                .submit-btn,
                .prev-btn,
                .next-btn {
                    background-color: transparent;
                    border: 0;
                    outline: 0;
                    color: $white;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    grid-row: 1;
                    padding: 0;

                    i {
                        font-size: 14px;
                    }
                }

                .prev-btn {
                    justify-self: start;
                }

                .submit-btn,
                .next-btn {
                    justify-self: end;
                }
            }
        }
    }

    &-results {
        background-color: $white;
        color: $body-color;
        @include rfs(#{map-get($map: $spacers, $key: section)}, padding-block);

        .results-form {
            background-color: $primary;
            color: $white;
            padding: 1.5rem;

            &-headline {
                @include use-hero-subline-ash($white);
            }

            &-intro {
                font-size: 1rem;
                margin-bottom: 1rem;
            }

            &-data {
                ul {
                    list-style: none;
                    padding-left: 0;
                }
            }

            .wrapper-start {
                display: flex;
                flex-direction: column;
            }

            .submit {
                display: grid;

                .btn-next {
                    justify-self: flex-end;
                    grid-row: 1;
                }

                .btn-prev {
                    justify-self: flex-start;
                    grid-row: 1;
                }

                button[type='submit'] {
                    justify-self: flex-end;
                    grid-row: 1;
                }
            }

            input[type='file'] {
                color: $white;
            }
        }

        .result-head {
            margin-bottom: map-get($map: $spacers, $key: 3x);
            text-align: center;

            h3 {
                @include use-hero-subline-ash($white);
            }
        }

        .results-buttons {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .btn-back {
                position: absolute;
                left: 0;
                color: $white;
                white-space: nowrap;

                i {
                    font-size: 14px;
                }
            }

            .btn-group {
                @include rfs(3rem, gap);
            }
        }

        .results-contact-person {
            display: flex;
            align-items: center;
            gap: 1rem;

            .contact-data {
                i {
                    color: $primary;
                    padding-right: 0.6rem;
                }

                .name {
                    color: $primary;
                    @include font-size(1.5rem);
                    font-weight: 600;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .step {
            &-body {
                &.business_divisions {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-results {
            .results-buttons {
                flex-direction: column;

                .btn-back {
                    margin-top: 2rem;
                    position: relative;
                }
            }
        }

        .step {
            &-body {
                &.business_divisions {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .step {
            &-body {
                &.career_level,
                &.locations,
                &.fields_of_activity,
                &.business_divisions {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }
}
