.container {
    &-fluid {
        padding: 0;
    }

    @include media-breakpoint-down(sm) {
        padding-inline: 1rem;
    }
}

.container-header {
    @include make-container();
    padding-inline: 0;
    max-width: 1550px;
    align-items: center;
    display: flex;
    flex-wrap: inherit;
    justify-content: space-between;
    position: relative;
}
