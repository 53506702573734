.news-categories {
    padding-block: 0.75rem;
    padding-inline: 2rem;
    background-color: $primary;
    margin-bottom: map-get($map: $spacers, $key: 3x);
    display: inline-flex;

    ul {
        flex: 1;
        list-style: none;
        display: inline-flex;
        @include rfs(4rem, gap);
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;

        li,
        li a {
            color: $white;
            white-space: nowrap;
        }

        li a {
            opacity: 0.5;
            @include font-size(1.25rem);

            &.active {
                opacity: 1;
                font-weight: 900;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
        width: 100%;

        ul {
            flex-direction: column;
        }
    }
}

.news-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: min-content;
    gap: $grid-gutter-width;

    .news-card {
        background-color: $white;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;

        &-image {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-body {
            padding-top: 1.25rem;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &-headline {
                @include font-size(1.5rem);
                font-weight: 500;
                line-height: 1.2;
            }

            a {
                display: block;
                flex-grow: 1;
            }

            time {
                margin-top: 1rem;
                font-style: italic;
                @include font-size(0.625rem);
                color: #3d3e3e;
                font-weight: 300;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.search-term {
    position: relative;

    &-toggle {
        cursor: pointer;
    }

    &-input-group {
        background-color: $gray-100;
        padding: 0.5rem;
        position: absolute;
        right: 0;
        top: calc(100% + 0.75rem + 3px);
        display: flex;
    }

    &-input {
        background-color: $white;
        border: solid 1px $primary;
        min-width: 150px;
        padding: 0.5rem;
        color: $primary;

        &::placeholder {
            color: $primary;
        }

        &:focus {
            background-color: $white;
            color: $primary;
        }
    }

    @include media-breakpoint-down(sm) {
        &-input-group {
            position: relative;
            padding: 0;
            background-color: transparent;
        }

        &-input {
            border-right: none;
            border-top: none;
            border-left: none;
            border-bottom-color: $white;
            min-width: 0;
            width: 100%;
            background-color: transparent;

            &::placeholder {
                color: $white;
            }
        }
    }
}

.accordion.accordion-search {
    --bs-accordion-bg: #{$primary};
    --bs-accordion-color: #{$white};
    --bs-accordion-active-color: #{$white};
    --bs-accordion-btn-icon: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E');
    --bs-accordion-btn-active-icon: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E');

    margin-inline: 0;
    width: 100%;
    color: $white;

    .accordion-button {
        --bs-accordion-btn-color: #{$white};
    }

    .accordion-body ul li {
        width: 100%;
    }
}
