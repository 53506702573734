.footer-main {
    background-color: $gray;
    @include rfs(1rem, padding-block);

    .nav {
        --bs-nav-link-color: #{$white};
        --bs-nav-link-hover-color: #{$white};
        --bs-nav-link-padding-x: 0px;

        @include rfs(1.5625rem, column-gap);
    }

    @include media-breakpoint-down(sm) {
        .nav {
            column-gap: 0.75rem;
        }
    }
}
