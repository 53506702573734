@mixin use-body($color: null) {
    font-family: map-get($body, font-family);
    @include rfs(map-get($body, font-size));
    font-weight: map-get($body, font-weight);
    line-height: map-get($body, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-nav-link($key: md, $color: null) {
    $typo-map: map-get($nav-link, $key);
    font-family: map-get($typo-map, font-family);
    @include rfs(map-get($typo-map, font-size));
    font-weight: map-get($typo-map, font-weight);
    line-height: map-get($typo-map, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-hero-video($color: null) {
    font-family: map-get($hero-video, font-family);
    @include rfs(map-get($hero-video, font-size));
    font-weight: map-get($hero-video, font-weight);
    line-height: map-get($hero-video, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-hero-video-ash($color: null) {
    font-family: map-get($hero-video-ash, font-family);
    @include rfs(map-get($hero-video-ash, font-size));
    font-weight: map-get($hero-video-ash, font-weight);
    line-height: map-get($hero-video-ash, line-height);
    letter-spacing: map-get($hero-video-ash, letter-spacing);

    @if $color {
        color: $color;
    }
}

@mixin use-hero-default($color: null) {
    font-family: map-get($hero-default, font-family);
    @include rfs(map-get($hero-default, font-size));
    font-weight: map-get($hero-default, font-weight);
    line-height: map-get($hero-default, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-hero-default-ash($color: null) {
    font-family: map-get($hero-default-ash, font-family);
    @include rfs(map-get($hero-default-ash, font-size));
    font-weight: map-get($hero-default-ash, font-weight);
    line-height: map-get($hero-default-ash, line-height);
    letter-spacing: map-get($hero-default-ash, letter-spacing);

    @if $color {
        color: $color;
    }
}

@mixin use-hero-subline($color: null) {
    font-family: map-get($hero-subline, font-family);
    @include rfs(map-get($hero-subline, font-size));
    font-weight: map-get($hero-subline, font-weight);
    line-height: map-get($hero-subline, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-hero-subline-ash($color: null) {
    font-family: map-get($hero-subline-ash, font-family);
    @include rfs(map-get($hero-subline-ash, font-size));
    font-weight: map-get($hero-subline-ash, font-weight);
    letter-spacing: map-get($hero-subline-ash, letter-spacing);
    line-height: map-get($hero-subline-ash, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-section($color: null) {
    font-family: map-get($section, font-family);
    @include rfs(map-get($section, font-size));
    font-weight: map-get($section, font-weight);
    line-height: map-get($section, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-section-ash($color: null) {
    font-family: map-get($section-ash, font-family);
    @include rfs(map-get($section-ash, font-size));
    font-weight: map-get($section-ash, font-weight);
    line-height: map-get($section-ash, line-height);
    letter-spacing: map-get($section-ash, letter-spacing);

    @if $color {
        color: $color;
    }
}

@mixin use-button-label($color: null) {
    font-family: map-get($button-label, font-family);
    @include rfs(map-get($button-label, font-size));
    font-weight: map-get($button-label, font-weight);
    line-height: map-get($button-label, line-height);

    @if $color {
        color: $color;
    }
}
