.link-banner {
    text-align: center;
    @include rfs(3.5rem, padding-block);

    .headline,
    .headline-content {
        margin-bottom: map-get($map: $spacers, $key: md);
    }

    .link {
        @include rfs(1.5rem, padding-top);
    }
}
