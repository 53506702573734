.navbar-header {
    --bs-navbar-padding-y: 0.5rem;
    @include rfs(3rem, padding-inline);

    background-color: $white;
    transition: padding $transition-length ease-in-out;

    .navbar-brand {
        --bs-navbar-brand-padding-y: 1rem;
    }

    .navbar-toggler {
        border-radius: 0;
        border: 0;
        outline: 0;
        line-height: normal;
        cursor: pointer;
        width: 35px;
        height: 40px;
        border: solid 1px transparent;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        &.open {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(10px, 9px);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg) translate(8px, -8px);
                }
            }
        }

        span {
            display: block;
            height: 5px;
            width: 100%;
            border-radius: 50rem;
            background-color: $primary;
        }

        i {
            color: $primary;
        }
    }

    .nav-item {
        border-bottom: solid 1px $white;

        &:last-child {
            border-bottom: 0;
        }
    }

    .nav-link {
        --bs-navbar-nav-link-padding-x: 0.5rem;
        --bs-nav-link-padding-y: 1rem;
        --bs-nav-link-font-weight: 400;

        transition: padding $transition-length ease-in-out;
    }

    .nav-link,
    .dropdown-link {
        @include use-nav-link(md, $white);

        &.active,
        &:hover {
            @include use-nav-link(md, $secondary);
            text-shadow: 0px 0px 1px $secondary;
        }
    }

    .dropdown-menu {
        background-color: transparent;
        transition: background-color $transition-length ease-in-out;
        border-top: solid 1px $white;
        padding-left: 2rem;
    }

    .dropdown-item {
        --bs-dropdown-link-active-bg: transparent;
        --bs-dropdown-item-padding-x: 0;
        --bs-dropdown-padding-y: 1rem;

        padding-block: 1rem;
        border-bottom: solid 1px $white;

        &:last-child {
            border-bottom: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-inline: 1rem;
    }
}
