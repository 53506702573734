.image {
    position: relative;
    margin-bottom: 0;

    &-copyright {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba($white, 0.3);
        color: $body-color;
        padding: 0.1rem 0.5rem;
        font-size: 0.7rem;
    }

    &-description {
        font-style: italic;
    }
}
