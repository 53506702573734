.swiper-container {
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 0 2rem;
    }

    &:hover {
        .swiper-button {
            opacity: 1;
        }
    }
}

.swiper {
    .swiper-slide {
        height: auto;
        display: flex;
        cursor: grab;
    }
}

.swiper-button {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba($black, 0.3);
    cursor: pointer;
    z-index: 2;
    padding: 0.5rem;
    transition: background-color $transition-length ease-in-out;
    display: flex;
    align-items: center;

    &:hover {
        background-color: rgba($black, 1);
    }

    &.swiper-button-disabled {
        cursor: default;
        background-color: rgba($black, 0.5);
    }

    i {
        color: $white;
        font-size: 1rem;
    }

    &-prev {
        left: 0;
    }

    &-next {
        right: 0;
    }
}
