.image-text {
    .headline-content {
        font-size: 1.25rem;
        font-weight: bolder;
        margin-bottom: map-get($map: $spacers, $key: lg);
    }

    @include media-breakpoint-down(sm) {
        .image {
            margin-inline: -1rem;
        }
    }
}
