.read-more {
    position: relative;
    overflow: hidden;
    max-height: 300px;

    &.open {
        max-height: 100%;

        .read-more-btn {
            &::before {
                display: none;
            }

            &::after {
                content: '\f077';
            }
        }
    }

    &.only-mobile {
        max-height: 100%;

        .read-more-btn {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            max-height: 150px;

            &.open {
                max-height: 100%;
            }

            .read-more-btn {
                display: block;
            }
        }
    }

    &-btn {
        cursor: pointer;
        border: none;
        background-color: $white;
        color: $secondary;
        padding-top: 1rem;
        padding-bottom: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        transition: opacity $transition-length ease-in-out;
        opacity: 1;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(-100%);
            height: 3rem;
            background-image: linear-gradient(
                to bottom,
                rgba($white, 0.2),
                $white
            );
        }

        &::after {
            content: '\f078';
            font-family: 'Font Awesome 5 Pro';
            margin-left: 0.5rem;
        }
    }
}
