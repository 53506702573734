.hero-video {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 10rem min-content;

    &-video {
        grid-column: 1;
        grid-row: 1 / span 2;
        @include object-fit(cover);
        height: clamp(450px, 80vh, 940px);
    }

    .container {
        grid-column: 1;
        grid-row: 2 / span 2;
        align-self: flex-end;
        position: relative;
        z-index: 1;
    }

    &-intro {
        background-color: $white;
        min-height: 100%;
        @include rfs(2.5rem, padding-inline);
        @include rfs(2.5rem, padding-block);

        .headline,
        .headline-content {
            margin-bottom: 0;
        }

        .headline {
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            &-content {
                @include use-hero-video($black);
            }

            &-subline {
                @include use-hero-subline($black);
            }
        }

        .text-content {
            @include rfs(1.5rem, margin-top);
        }
    }

    // @include media-breakpoint-down(md) {
    //     &-video {
    //         height: 450px;
    //     }
    // }

    @include media-breakpoint-down(sm) {
        padding-inline: 0;
        grid-template-rows: min-content 6rem min-content;

        &-intro {
            margin-inline: 1.5rem;
        }

        &-video {
            height: clamp(350px, 75vh, 500px);
        }
    }
}
