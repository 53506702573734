.header {
    background-color: transparent;
    transition: background-color $transition-length ease-in-out;

    &-logo {
        transform-origin: left;
        transition: transform $transition-length ease-in-out;
    }

    // &.scrolled {
    //     .header-logo {
    //         transform: scale(0.8);
    //     }

    //     .navbar-header {
    //         --bs-navbar-padding-y: 0rem;
    //     }

    //     #main-nav .nav-link {
    //         --bs-nav-link-padding-y: #{map-get($map: $spacers, $key: md)};
    //     }
    // }
}
