.page-section {
    @include spacing(padding, y, map-get($map: $spacers, $key: section));

    &.bg-primary,
    &.bg-secondary,
    &.bg-green,
    &.bg-red {
        .headline-content,
        .text-content {
            color: $white;
        }
    }

    &.has-graphic {
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: top;
    }

    &.has-graphic.is-last-block {
        mask-image: url($bg-section-border-top);
        margin-top: -15px;
    }

    &.sibling-after {
        mask-image: url($bg-section-border-top);
    }

    &.sibling-before {
        mask-image: url($bg-section-border-both);
        margin-block: -15px;
    }

    &.sibling-both {
        mask-image: url($bg-section-border-both);
        margin-block: -15px;
        position: relative;
        z-index: 1;
    }
}
