.card-numbers {
    @include rfs(2.5rem, padding-inline);

    .row > div {
        &:nth-child(odd):nth-child(3n + 1) {
            .card-numbers-card {
                background-color: $primary;
            }
        }

        &:nth-child(odd):nth-child(3n + 2) {
            .card-numbers-card {
                background-color: $another-gray;
            }
        }

        &:nth-child(odd):nth-child(3n) {
            .card-numbers-card {
                background-color: $green;
            }
        }

        &:nth-child(even):nth-child(3n + 1) {
            .card-numbers-card {
                background-color: $green;
            }
        }

        &:nth-child(even):nth-child(3n + 2) {
            .card-numbers-card {
                background-color: $another-gray;
            }
        }

        &:nth-child(even):nth-child(3n) {
            .card-numbers-card {
                background-color: $primary;
            }
        }
    }

    &-card {
        @include rfs(2rem, padding);
        @include rfs(1rem, gap);
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-number {
            @include font-size(5rem);
            font-weight: 700;
            line-height: 1;
        }

        &-description {
            @include font-size(1.5rem);
            line-height: 1;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-inline: 0;
        margin-inline: -1rem;
    }
}
