.job-contact {
    &-form {
        background-color: $primary;
        color: $white;
        padding: 1.5rem;

        input {
            color: $white;
        }

        .headline-content {
            @include font-size(2.75rem);
            font-weight: 400;
            font-family: 'DCC Ash';
        }

        &-title {
            @include font-size(1.25rem);
            font-weight: 600;
        }

        .submit {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-person {
        display: flex;
        align-items: center;
        gap: 1rem;

        &-data {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            i {
                color: $primary;
                padding-right: 0.6rem;
            }

            .name {
                color: $primary;
                @include font-size(1.5rem);
                font-weight: 600;
                margin-bottom: 0.5rem;
            }
        }
    }
}
