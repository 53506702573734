.offcanvas {
    background-color: transparent;

    &-backdrop {
        top: var(--header-height);
        background-color: #000;
    }

    &.offcanvas-end {
        border: 0;

        // Breaks my heart to use fixed px values but Safari can't handle it otherwise
        top: 98px;
        //top: var(--header-height);

        @include media-breakpoint-down(xl) {
            top: 83px;
        }
    }

    &-header,
    &-body {
        background-color: $primary;
    }

    &-body {
        flex-grow: unset;
    }

    @include media-breakpoint-down(sm) {
        &.offcanvas-end {
            width: 100vw;
        }
    }
}
