.check-list {
    ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding-block: 0.5rem;
            @include font-size(1.2rem);

            &::before {
                content: '\f00c';
                font-family: 'Font Awesome 5 Pro';
                font-weight: 700;
                color: $primary;
                align-self: flex-start;
            }
        }
    }
}
