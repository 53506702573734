.job-detail {
    &-hero {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content 10rem min-content;

        &-background {
            grid-column: 1;
            grid-row: 1 / span 2;
            @include object-fit(cover);
            height: clamp(450px, 80vh, 940px);

            .image,
            img {
                height: 100%;
            }

            img {
                @include object-fit(cover);
            }
        }

        .container {
            grid-column: 1;
            grid-row: 2 / span 2;
            position: relative;
            z-index: 1;
        }

        &-intro {
            min-height: 100%;
            text-align: center;
            background-image: url($bg-white);
            background-size: cover;
            background-position: top;
            background-repeat: no-repeat;
            @include rfs(2.5rem, padding-inline);
            @include rfs(4rem, padding-block);

            .headline,
            .headline-content {
                margin-bottom: 0;
            }

            .headline-content {
                @include use-hero-default-ash($black);
            }

            .headline-subline {
                @include use-hero-subline-ash($black);
            }
        }

        @include media-breakpoint-down(sm) {
            padding-inline: 0;
            grid-template-rows: min-content 6rem min-content;

            &-intro {
                margin-inline: 1.5rem;
            }

            .container {
                padding-inline: 1.5rem;
            }
        }
    }

    &-cards {
        @include spacing(padding, y, map-get($map: $spacers, $key: section));
        background-image: url($bg-button-image);
        background-repeat: no-repeat;
        background-size: cover;

        &-headline {
            text-align: center;
            @include font-size(2.75rem);
            font-family: 'DCC Ash';
            color: $body-color;
            font-weight: 400;
            line-height: 1;
            @include rfs(2.1875rem, margin-bottom);
        }

        &-item {
            background-color: $white;
            @include rfs(3rem, padding);
            flex-grow: 1;
            transition: max-height $transition-length ease-in-out;
            // max-height: 300px;
            overflow: hidden;
            position: relative;

            &-more {
                background-color: $white;
                color: $secondary;
                padding-top: 1rem;
                padding-bottom: 2.5rem;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                @include rfs(3rem, padding-inline);
                transition: opacity $transition-length ease-in-out;
                opacity: 1;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    transform: translateY(-100%);
                    height: 3rem;
                    background-image: linear-gradient(
                        to bottom,
                        rgba($white, 0.2),
                        $white
                    );
                }
            }

            // &:hover {
            //     max-height: 100%;

            //     .job-detail-cards-item-more {
            //         opacity: 0;
            //         visibility: hidden;
            //     }
            // }

            ul {
                list-style: none;
                margin-bottom: 0;
                padding-left: 0;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                li {
                    padding-left: 2rem;
                    position: relative;

                    &::before {
                        content: '\f00c';
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-family: 'Font Awesome 6 Pro';
                        font-weight: 900;
                        color: $primary;
                        padding-right: 0.5rem;
                    }
                }
            }
        }
    }

    &-video {
        @include spacing(padding, y, map-get($map: $spacers, $key: section));
    }

    &-contact {
        @include spacing(
            padding,
            bottom,
            map-get($map: $spacers, $key: section)
        );
    }

    &-gallery {
        .swiper-slide {
            width: auto;

            a,
            .image,
            img {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
            }
        }
    }

    &-cta-top {
        margin-top: -10px;
        position: relative;
        z-index: 1;
    }

    &-cta-bottom {
        margin-top: -10px;
        position: relative;
        z-index: 1;
    }

    @include media-breakpoint-down(md) {
        &-cta-top {
            position: fixed;
            bottom: 0;
            width: 100vw;
            z-index: 9;
            margin-top: -10px;
        }

        &-cta-bottom {
            display: none !important;
        }
    }

    @include media-breakpoint-down(sm) {
        &-contact {
            .job-contact-form {
                margin-inline: -1rem;
            }
        }
    }
}
