.accordion {
    --bs-accordion-bg: #{$light-blue};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include rfs(24px, gap);

    &-item {
        border: 0;
        align-self: flex-start;
    }

    &-button {
        @include font-size(18px);
        font-weight: 700;
        line-height: 1.2;
        padding: 17px 20px;
        box-shadow: none;
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        margin-inline: -1rem;
        gap: map-get($map: $spacers, $key: sm);
    }
}
