.custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 200px;
}

.custom-select {
    position: relative;
    cursor: pointer;
}

.custom-select-trigger {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.custom-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: none;
    z-index: 10;
}

.custom-option {
    padding: 10px;
    cursor: pointer;
}

.custom-option:hover {
    background-color: #f1f1f1;
}
