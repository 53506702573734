@charset "UTF-8";
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #cc0000;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #384e30;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #001a1a;
  --bs-white: #fff;
  --bs-gray: #666667;
  --bs-gray-dark: #4c4d4d;
  --bs-gray-100: #f7f8f9;
  --bs-gray-200: #d8d9da;
  --bs-gray-300: #babbbc;
  --bs-gray-400: #9d9e9f;
  --bs-gray-500: #818283;
  --bs-gray-600: #666667;
  --bs-gray-700: #666667;
  --bs-gray-800: #4c4d4d;
  --bs-gray-900: #333435;
  --bs-primary: #0068b5;
  --bs-secondary: #ff6600;
  --bs-success: #384e30;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #cc0000;
  --bs-light: #f7f8f9;
  --bs-dark: #333435;
  --bs-light-blue: #cfe7ff;
  --bs-light-gray: #edf0f0;
  --bs-green: #384e30;
  --bs-gray: #3d3e3e;
  --bs-red: #cc0000;
  --bs-primary-rgb: 0, 104, 181;
  --bs-secondary-rgb: 255, 102, 0;
  --bs-success-rgb: 56, 78, 48;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 204, 0, 0;
  --bs-light-rgb: 247, 248, 249;
  --bs-dark-rgb: 51, 52, 53;
  --bs-light-blue-rgb: 207, 231, 255;
  --bs-light-gray-rgb: 237, 240, 240;
  --bs-green-rgb: 56, 78, 48;
  --bs-gray-rgb: 61, 62, 62;
  --bs-red-rgb: 204, 0, 0;
  --bs-primary-text-emphasis: #002a48;
  --bs-secondary-text-emphasis: #662900;
  --bs-success-text-emphasis: #161f13;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #520000;
  --bs-light-text-emphasis: #666667;
  --bs-dark-text-emphasis: #666667;
  --bs-primary-bg-subtle: #cce1f0;
  --bs-secondary-bg-subtle: #ffe0cc;
  --bs-success-bg-subtle: #d7dcd6;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f5cccc;
  --bs-light-bg-subtle: #fbfcfc;
  --bs-dark-bg-subtle: #9d9e9f;
  --bs-primary-border-subtle: #99c3e1;
  --bs-secondary-border-subtle: #ffc299;
  --bs-success-border-subtle: #afb8ac;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #eb9999;
  --bs-light-border-subtle: #d8d9da;
  --bs-dark-border-subtle: #818283;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 26, 26;
  --bs-font-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: calc(0.865rem + 0.18vw);
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #001a1a;
  --bs-body-color-rgb: 0, 26, 26;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #001a1a;
  --bs-emphasis-color-rgb: 0, 26, 26;
  --bs-secondary-color: rgba(0, 26, 26, 0.75);
  --bs-secondary-color-rgb: 0, 26, 26;
  --bs-secondary-bg: #d8d9da;
  --bs-secondary-bg-rgb: 216, 217, 218;
  --bs-tertiary-color: rgba(0, 26, 26, 0.5);
  --bs-tertiary-color-rgb: 0, 26, 26;
  --bs-tertiary-bg: #f7f8f9;
  --bs-tertiary-bg-rgb: 247, 248, 249;
  --bs-heading-color: inherit;
  --bs-link-color: #001a1a;
  --bs-link-color-rgb: 0, 26, 26;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #001515;
  --bs-link-hover-color-rgb: 0, 21, 21;
  --bs-code-color: #d63384;
  --bs-highlight-color: #001a1a;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #babbbc;
  --bs-border-color-translucent: rgba(0, 26, 26, 0.175);
  --bs-border-radius: 0.625rem;
  --bs-border-radius-sm: 1.25rem;
  --bs-border-radius-lg: 2.5rem;
  --bs-border-radius-xl: 5rem;
  --bs-border-radius-xxl: 10rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 26, 26, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 26, 26, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 26, 26, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 26, 26, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(0, 104, 181, 0.25);
  --bs-form-valid-color: #384e30;
  --bs-form-valid-border-color: #384e30;
  --bs-form-invalid-color: #cc0000;
  --bs-form-invalid-border-color: #cc0000;
}
@media (min-width: 1200px) {
  :root,
  [data-bs-theme=light] {
    --bs-body-font-size: 1rem;
  }
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #babbbc;
  --bs-body-color-rgb: 186, 187, 188;
  --bs-body-bg: #333435;
  --bs-body-bg-rgb: 51, 52, 53;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(186, 187, 188, 0.75);
  --bs-secondary-color-rgb: 186, 187, 188;
  --bs-secondary-bg: #4c4d4d;
  --bs-secondary-bg-rgb: 76, 77, 77;
  --bs-tertiary-color: rgba(186, 187, 188, 0.5);
  --bs-tertiary-color-rgb: 186, 187, 188;
  --bs-tertiary-bg: #404141;
  --bs-tertiary-bg-rgb: 64, 65, 65;
  --bs-primary-text-emphasis: #66a4d3;
  --bs-secondary-text-emphasis: #ffa366;
  --bs-success-text-emphasis: #889583;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #e06666;
  --bs-light-text-emphasis: #f7f8f9;
  --bs-dark-text-emphasis: #babbbc;
  --bs-primary-bg-subtle: #001524;
  --bs-secondary-bg-subtle: #331400;
  --bs-success-bg-subtle: #0b100a;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #290000;
  --bs-light-bg-subtle: #4c4d4d;
  --bs-dark-bg-subtle: #263434;
  --bs-primary-border-subtle: #003e6d;
  --bs-secondary-border-subtle: #993d00;
  --bs-success-border-subtle: #222f1d;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #7a0000;
  --bs-light-border-subtle: #666667;
  --bs-dark-border-subtle: #4c4d4d;
  --bs-heading-color: inherit;
  --bs-link-color: #66a4d3;
  --bs-link-hover-color: #85b6dc;
  --bs-link-color-rgb: 102, 164, 211;
  --bs-link-hover-color-rgb: 133, 182, 220;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #babbbc;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #666667;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #889583;
  --bs-form-valid-border-color: #889583;
  --bs-form-invalid-color: #e06666;
  --bs-form-invalid-border-color: #e06666;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 26, 26, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.04rem + 2.28vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.75rem;
  }
}

h2, .h2 {
  font-size: calc(0.99rem + 1.68vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(0.965rem + 1.38vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2rem;
  }
}

h4, .h4 {
  font-size: calc(0.94rem + 1.08vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.75rem;
  }
}

h5, .h5 {
  font-size: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.5rem;
  }
}

h6, .h6 {
  font-size: calc(0.89rem + 0.48vw);
}
@media (min-width: 1200px) {
  h6, .h6 {
    font-size: 1.25rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 1.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(0.915rem + 0.78vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(0.89rem + 0.48vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.25rem;
  }
}

.display-1 {
  font-size: calc(1.265rem + 4.98vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.215rem + 4.38vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.165rem + 3.78vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.115rem + 3.18vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.065rem + 2.58vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(0.89rem + 0.48vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.25rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #666667;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: calc(8.3333333333% + 1.5rem);
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1298px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-xs,
.gx-xs {
  --bs-gutter-x: 0.25rem;
}

.g-xs,
.gy-xs {
  --bs-gutter-y: 0.25rem;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 0.5rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 0.5rem;
}

.g-md,
.gx-md {
  --bs-gutter-x: 1rem;
}

.g-md,
.gy-md {
  --bs-gutter-y: 1rem;
}

.g-lg,
.gx-lg {
  --bs-gutter-x: 1.5rem;
}

.g-lg,
.gy-lg {
  --bs-gutter-y: 1.5rem;
}

.g-2x,
.gx-2x {
  --bs-gutter-x: 2rem;
}

.g-2x,
.gy-2x {
  --bs-gutter-y: 2rem;
}

.g-3x,
.gx-3x {
  --bs-gutter-x: 3rem;
}

.g-3x,
.gy-3x {
  --bs-gutter-y: 3rem;
}

.g-4x,
.gx-4x {
  --bs-gutter-x: 4rem;
}

.g-4x,
.gy-4x {
  --bs-gutter-y: 4rem;
}

.g-5x,
.gx-5x {
  --bs-gutter-x: 5rem;
}

.g-5x,
.gy-5x {
  --bs-gutter-y: 5rem;
}

.g-6x,
.gx-6x {
  --bs-gutter-x: 6rem;
}

.g-6x,
.gy-6x {
  --bs-gutter-y: 6rem;
}

.g-7x,
.gx-7x {
  --bs-gutter-x: 7rem;
}

.g-7x,
.gy-7x {
  --bs-gutter-y: 7rem;
}

.g-8x,
.gx-8x {
  --bs-gutter-x: 8rem;
}

.g-8x,
.gy-8x {
  --bs-gutter-y: 8rem;
}

.g-9x,
.gx-9x {
  --bs-gutter-x: 9rem;
}

.g-9x,
.gy-9x {
  --bs-gutter-y: 9rem;
}

.g-10x,
.gx-10x {
  --bs-gutter-x: 10rem;
}

.g-10x,
.gy-10x {
  --bs-gutter-y: 10rem;
}

.g-section,
.gx-section {
  --bs-gutter-x: 7rem;
}

.g-section,
.gy-section {
  --bs-gutter-y: 7rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-xs,
  .gx-sm-xs {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-xs,
  .gy-sm-xs {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-sm,
  .gx-sm-sm {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-sm,
  .gy-sm-sm {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-md,
  .gx-sm-md {
    --bs-gutter-x: 1rem;
  }
  .g-sm-md,
  .gy-sm-md {
    --bs-gutter-y: 1rem;
  }
  .g-sm-lg,
  .gx-sm-lg {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-lg,
  .gy-sm-lg {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-2x,
  .gx-sm-2x {
    --bs-gutter-x: 2rem;
  }
  .g-sm-2x,
  .gy-sm-2x {
    --bs-gutter-y: 2rem;
  }
  .g-sm-3x,
  .gx-sm-3x {
    --bs-gutter-x: 3rem;
  }
  .g-sm-3x,
  .gy-sm-3x {
    --bs-gutter-y: 3rem;
  }
  .g-sm-4x,
  .gx-sm-4x {
    --bs-gutter-x: 4rem;
  }
  .g-sm-4x,
  .gy-sm-4x {
    --bs-gutter-y: 4rem;
  }
  .g-sm-5x,
  .gx-sm-5x {
    --bs-gutter-x: 5rem;
  }
  .g-sm-5x,
  .gy-sm-5x {
    --bs-gutter-y: 5rem;
  }
  .g-sm-6x,
  .gx-sm-6x {
    --bs-gutter-x: 6rem;
  }
  .g-sm-6x,
  .gy-sm-6x {
    --bs-gutter-y: 6rem;
  }
  .g-sm-7x,
  .gx-sm-7x {
    --bs-gutter-x: 7rem;
  }
  .g-sm-7x,
  .gy-sm-7x {
    --bs-gutter-y: 7rem;
  }
  .g-sm-8x,
  .gx-sm-8x {
    --bs-gutter-x: 8rem;
  }
  .g-sm-8x,
  .gy-sm-8x {
    --bs-gutter-y: 8rem;
  }
  .g-sm-9x,
  .gx-sm-9x {
    --bs-gutter-x: 9rem;
  }
  .g-sm-9x,
  .gy-sm-9x {
    --bs-gutter-y: 9rem;
  }
  .g-sm-10x,
  .gx-sm-10x {
    --bs-gutter-x: 10rem;
  }
  .g-sm-10x,
  .gy-sm-10x {
    --bs-gutter-y: 10rem;
  }
  .g-sm-section,
  .gx-sm-section {
    --bs-gutter-x: 7rem;
  }
  .g-sm-section,
  .gy-sm-section {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-xs,
  .gx-md-xs {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-xs,
  .gy-md-xs {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-sm,
  .gx-md-sm {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-sm,
  .gy-md-sm {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-md,
  .gx-md-md {
    --bs-gutter-x: 1rem;
  }
  .g-md-md,
  .gy-md-md {
    --bs-gutter-y: 1rem;
  }
  .g-md-lg,
  .gx-md-lg {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-lg,
  .gy-md-lg {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-2x,
  .gx-md-2x {
    --bs-gutter-x: 2rem;
  }
  .g-md-2x,
  .gy-md-2x {
    --bs-gutter-y: 2rem;
  }
  .g-md-3x,
  .gx-md-3x {
    --bs-gutter-x: 3rem;
  }
  .g-md-3x,
  .gy-md-3x {
    --bs-gutter-y: 3rem;
  }
  .g-md-4x,
  .gx-md-4x {
    --bs-gutter-x: 4rem;
  }
  .g-md-4x,
  .gy-md-4x {
    --bs-gutter-y: 4rem;
  }
  .g-md-5x,
  .gx-md-5x {
    --bs-gutter-x: 5rem;
  }
  .g-md-5x,
  .gy-md-5x {
    --bs-gutter-y: 5rem;
  }
  .g-md-6x,
  .gx-md-6x {
    --bs-gutter-x: 6rem;
  }
  .g-md-6x,
  .gy-md-6x {
    --bs-gutter-y: 6rem;
  }
  .g-md-7x,
  .gx-md-7x {
    --bs-gutter-x: 7rem;
  }
  .g-md-7x,
  .gy-md-7x {
    --bs-gutter-y: 7rem;
  }
  .g-md-8x,
  .gx-md-8x {
    --bs-gutter-x: 8rem;
  }
  .g-md-8x,
  .gy-md-8x {
    --bs-gutter-y: 8rem;
  }
  .g-md-9x,
  .gx-md-9x {
    --bs-gutter-x: 9rem;
  }
  .g-md-9x,
  .gy-md-9x {
    --bs-gutter-y: 9rem;
  }
  .g-md-10x,
  .gx-md-10x {
    --bs-gutter-x: 10rem;
  }
  .g-md-10x,
  .gy-md-10x {
    --bs-gutter-y: 10rem;
  }
  .g-md-section,
  .gx-md-section {
    --bs-gutter-x: 7rem;
  }
  .g-md-section,
  .gy-md-section {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-xs,
  .gx-lg-xs {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-xs,
  .gy-lg-xs {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-sm,
  .gx-lg-sm {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-sm,
  .gy-lg-sm {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-md,
  .gx-lg-md {
    --bs-gutter-x: 1rem;
  }
  .g-lg-md,
  .gy-lg-md {
    --bs-gutter-y: 1rem;
  }
  .g-lg-lg,
  .gx-lg-lg {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-lg,
  .gy-lg-lg {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-2x,
  .gx-lg-2x {
    --bs-gutter-x: 2rem;
  }
  .g-lg-2x,
  .gy-lg-2x {
    --bs-gutter-y: 2rem;
  }
  .g-lg-3x,
  .gx-lg-3x {
    --bs-gutter-x: 3rem;
  }
  .g-lg-3x,
  .gy-lg-3x {
    --bs-gutter-y: 3rem;
  }
  .g-lg-4x,
  .gx-lg-4x {
    --bs-gutter-x: 4rem;
  }
  .g-lg-4x,
  .gy-lg-4x {
    --bs-gutter-y: 4rem;
  }
  .g-lg-5x,
  .gx-lg-5x {
    --bs-gutter-x: 5rem;
  }
  .g-lg-5x,
  .gy-lg-5x {
    --bs-gutter-y: 5rem;
  }
  .g-lg-6x,
  .gx-lg-6x {
    --bs-gutter-x: 6rem;
  }
  .g-lg-6x,
  .gy-lg-6x {
    --bs-gutter-y: 6rem;
  }
  .g-lg-7x,
  .gx-lg-7x {
    --bs-gutter-x: 7rem;
  }
  .g-lg-7x,
  .gy-lg-7x {
    --bs-gutter-y: 7rem;
  }
  .g-lg-8x,
  .gx-lg-8x {
    --bs-gutter-x: 8rem;
  }
  .g-lg-8x,
  .gy-lg-8x {
    --bs-gutter-y: 8rem;
  }
  .g-lg-9x,
  .gx-lg-9x {
    --bs-gutter-x: 9rem;
  }
  .g-lg-9x,
  .gy-lg-9x {
    --bs-gutter-y: 9rem;
  }
  .g-lg-10x,
  .gx-lg-10x {
    --bs-gutter-x: 10rem;
  }
  .g-lg-10x,
  .gy-lg-10x {
    --bs-gutter-y: 10rem;
  }
  .g-lg-section,
  .gx-lg-section {
    --bs-gutter-x: 7rem;
  }
  .g-lg-section,
  .gy-lg-section {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-xs,
  .gx-xl-xs {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-xs,
  .gy-xl-xs {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-sm,
  .gx-xl-sm {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-sm,
  .gy-xl-sm {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-md,
  .gx-xl-md {
    --bs-gutter-x: 1rem;
  }
  .g-xl-md,
  .gy-xl-md {
    --bs-gutter-y: 1rem;
  }
  .g-xl-lg,
  .gx-xl-lg {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-lg,
  .gy-xl-lg {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-2x,
  .gx-xl-2x {
    --bs-gutter-x: 2rem;
  }
  .g-xl-2x,
  .gy-xl-2x {
    --bs-gutter-y: 2rem;
  }
  .g-xl-3x,
  .gx-xl-3x {
    --bs-gutter-x: 3rem;
  }
  .g-xl-3x,
  .gy-xl-3x {
    --bs-gutter-y: 3rem;
  }
  .g-xl-4x,
  .gx-xl-4x {
    --bs-gutter-x: 4rem;
  }
  .g-xl-4x,
  .gy-xl-4x {
    --bs-gutter-y: 4rem;
  }
  .g-xl-5x,
  .gx-xl-5x {
    --bs-gutter-x: 5rem;
  }
  .g-xl-5x,
  .gy-xl-5x {
    --bs-gutter-y: 5rem;
  }
  .g-xl-6x,
  .gx-xl-6x {
    --bs-gutter-x: 6rem;
  }
  .g-xl-6x,
  .gy-xl-6x {
    --bs-gutter-y: 6rem;
  }
  .g-xl-7x,
  .gx-xl-7x {
    --bs-gutter-x: 7rem;
  }
  .g-xl-7x,
  .gy-xl-7x {
    --bs-gutter-y: 7rem;
  }
  .g-xl-8x,
  .gx-xl-8x {
    --bs-gutter-x: 8rem;
  }
  .g-xl-8x,
  .gy-xl-8x {
    --bs-gutter-y: 8rem;
  }
  .g-xl-9x,
  .gx-xl-9x {
    --bs-gutter-x: 9rem;
  }
  .g-xl-9x,
  .gy-xl-9x {
    --bs-gutter-y: 9rem;
  }
  .g-xl-10x,
  .gx-xl-10x {
    --bs-gutter-x: 10rem;
  }
  .g-xl-10x,
  .gy-xl-10x {
    --bs-gutter-y: 10rem;
  }
  .g-xl-section,
  .gx-xl-section {
    --bs-gutter-x: 7rem;
  }
  .g-xl-section,
  .gy-xl-section {
    --bs-gutter-y: 7rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-xs,
  .gx-xxl-xs {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-xs,
  .gy-xxl-xs {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-sm,
  .gx-xxl-sm {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-sm,
  .gy-xxl-sm {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-md,
  .gx-xxl-md {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-md,
  .gy-xxl-md {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-lg,
  .gx-xxl-lg {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-lg,
  .gy-xxl-lg {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-2x,
  .gx-xxl-2x {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-2x,
  .gy-xxl-2x {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-3x,
  .gx-xxl-3x {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-3x,
  .gy-xxl-3x {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-4x,
  .gx-xxl-4x {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-4x,
  .gy-xxl-4x {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-5x,
  .gx-xxl-5x {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-5x,
  .gy-xxl-5x {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-6x,
  .gx-xxl-6x {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-6x,
  .gy-xxl-6x {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-7x,
  .gx-xxl-7x {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-7x,
  .gy-xxl-7x {
    --bs-gutter-y: 7rem;
  }
  .g-xxl-8x,
  .gx-xxl-8x {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-8x,
  .gy-xxl-8x {
    --bs-gutter-y: 8rem;
  }
  .g-xxl-9x,
  .gx-xxl-9x {
    --bs-gutter-x: 9rem;
  }
  .g-xxl-9x,
  .gy-xxl-9x {
    --bs-gutter-y: 9rem;
  }
  .g-xxl-10x,
  .gx-xxl-10x {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-10x,
  .gy-xxl-10x {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-section,
  .gx-xxl-section {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-section,
  .gy-xxl-section {
    --bs-gutter-y: 7rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #001a1a;
  --bs-table-bg: #cce1f0;
  --bs-table-border-color: #a3b9c5;
  --bs-table-striped-bg: #c2d7e5;
  --bs-table-striped-color: #001a1a;
  --bs-table-active-bg: #b8cddb;
  --bs-table-active-color: #001a1a;
  --bs-table-hover-bg: #bdd2e0;
  --bs-table-hover-color: #001a1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #001a1a;
  --bs-table-bg: #ffe0cc;
  --bs-table-border-color: #ccb8a8;
  --bs-table-striped-bg: #f2d6c3;
  --bs-table-striped-color: #001a1a;
  --bs-table-active-bg: #e6ccba;
  --bs-table-active-color: #001a1a;
  --bs-table-hover-bg: #ecd1bf;
  --bs-table-hover-color: #001a1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #001a1a;
  --bs-table-bg: #d7dcd6;
  --bs-table-border-color: #acb5b0;
  --bs-table-striped-bg: #ccd2cd;
  --bs-table-striped-color: #001a1a;
  --bs-table-active-bg: #c2c9c3;
  --bs-table-active-color: #001a1a;
  --bs-table-hover-bg: #c7cdc8;
  --bs-table-hover-color: #001a1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #001a1a;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c8cf;
  --bs-table-striped-bg: #c5e9f1;
  --bs-table-striped-color: #001a1a;
  --bs-table-active-bg: #badee5;
  --bs-table-active-color: #001a1a;
  --bs-table-hover-bg: #bfe4eb;
  --bs-table-hover-color: #001a1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #001a1a;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc8a9;
  --bs-table-striped-bg: #f2e8c4;
  --bs-table-striped-color: #001a1a;
  --bs-table-active-bg: #e6ddbb;
  --bs-table-active-color: #001a1a;
  --bs-table-hover-bg: #ece3c0;
  --bs-table-hover-color: #001a1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #001a1a;
  --bs-table-bg: #f5cccc;
  --bs-table-border-color: #c4a8a8;
  --bs-table-striped-bg: #e9c3c3;
  --bs-table-striped-color: #001a1a;
  --bs-table-active-bg: #ddbaba;
  --bs-table-active-color: #001a1a;
  --bs-table-hover-bg: #e3bfbf;
  --bs-table-hover-color: #001a1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #001a1a;
  --bs-table-bg: #f7f8f9;
  --bs-table-border-color: #c6cccc;
  --bs-table-striped-bg: #ebedee;
  --bs-table-striped-color: #001a1a;
  --bs-table-active-bg: #dee2e3;
  --bs-table-active-color: #001a1a;
  --bs-table-hover-bg: #e4e7e8;
  --bs-table-hover-color: #001a1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #333435;
  --bs-table-border-color: #5c5d5d;
  --bs-table-striped-bg: #3d3e3f;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #474849;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #424344;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: calc(0.89rem + 0.48vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.25rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: calc(0.8525rem + 0.03vw);
}
@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 0.875rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: calc(0.865rem + 0.18vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--bs-border-width) solid #fff;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .form-control {
    font-size: 1rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: #fff;
  border-color: #80b4da;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #fff;
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: calc(0.8525rem + 0.03vw);
  border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 0.875rem;
  }
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(0.89rem + 0.48vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.25rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234c4d4d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: calc(0.865rem + 0.18vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #fff;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #fff;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .form-select {
    font-size: 1rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #80b4da;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: calc(0.8525rem + 0.03vw);
  border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 0.875rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(0.89rem + 0.48vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.25rem;
  }
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23babbbc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #fff;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #80b4da;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
}
.form-check-input:checked {
  background-color: #0068b5;
  border-color: #0068b5;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0068b5;
  border-color: #0068b5;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 26, 26, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380b4da'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0068b5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3d2e9;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0068b5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3d2e9;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #666667;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: calc(0.865rem + 0.18vw);
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid #fff;
  border-radius: var(--bs-border-radius);
}
@media (min-width: 1200px) {
  .input-group-text {
    font-size: 1rem;
  }
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(0.89rem + 0.48vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.25rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: calc(0.8525rem + 0.03vw);
  border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    font-size: 0.875rem;
  }
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: calc(0.8525rem + 0.03vw);
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}
@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 0.875rem;
  }
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23384e30' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23384e30' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: calc(0.8525rem + 0.03vw);
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}
@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 0.875rem;
  }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23cc0000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc0000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23cc0000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc0000' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.8125rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: calc(0.8525rem + 0.03vw);
  --bs-btn-font-weight: 900;
  --bs-btn-line-height: 1.2;
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 2px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.625rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 26, 26, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .btn {
    --bs-btn-font-size: 0.875rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0068b5;
  --bs-btn-border-color: #0068b5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00589a;
  --bs-btn-hover-border-color: #005391;
  --bs-btn-focus-shadow-rgb: 38, 127, 192;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005391;
  --bs-btn-active-border-color: #004e88;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0068b5;
  --bs-btn-disabled-border-color: #0068b5;
}

.btn-secondary {
  --bs-btn-color: #001a1a;
  --bs-btn-bg: #ff6600;
  --bs-btn-border-color: #ff6600;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #ff7d26;
  --bs-btn-hover-border-color: #ff751a;
  --bs-btn-focus-shadow-rgb: 217, 91, 4;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #ff8533;
  --bs-btn-active-border-color: #ff751a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #001a1a;
  --bs-btn-disabled-bg: #ff6600;
  --bs-btn-disabled-border-color: #ff6600;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #384e30;
  --bs-btn-border-color: #384e30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #304229;
  --bs-btn-hover-border-color: #2d3e26;
  --bs-btn-focus-shadow-rgb: 86, 105, 79;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2d3e26;
  --bs-btn-active-border-color: #2a3b24;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #384e30;
  --bs-btn-disabled-border-color: #384e30;
}

.btn-info {
  --bs-btn-color: #001a1a;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 176, 208;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #001a1a;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #001a1a;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 168, 10;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #001a1a;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #cc0000;
  --bs-btn-border-color: #cc0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ad0000;
  --bs-btn-hover-border-color: #a30000;
  --bs-btn-focus-shadow-rgb: 212, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a30000;
  --bs-btn-active-border-color: #990000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #cc0000;
  --bs-btn-disabled-border-color: #cc0000;
}

.btn-light {
  --bs-btn-color: #001a1a;
  --bs-btn-bg: #f7f8f9;
  --bs-btn-border-color: #f7f8f9;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #d2d3d4;
  --bs-btn-hover-border-color: #c6c6c7;
  --bs-btn-focus-shadow-rgb: 210, 215, 216;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #c6c6c7;
  --bs-btn-active-border-color: #b9babb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #001a1a;
  --bs-btn-disabled-bg: #f7f8f9;
  --bs-btn-disabled-border-color: #f7f8f9;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333435;
  --bs-btn-border-color: #333435;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #525253;
  --bs-btn-hover-border-color: #474849;
  --bs-btn-focus-shadow-rgb: 82, 82, 83;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c5d5d;
  --bs-btn-active-border-color: #474849;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #333435;
  --bs-btn-disabled-border-color: #333435;
}

.btn-light-blue {
  --bs-btn-color: #001a1a;
  --bs-btn-bg: #cfe7ff;
  --bs-btn-border-color: #cfe7ff;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #d6ebff;
  --bs-btn-hover-border-color: #d4e9ff;
  --bs-btn-focus-shadow-rgb: 176, 200, 221;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #d9ecff;
  --bs-btn-active-border-color: #d4e9ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #001a1a;
  --bs-btn-disabled-bg: #cfe7ff;
  --bs-btn-disabled-border-color: #cfe7ff;
}

.btn-light-gray {
  --bs-btn-color: #001a1a;
  --bs-btn-bg: #edf0f0;
  --bs-btn-border-color: #edf0f0;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #f0f2f2;
  --bs-btn-hover-border-color: #eff2f2;
  --bs-btn-focus-shadow-rgb: 201, 208, 208;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #f1f3f3;
  --bs-btn-active-border-color: #eff2f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #001a1a;
  --bs-btn-disabled-bg: #edf0f0;
  --bs-btn-disabled-border-color: #edf0f0;
}

.btn-green {
  --bs-btn-color: #fff;
  --bs-btn-bg: #384e30;
  --bs-btn-border-color: #384e30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #304229;
  --bs-btn-hover-border-color: #2d3e26;
  --bs-btn-focus-shadow-rgb: 86, 105, 79;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2d3e26;
  --bs-btn-active-border-color: #2a3b24;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #384e30;
  --bs-btn-disabled-border-color: #384e30;
}

.btn-gray {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3d3e3e;
  --bs-btn-border-color: #3d3e3e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #343535;
  --bs-btn-hover-border-color: #313232;
  --bs-btn-focus-shadow-rgb: 90, 91, 91;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #313232;
  --bs-btn-active-border-color: #2e2f2f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3d3e3e;
  --bs-btn-disabled-border-color: #3d3e3e;
}

.btn-red {
  --bs-btn-color: #fff;
  --bs-btn-bg: #cc0000;
  --bs-btn-border-color: #cc0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ad0000;
  --bs-btn-hover-border-color: #a30000;
  --bs-btn-focus-shadow-rgb: 212, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a30000;
  --bs-btn-active-border-color: #990000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #cc0000;
  --bs-btn-disabled-border-color: #cc0000;
}

.btn-outline-primary {
  --bs-btn-color: #0068b5;
  --bs-btn-border-color: #0068b5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0068b5;
  --bs-btn-hover-border-color: #0068b5;
  --bs-btn-focus-shadow-rgb: 0, 104, 181;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0068b5;
  --bs-btn-active-border-color: #0068b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #0068b5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0068b5;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #ff6600;
  --bs-btn-border-color: #ff6600;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #ff6600;
  --bs-btn-hover-border-color: #ff6600;
  --bs-btn-focus-shadow-rgb: 255, 102, 0;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #ff6600;
  --bs-btn-active-border-color: #ff6600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #ff6600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff6600;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #384e30;
  --bs-btn-border-color: #384e30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #384e30;
  --bs-btn-hover-border-color: #384e30;
  --bs-btn-focus-shadow-rgb: 56, 78, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #384e30;
  --bs-btn-active-border-color: #384e30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #384e30;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #384e30;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #cc0000;
  --bs-btn-border-color: #cc0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cc0000;
  --bs-btn-hover-border-color: #cc0000;
  --bs-btn-focus-shadow-rgb: 204, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc0000;
  --bs-btn-active-border-color: #cc0000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #cc0000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cc0000;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f7f8f9;
  --bs-btn-border-color: #f7f8f9;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #f7f8f9;
  --bs-btn-hover-border-color: #f7f8f9;
  --bs-btn-focus-shadow-rgb: 247, 248, 249;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #f7f8f9;
  --bs-btn-active-border-color: #f7f8f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #f7f8f9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f7f8f9;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #333435;
  --bs-btn-border-color: #333435;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333435;
  --bs-btn-hover-border-color: #333435;
  --bs-btn-focus-shadow-rgb: 51, 52, 53;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333435;
  --bs-btn-active-border-color: #333435;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #333435;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333435;
  --bs-gradient: none;
}

.btn-outline-light-blue {
  --bs-btn-color: #cfe7ff;
  --bs-btn-border-color: #cfe7ff;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #cfe7ff;
  --bs-btn-hover-border-color: #cfe7ff;
  --bs-btn-focus-shadow-rgb: 207, 231, 255;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #cfe7ff;
  --bs-btn-active-border-color: #cfe7ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #cfe7ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cfe7ff;
  --bs-gradient: none;
}

.btn-outline-light-gray {
  --bs-btn-color: #edf0f0;
  --bs-btn-border-color: #edf0f0;
  --bs-btn-hover-color: #001a1a;
  --bs-btn-hover-bg: #edf0f0;
  --bs-btn-hover-border-color: #edf0f0;
  --bs-btn-focus-shadow-rgb: 237, 240, 240;
  --bs-btn-active-color: #001a1a;
  --bs-btn-active-bg: #edf0f0;
  --bs-btn-active-border-color: #edf0f0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #edf0f0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #edf0f0;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #384e30;
  --bs-btn-border-color: #384e30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #384e30;
  --bs-btn-hover-border-color: #384e30;
  --bs-btn-focus-shadow-rgb: 56, 78, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #384e30;
  --bs-btn-active-border-color: #384e30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #384e30;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #384e30;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #3d3e3e;
  --bs-btn-border-color: #3d3e3e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3d3e3e;
  --bs-btn-hover-border-color: #3d3e3e;
  --bs-btn-focus-shadow-rgb: 61, 62, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d3e3e;
  --bs-btn-active-border-color: #3d3e3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #3d3e3e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3d3e3e;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #cc0000;
  --bs-btn-border-color: #cc0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cc0000;
  --bs-btn-hover-border-color: #cc0000;
  --bs-btn-focus-shadow-rgb: 204, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc0000;
  --bs-btn-active-border-color: #cc0000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #cc0000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cc0000;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #666667;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 38, 60, 60;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(0.89rem + 0.48vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --bs-btn-font-size: 1.25rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: calc(0.8525rem + 0.03vw);
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .btn-sm, .btn-group-sm > .btn {
    --bs-btn-font-size: 0.875rem;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: auto;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0;
  --bs-dropdown-font-size: calc(0.865rem + 0.18vw);
  --bs-dropdown-color: #fff;
  --bs-dropdown-bg: transparent;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: calc(0 - 0);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #0068b5;
  --bs-dropdown-link-active-bg: #0068b5;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-header-color: #666667;
  --bs-dropdown-header-padding-x: 1.5rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
@media (min-width: 1200px) {
  .dropdown-menu {
    --bs-dropdown-font-size: 1rem;
  }
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: calc(0.8525rem + 0.03vw);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .dropdown-header {
    font-size: 0.875rem;
  }
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #babbbc;
  --bs-dropdown-bg: #4c4d4d;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #babbbc;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #0068b5;
  --bs-dropdown-link-active-bg: #0068b5;
  --bs-dropdown-link-disabled-color: #818283;
  --bs-dropdown-header-color: #818283;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.625rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(2px * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(2px * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #0068b5;
  --bs-nav-pills-link-active-bg: transparent;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: #fff;
  --bs-navbar-hover-color: #ff6600;
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: #ff6600;
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #ff6600;
  --bs-navbar-brand-hover-color: #ff6600;
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0;
  --bs-navbar-toggler-padding-x: 0;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 26, 26, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.625rem;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #c7c7c7;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: calc(0 - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23001a1a' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23002a48' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: transaperent;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: calc(0.865rem + 0.18vw);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (min-width: 1200px) {
  .accordion-button {
    font-size: 1rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366a4d3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366a4d3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: calc(0.865rem + 0.18vw);
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0068b5;
  --bs-pagination-active-border-color: #0068b5;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}
@media (min-width: 1200px) {
  .pagination {
    --bs-pagination-font-size: 1rem;
  }
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: calc(0.89rem + 0.48vw);
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .pagination-lg {
    --bs-pagination-font-size: 1.25rem;
  }
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: calc(0.8525rem + 0.03vw);
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .pagination-sm {
    --bs-pagination-font-size: 0.875rem;
  }
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-light-blue {
  --bs-alert-color: var(--bs-light-blue-text-emphasis);
  --bs-alert-bg: var(--bs-light-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-light-blue-border-subtle);
  --bs-alert-link-color: var(--bs-light-blue-text-emphasis);
}

.alert-light-gray {
  --bs-alert-color: var(--bs-light-gray-text-emphasis);
  --bs-alert-bg: var(--bs-light-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-light-gray-border-subtle);
  --bs-alert-link-color: var(--bs-light-gray-text-emphasis);
}

.alert-green {
  --bs-alert-color: var(--bs-green-text-emphasis);
  --bs-alert-bg: var(--bs-green-bg-subtle);
  --bs-alert-border-color: var(--bs-green-border-subtle);
  --bs-alert-link-color: var(--bs-green-text-emphasis);
}

.alert-gray {
  --bs-alert-color: var(--bs-gray-text-emphasis);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text-emphasis);
}

.alert-red {
  --bs-alert-color: var(--bs-red-text-emphasis);
  --bs-alert-bg: var(--bs-red-bg-subtle);
  --bs-alert-border-color: var(--bs-red-border-subtle);
  --bs-alert-link-color: var(--bs-red-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0068b5;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0068b5;
  --bs-list-group-active-border-color: #0068b5;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-light-blue {
  --bs-list-group-color: var(--bs-light-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-light-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-light-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-blue-text-emphasis);
}

.list-group-item-light-gray {
  --bs-list-group-color: var(--bs-light-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-light-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-light-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-gray-text-emphasis);
}

.list-group-item-green {
  --bs-list-group-color: var(--bs-green-text-emphasis);
  --bs-list-group-bg: var(--bs-green-bg-subtle);
  --bs-list-group-border-color: var(--bs-green-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-green-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-green-border-subtle);
  --bs-list-group-active-color: var(--bs-green-bg-subtle);
  --bs-list-group-active-bg: var(--bs-green-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-green-text-emphasis);
}

.list-group-item-gray {
  --bs-list-group-color: var(--bs-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-text-emphasis);
}

.list-group-item-red {
  --bs-list-group-color: var(--bs-red-text-emphasis);
  --bs-list-group-bg: var(--bs-red-bg-subtle);
  --bs-list-group-border-color: var(--bs-red-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-red-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-red-border-subtle);
  --bs-list-group-active-color: var(--bs-red-bg-subtle);
  --bs-list-group-active-bg: var(--bs-red-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-red-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #fff;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 1;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(0, 104, 181, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.625rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: calc(8.3333333333% + 1.5rem);
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: calc(0.8525rem + 0.03vw);
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
@media (min-width: 1200px) {
  .toast {
    --bs-toast-font-size: 0.875rem;
  }
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #001a1a;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: calc(0.8525rem + 0.03vw);
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
@media (min-width: 1200px) {
  .tooltip {
    --bs-tooltip-font-size: 0.875rem;
  }
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: calc(0.8525rem + 0.03vw);
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: calc(0.865rem + 0.18vw);
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
@media (min-width: 1200px) {
  .popover {
    --bs-popover-font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .popover {
    --bs-popover-header-font-size: 1rem;
  }
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #001a1a;
}
.carousel-dark .carousel-caption {
  color: #001a1a;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #001a1a;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #001a1a;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: #0068b5;
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #001a1a;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #001a1a 55%, rgba(0, 0, 0, 0.8) 75%, #001a1a 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #001a1a !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #001a1a !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #001a1a !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #001a1a !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-blue {
  color: #001a1a !important;
  background-color: RGBA(var(--bs-light-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-gray {
  color: #001a1a !important;
  background-color: RGBA(var(--bs-light-gray-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #fff !important;
  background-color: RGBA(var(--bs-green-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #fff !important;
  background-color: RGBA(var(--bs-gray-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #fff !important;
  background-color: RGBA(var(--bs-red-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(0, 83, 145, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 83, 145, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(255, 133, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 133, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(45, 62, 38, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(45, 62, 38, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(163, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(163, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 249, 250, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 249, 250, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(41, 42, 42, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 42, 42, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light-blue {
  color: RGBA(var(--bs-light-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light-blue:hover, .link-light-blue:focus {
  color: RGBA(217, 236, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(217, 236, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light-gray {
  color: RGBA(var(--bs-light-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-gray-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light-gray:hover, .link-light-gray:focus {
  color: RGBA(241, 243, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 243, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-green {
  color: RGBA(var(--bs-green-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-green-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-green:hover, .link-green:focus {
  color: RGBA(45, 62, 38, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(45, 62, 38, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray {
  color: RGBA(var(--bs-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray:hover, .link-gray:focus {
  color: RGBA(49, 50, 50, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(49, 50, 50, var(--bs-link-underline-opacity, 1)) !important;
}

.link-red {
  color: RGBA(var(--bs-red-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-red-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-red:hover, .link-red:focus {
  color: RGBA(163, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(163, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light-blue {
  --bs-focus-ring-color: rgba(var(--bs-light-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light-gray {
  --bs-focus-ring-color: rgba(var(--bs-light-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-green {
  --bs-focus-ring-color: rgba(var(--bs-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-red {
  --bs-focus-ring-color: rgba(var(--bs-red-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-light-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-light-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-xs {
  margin: 0.25rem !important;
}

.m-sm {
  margin: 0.5rem !important;
}

.m-md {
  margin: 1rem !important;
}

.m-lg {
  margin: 1.5rem !important;
}

.m-2x {
  margin: 2rem !important;
}

.m-3x {
  margin: 3rem !important;
}

.m-4x {
  margin: 4rem !important;
}

.m-5x {
  margin: 5rem !important;
}

.m-6x {
  margin: 6rem !important;
}

.m-7x {
  margin: 7rem !important;
}

.m-8x {
  margin: 8rem !important;
}

.m-9x {
  margin: 9rem !important;
}

.m-10x {
  margin: 10rem !important;
}

.m-section {
  margin: 7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-xs {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-sm {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-md {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-lg {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-2x {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-3x {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-4x {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-5x {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-6x {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-7x {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-8x {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-9x {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-10x {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-section {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-xs {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-sm {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-md {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-lg {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-2x {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-3x {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-4x {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-5x {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-6x {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-7x {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-8x {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-9x {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-10x {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-section {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-xs {
  margin-top: 0.25rem !important;
}

.mt-sm {
  margin-top: 0.5rem !important;
}

.mt-md {
  margin-top: 1rem !important;
}

.mt-lg {
  margin-top: 1.5rem !important;
}

.mt-2x {
  margin-top: 2rem !important;
}

.mt-3x {
  margin-top: 3rem !important;
}

.mt-4x {
  margin-top: 4rem !important;
}

.mt-5x {
  margin-top: 5rem !important;
}

.mt-6x {
  margin-top: 6rem !important;
}

.mt-7x {
  margin-top: 7rem !important;
}

.mt-8x {
  margin-top: 8rem !important;
}

.mt-9x {
  margin-top: 9rem !important;
}

.mt-10x {
  margin-top: 10rem !important;
}

.mt-section {
  margin-top: 7rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-xs {
  margin-right: 0.25rem !important;
}

.me-sm {
  margin-right: 0.5rem !important;
}

.me-md {
  margin-right: 1rem !important;
}

.me-lg {
  margin-right: 1.5rem !important;
}

.me-2x {
  margin-right: 2rem !important;
}

.me-3x {
  margin-right: 3rem !important;
}

.me-4x {
  margin-right: 4rem !important;
}

.me-5x {
  margin-right: 5rem !important;
}

.me-6x {
  margin-right: 6rem !important;
}

.me-7x {
  margin-right: 7rem !important;
}

.me-8x {
  margin-right: 8rem !important;
}

.me-9x {
  margin-right: 9rem !important;
}

.me-10x {
  margin-right: 10rem !important;
}

.me-section {
  margin-right: 7rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-xs {
  margin-bottom: 0.25rem !important;
}

.mb-sm {
  margin-bottom: 0.5rem !important;
}

.mb-md {
  margin-bottom: 1rem !important;
}

.mb-lg {
  margin-bottom: 1.5rem !important;
}

.mb-2x {
  margin-bottom: 2rem !important;
}

.mb-3x {
  margin-bottom: 3rem !important;
}

.mb-4x {
  margin-bottom: 4rem !important;
}

.mb-5x {
  margin-bottom: 5rem !important;
}

.mb-6x {
  margin-bottom: 6rem !important;
}

.mb-7x {
  margin-bottom: 7rem !important;
}

.mb-8x {
  margin-bottom: 8rem !important;
}

.mb-9x {
  margin-bottom: 9rem !important;
}

.mb-10x {
  margin-bottom: 10rem !important;
}

.mb-section {
  margin-bottom: 7rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-xs {
  margin-left: 0.25rem !important;
}

.ms-sm {
  margin-left: 0.5rem !important;
}

.ms-md {
  margin-left: 1rem !important;
}

.ms-lg {
  margin-left: 1.5rem !important;
}

.ms-2x {
  margin-left: 2rem !important;
}

.ms-3x {
  margin-left: 3rem !important;
}

.ms-4x {
  margin-left: 4rem !important;
}

.ms-5x {
  margin-left: 5rem !important;
}

.ms-6x {
  margin-left: 6rem !important;
}

.ms-7x {
  margin-left: 7rem !important;
}

.ms-8x {
  margin-left: 8rem !important;
}

.ms-9x {
  margin-left: 9rem !important;
}

.ms-10x {
  margin-left: 10rem !important;
}

.ms-section {
  margin-left: 7rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-xs {
  padding: 0.25rem !important;
}

.p-sm {
  padding: 0.5rem !important;
}

.p-md {
  padding: 1rem !important;
}

.p-lg {
  padding: 1.5rem !important;
}

.p-2x {
  padding: 2rem !important;
}

.p-3x {
  padding: 3rem !important;
}

.p-4x {
  padding: 4rem !important;
}

.p-5x {
  padding: 5rem !important;
}

.p-6x {
  padding: 6rem !important;
}

.p-7x {
  padding: 7rem !important;
}

.p-8x {
  padding: 8rem !important;
}

.p-9x {
  padding: 9rem !important;
}

.p-10x {
  padding: 10rem !important;
}

.p-section {
  padding: 7rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-xs {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-sm {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-md {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-lg {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-2x {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-3x {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-4x {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-5x {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-6x {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-7x {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-8x {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-9x {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-10x {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-section {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-xs {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-sm {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-md {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-lg {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-2x {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-3x {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4x {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-5x {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-6x {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-7x {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-8x {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9x {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-10x {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-section {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-xs {
  padding-top: 0.25rem !important;
}

.pt-sm {
  padding-top: 0.5rem !important;
}

.pt-md {
  padding-top: 1rem !important;
}

.pt-lg {
  padding-top: 1.5rem !important;
}

.pt-2x {
  padding-top: 2rem !important;
}

.pt-3x {
  padding-top: 3rem !important;
}

.pt-4x {
  padding-top: 4rem !important;
}

.pt-5x {
  padding-top: 5rem !important;
}

.pt-6x {
  padding-top: 6rem !important;
}

.pt-7x {
  padding-top: 7rem !important;
}

.pt-8x {
  padding-top: 8rem !important;
}

.pt-9x {
  padding-top: 9rem !important;
}

.pt-10x {
  padding-top: 10rem !important;
}

.pt-section {
  padding-top: 7rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-xs {
  padding-right: 0.25rem !important;
}

.pe-sm {
  padding-right: 0.5rem !important;
}

.pe-md {
  padding-right: 1rem !important;
}

.pe-lg {
  padding-right: 1.5rem !important;
}

.pe-2x {
  padding-right: 2rem !important;
}

.pe-3x {
  padding-right: 3rem !important;
}

.pe-4x {
  padding-right: 4rem !important;
}

.pe-5x {
  padding-right: 5rem !important;
}

.pe-6x {
  padding-right: 6rem !important;
}

.pe-7x {
  padding-right: 7rem !important;
}

.pe-8x {
  padding-right: 8rem !important;
}

.pe-9x {
  padding-right: 9rem !important;
}

.pe-10x {
  padding-right: 10rem !important;
}

.pe-section {
  padding-right: 7rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-xs {
  padding-bottom: 0.25rem !important;
}

.pb-sm {
  padding-bottom: 0.5rem !important;
}

.pb-md {
  padding-bottom: 1rem !important;
}

.pb-lg {
  padding-bottom: 1.5rem !important;
}

.pb-2x {
  padding-bottom: 2rem !important;
}

.pb-3x {
  padding-bottom: 3rem !important;
}

.pb-4x {
  padding-bottom: 4rem !important;
}

.pb-5x {
  padding-bottom: 5rem !important;
}

.pb-6x {
  padding-bottom: 6rem !important;
}

.pb-7x {
  padding-bottom: 7rem !important;
}

.pb-8x {
  padding-bottom: 8rem !important;
}

.pb-9x {
  padding-bottom: 9rem !important;
}

.pb-10x {
  padding-bottom: 10rem !important;
}

.pb-section {
  padding-bottom: 7rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-xs {
  padding-left: 0.25rem !important;
}

.ps-sm {
  padding-left: 0.5rem !important;
}

.ps-md {
  padding-left: 1rem !important;
}

.ps-lg {
  padding-left: 1.5rem !important;
}

.ps-2x {
  padding-left: 2rem !important;
}

.ps-3x {
  padding-left: 3rem !important;
}

.ps-4x {
  padding-left: 4rem !important;
}

.ps-5x {
  padding-left: 5rem !important;
}

.ps-6x {
  padding-left: 6rem !important;
}

.ps-7x {
  padding-left: 7rem !important;
}

.ps-8x {
  padding-left: 8rem !important;
}

.ps-9x {
  padding-left: 9rem !important;
}

.ps-10x {
  padding-left: 10rem !important;
}

.ps-section {
  padding-left: 7rem !important;
}

.gap-sm {
  gap: 0.5rem !important;
}

.gap-md {
  gap: 1rem !important;
}

.gap-lg {
  gap: 1.5rem !important;
}

.gap-2x {
  gap: 2rem !important;
}

.gap-3x {
  gap: 3rem !important;
}

.gap-4x {
  gap: 4rem !important;
}

.gap-5x {
  gap: 5rem !important;
}

.gap-6x {
  gap: 6rem !important;
}

.gap-7x {
  gap: 7rem !important;
}

.gap-8x {
  gap: 8rem !important;
}

.gap-9x {
  gap: 9rem !important;
}

.gap-10x {
  gap: 10rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-xs {
  row-gap: 0.25rem !important;
}

.row-gap-sm {
  row-gap: 0.5rem !important;
}

.row-gap-md {
  row-gap: 1rem !important;
}

.row-gap-lg {
  row-gap: 1.5rem !important;
}

.row-gap-2x {
  row-gap: 2rem !important;
}

.row-gap-3x {
  row-gap: 3rem !important;
}

.row-gap-4x {
  row-gap: 4rem !important;
}

.row-gap-5x {
  row-gap: 5rem !important;
}

.row-gap-6x {
  row-gap: 6rem !important;
}

.row-gap-7x {
  row-gap: 7rem !important;
}

.row-gap-8x {
  row-gap: 8rem !important;
}

.row-gap-9x {
  row-gap: 9rem !important;
}

.row-gap-10x {
  row-gap: 10rem !important;
}

.row-gap-section {
  row-gap: 7rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-xs {
  column-gap: 0.25rem !important;
}

.column-gap-sm {
  column-gap: 0.5rem !important;
}

.column-gap-md {
  column-gap: 1rem !important;
}

.column-gap-lg {
  column-gap: 1.5rem !important;
}

.column-gap-2x {
  column-gap: 2rem !important;
}

.column-gap-3x {
  column-gap: 3rem !important;
}

.column-gap-4x {
  column-gap: 4rem !important;
}

.column-gap-5x {
  column-gap: 5rem !important;
}

.column-gap-6x {
  column-gap: 6rem !important;
}

.column-gap-7x {
  column-gap: 7rem !important;
}

.column-gap-8x {
  column-gap: 8rem !important;
}

.column-gap-9x {
  column-gap: 9rem !important;
}

.column-gap-10x {
  column-gap: 10rem !important;
}

.column-gap-section {
  column-gap: 7rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.04rem + 2.28vw) !important;
}

.fs-2 {
  font-size: calc(0.99rem + 1.68vw) !important;
}

.fs-3 {
  font-size: calc(0.965rem + 1.38vw) !important;
}

.fs-4 {
  font-size: calc(0.94rem + 1.08vw) !important;
}

.fs-5 {
  font-size: calc(0.915rem + 0.78vw) !important;
}

.fs-6 {
  font-size: calc(0.89rem + 0.48vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-light-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-light-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 26, 26, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-red {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-red-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-xs {
    margin: 0.25rem !important;
  }
  .m-sm-sm {
    margin: 0.5rem !important;
  }
  .m-sm-md {
    margin: 1rem !important;
  }
  .m-sm-lg {
    margin: 1.5rem !important;
  }
  .m-sm-2x {
    margin: 2rem !important;
  }
  .m-sm-3x {
    margin: 3rem !important;
  }
  .m-sm-4x {
    margin: 4rem !important;
  }
  .m-sm-5x {
    margin: 5rem !important;
  }
  .m-sm-6x {
    margin: 6rem !important;
  }
  .m-sm-7x {
    margin: 7rem !important;
  }
  .m-sm-8x {
    margin: 8rem !important;
  }
  .m-sm-9x {
    margin: 9rem !important;
  }
  .m-sm-10x {
    margin: 10rem !important;
  }
  .m-sm-section {
    margin: 7rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-xs {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-sm {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-md {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-lg {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-2x {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-3x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-4x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-5x {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-6x {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-7x {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-8x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-9x {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-10x {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-section {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-md {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-lg {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-2x {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-3x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-4x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-5x {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-6x {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-7x {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-8x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-9x {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-10x {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-section {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-xs {
    margin-top: 0.25rem !important;
  }
  .mt-sm-sm {
    margin-top: 0.5rem !important;
  }
  .mt-sm-md {
    margin-top: 1rem !important;
  }
  .mt-sm-lg {
    margin-top: 1.5rem !important;
  }
  .mt-sm-2x {
    margin-top: 2rem !important;
  }
  .mt-sm-3x {
    margin-top: 3rem !important;
  }
  .mt-sm-4x {
    margin-top: 4rem !important;
  }
  .mt-sm-5x {
    margin-top: 5rem !important;
  }
  .mt-sm-6x {
    margin-top: 6rem !important;
  }
  .mt-sm-7x {
    margin-top: 7rem !important;
  }
  .mt-sm-8x {
    margin-top: 8rem !important;
  }
  .mt-sm-9x {
    margin-top: 9rem !important;
  }
  .mt-sm-10x {
    margin-top: 10rem !important;
  }
  .mt-sm-section {
    margin-top: 7rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-xs {
    margin-right: 0.25rem !important;
  }
  .me-sm-sm {
    margin-right: 0.5rem !important;
  }
  .me-sm-md {
    margin-right: 1rem !important;
  }
  .me-sm-lg {
    margin-right: 1.5rem !important;
  }
  .me-sm-2x {
    margin-right: 2rem !important;
  }
  .me-sm-3x {
    margin-right: 3rem !important;
  }
  .me-sm-4x {
    margin-right: 4rem !important;
  }
  .me-sm-5x {
    margin-right: 5rem !important;
  }
  .me-sm-6x {
    margin-right: 6rem !important;
  }
  .me-sm-7x {
    margin-right: 7rem !important;
  }
  .me-sm-8x {
    margin-right: 8rem !important;
  }
  .me-sm-9x {
    margin-right: 9rem !important;
  }
  .me-sm-10x {
    margin-right: 10rem !important;
  }
  .me-sm-section {
    margin-right: 7rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-xs {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-sm {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-md {
    margin-bottom: 1rem !important;
  }
  .mb-sm-lg {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-2x {
    margin-bottom: 2rem !important;
  }
  .mb-sm-3x {
    margin-bottom: 3rem !important;
  }
  .mb-sm-4x {
    margin-bottom: 4rem !important;
  }
  .mb-sm-5x {
    margin-bottom: 5rem !important;
  }
  .mb-sm-6x {
    margin-bottom: 6rem !important;
  }
  .mb-sm-7x {
    margin-bottom: 7rem !important;
  }
  .mb-sm-8x {
    margin-bottom: 8rem !important;
  }
  .mb-sm-9x {
    margin-bottom: 9rem !important;
  }
  .mb-sm-10x {
    margin-bottom: 10rem !important;
  }
  .mb-sm-section {
    margin-bottom: 7rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-xs {
    margin-left: 0.25rem !important;
  }
  .ms-sm-sm {
    margin-left: 0.5rem !important;
  }
  .ms-sm-md {
    margin-left: 1rem !important;
  }
  .ms-sm-lg {
    margin-left: 1.5rem !important;
  }
  .ms-sm-2x {
    margin-left: 2rem !important;
  }
  .ms-sm-3x {
    margin-left: 3rem !important;
  }
  .ms-sm-4x {
    margin-left: 4rem !important;
  }
  .ms-sm-5x {
    margin-left: 5rem !important;
  }
  .ms-sm-6x {
    margin-left: 6rem !important;
  }
  .ms-sm-7x {
    margin-left: 7rem !important;
  }
  .ms-sm-8x {
    margin-left: 8rem !important;
  }
  .ms-sm-9x {
    margin-left: 9rem !important;
  }
  .ms-sm-10x {
    margin-left: 10rem !important;
  }
  .ms-sm-section {
    margin-left: 7rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-xs {
    padding: 0.25rem !important;
  }
  .p-sm-sm {
    padding: 0.5rem !important;
  }
  .p-sm-md {
    padding: 1rem !important;
  }
  .p-sm-lg {
    padding: 1.5rem !important;
  }
  .p-sm-2x {
    padding: 2rem !important;
  }
  .p-sm-3x {
    padding: 3rem !important;
  }
  .p-sm-4x {
    padding: 4rem !important;
  }
  .p-sm-5x {
    padding: 5rem !important;
  }
  .p-sm-6x {
    padding: 6rem !important;
  }
  .p-sm-7x {
    padding: 7rem !important;
  }
  .p-sm-8x {
    padding: 8rem !important;
  }
  .p-sm-9x {
    padding: 9rem !important;
  }
  .p-sm-10x {
    padding: 10rem !important;
  }
  .p-sm-section {
    padding: 7rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-xs {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-sm {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-md {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-lg {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-2x {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-3x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-4x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-5x {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-6x {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-7x {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-sm-8x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-9x {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-10x {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-section {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-lg {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-2x {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-3x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-4x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-5x {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-6x {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-7x {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-sm-8x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-9x {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-10x {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-section {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-xs {
    padding-top: 0.25rem !important;
  }
  .pt-sm-sm {
    padding-top: 0.5rem !important;
  }
  .pt-sm-md {
    padding-top: 1rem !important;
  }
  .pt-sm-lg {
    padding-top: 1.5rem !important;
  }
  .pt-sm-2x {
    padding-top: 2rem !important;
  }
  .pt-sm-3x {
    padding-top: 3rem !important;
  }
  .pt-sm-4x {
    padding-top: 4rem !important;
  }
  .pt-sm-5x {
    padding-top: 5rem !important;
  }
  .pt-sm-6x {
    padding-top: 6rem !important;
  }
  .pt-sm-7x {
    padding-top: 7rem !important;
  }
  .pt-sm-8x {
    padding-top: 8rem !important;
  }
  .pt-sm-9x {
    padding-top: 9rem !important;
  }
  .pt-sm-10x {
    padding-top: 10rem !important;
  }
  .pt-sm-section {
    padding-top: 7rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-xs {
    padding-right: 0.25rem !important;
  }
  .pe-sm-sm {
    padding-right: 0.5rem !important;
  }
  .pe-sm-md {
    padding-right: 1rem !important;
  }
  .pe-sm-lg {
    padding-right: 1.5rem !important;
  }
  .pe-sm-2x {
    padding-right: 2rem !important;
  }
  .pe-sm-3x {
    padding-right: 3rem !important;
  }
  .pe-sm-4x {
    padding-right: 4rem !important;
  }
  .pe-sm-5x {
    padding-right: 5rem !important;
  }
  .pe-sm-6x {
    padding-right: 6rem !important;
  }
  .pe-sm-7x {
    padding-right: 7rem !important;
  }
  .pe-sm-8x {
    padding-right: 8rem !important;
  }
  .pe-sm-9x {
    padding-right: 9rem !important;
  }
  .pe-sm-10x {
    padding-right: 10rem !important;
  }
  .pe-sm-section {
    padding-right: 7rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-xs {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-sm {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-md {
    padding-bottom: 1rem !important;
  }
  .pb-sm-lg {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-2x {
    padding-bottom: 2rem !important;
  }
  .pb-sm-3x {
    padding-bottom: 3rem !important;
  }
  .pb-sm-4x {
    padding-bottom: 4rem !important;
  }
  .pb-sm-5x {
    padding-bottom: 5rem !important;
  }
  .pb-sm-6x {
    padding-bottom: 6rem !important;
  }
  .pb-sm-7x {
    padding-bottom: 7rem !important;
  }
  .pb-sm-8x {
    padding-bottom: 8rem !important;
  }
  .pb-sm-9x {
    padding-bottom: 9rem !important;
  }
  .pb-sm-10x {
    padding-bottom: 10rem !important;
  }
  .pb-sm-section {
    padding-bottom: 7rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-xs {
    padding-left: 0.25rem !important;
  }
  .ps-sm-sm {
    padding-left: 0.5rem !important;
  }
  .ps-sm-md {
    padding-left: 1rem !important;
  }
  .ps-sm-lg {
    padding-left: 1.5rem !important;
  }
  .ps-sm-2x {
    padding-left: 2rem !important;
  }
  .ps-sm-3x {
    padding-left: 3rem !important;
  }
  .ps-sm-4x {
    padding-left: 4rem !important;
  }
  .ps-sm-5x {
    padding-left: 5rem !important;
  }
  .ps-sm-6x {
    padding-left: 6rem !important;
  }
  .ps-sm-7x {
    padding-left: 7rem !important;
  }
  .ps-sm-8x {
    padding-left: 8rem !important;
  }
  .ps-sm-9x {
    padding-left: 9rem !important;
  }
  .ps-sm-10x {
    padding-left: 10rem !important;
  }
  .ps-sm-section {
    padding-left: 7rem !important;
  }
  .gap-sm-sm {
    gap: 0.5rem !important;
  }
  .gap-sm-md {
    gap: 1rem !important;
  }
  .gap-sm-lg {
    gap: 1.5rem !important;
  }
  .gap-sm-2x {
    gap: 2rem !important;
  }
  .gap-sm-3x {
    gap: 3rem !important;
  }
  .gap-sm-4x {
    gap: 4rem !important;
  }
  .gap-sm-5x {
    gap: 5rem !important;
  }
  .gap-sm-6x {
    gap: 6rem !important;
  }
  .gap-sm-7x {
    gap: 7rem !important;
  }
  .gap-sm-8x {
    gap: 8rem !important;
  }
  .gap-sm-9x {
    gap: 9rem !important;
  }
  .gap-sm-10x {
    gap: 10rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-xs {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-sm {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-md {
    row-gap: 1rem !important;
  }
  .row-gap-sm-lg {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-2x {
    row-gap: 2rem !important;
  }
  .row-gap-sm-3x {
    row-gap: 3rem !important;
  }
  .row-gap-sm-4x {
    row-gap: 4rem !important;
  }
  .row-gap-sm-5x {
    row-gap: 5rem !important;
  }
  .row-gap-sm-6x {
    row-gap: 6rem !important;
  }
  .row-gap-sm-7x {
    row-gap: 7rem !important;
  }
  .row-gap-sm-8x {
    row-gap: 8rem !important;
  }
  .row-gap-sm-9x {
    row-gap: 9rem !important;
  }
  .row-gap-sm-10x {
    row-gap: 10rem !important;
  }
  .row-gap-sm-section {
    row-gap: 7rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-xs {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-sm {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-md {
    column-gap: 1rem !important;
  }
  .column-gap-sm-lg {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-2x {
    column-gap: 2rem !important;
  }
  .column-gap-sm-3x {
    column-gap: 3rem !important;
  }
  .column-gap-sm-4x {
    column-gap: 4rem !important;
  }
  .column-gap-sm-5x {
    column-gap: 5rem !important;
  }
  .column-gap-sm-6x {
    column-gap: 6rem !important;
  }
  .column-gap-sm-7x {
    column-gap: 7rem !important;
  }
  .column-gap-sm-8x {
    column-gap: 8rem !important;
  }
  .column-gap-sm-9x {
    column-gap: 9rem !important;
  }
  .column-gap-sm-10x {
    column-gap: 10rem !important;
  }
  .column-gap-sm-section {
    column-gap: 7rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-xs {
    margin: 0.25rem !important;
  }
  .m-md-sm {
    margin: 0.5rem !important;
  }
  .m-md-md {
    margin: 1rem !important;
  }
  .m-md-lg {
    margin: 1.5rem !important;
  }
  .m-md-2x {
    margin: 2rem !important;
  }
  .m-md-3x {
    margin: 3rem !important;
  }
  .m-md-4x {
    margin: 4rem !important;
  }
  .m-md-5x {
    margin: 5rem !important;
  }
  .m-md-6x {
    margin: 6rem !important;
  }
  .m-md-7x {
    margin: 7rem !important;
  }
  .m-md-8x {
    margin: 8rem !important;
  }
  .m-md-9x {
    margin: 9rem !important;
  }
  .m-md-10x {
    margin: 10rem !important;
  }
  .m-md-section {
    margin: 7rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-xs {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-sm {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-md {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-lg {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-2x {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-3x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-4x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-5x {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-6x {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-7x {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-8x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-9x {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-10x {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-section {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-md {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-lg {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-2x {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-3x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-4x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-5x {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-6x {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-7x {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-8x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-9x {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-10x {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-section {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-xs {
    margin-top: 0.25rem !important;
  }
  .mt-md-sm {
    margin-top: 0.5rem !important;
  }
  .mt-md-md {
    margin-top: 1rem !important;
  }
  .mt-md-lg {
    margin-top: 1.5rem !important;
  }
  .mt-md-2x {
    margin-top: 2rem !important;
  }
  .mt-md-3x {
    margin-top: 3rem !important;
  }
  .mt-md-4x {
    margin-top: 4rem !important;
  }
  .mt-md-5x {
    margin-top: 5rem !important;
  }
  .mt-md-6x {
    margin-top: 6rem !important;
  }
  .mt-md-7x {
    margin-top: 7rem !important;
  }
  .mt-md-8x {
    margin-top: 8rem !important;
  }
  .mt-md-9x {
    margin-top: 9rem !important;
  }
  .mt-md-10x {
    margin-top: 10rem !important;
  }
  .mt-md-section {
    margin-top: 7rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-xs {
    margin-right: 0.25rem !important;
  }
  .me-md-sm {
    margin-right: 0.5rem !important;
  }
  .me-md-md {
    margin-right: 1rem !important;
  }
  .me-md-lg {
    margin-right: 1.5rem !important;
  }
  .me-md-2x {
    margin-right: 2rem !important;
  }
  .me-md-3x {
    margin-right: 3rem !important;
  }
  .me-md-4x {
    margin-right: 4rem !important;
  }
  .me-md-5x {
    margin-right: 5rem !important;
  }
  .me-md-6x {
    margin-right: 6rem !important;
  }
  .me-md-7x {
    margin-right: 7rem !important;
  }
  .me-md-8x {
    margin-right: 8rem !important;
  }
  .me-md-9x {
    margin-right: 9rem !important;
  }
  .me-md-10x {
    margin-right: 10rem !important;
  }
  .me-md-section {
    margin-right: 7rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-xs {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-sm {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-md {
    margin-bottom: 1rem !important;
  }
  .mb-md-lg {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-2x {
    margin-bottom: 2rem !important;
  }
  .mb-md-3x {
    margin-bottom: 3rem !important;
  }
  .mb-md-4x {
    margin-bottom: 4rem !important;
  }
  .mb-md-5x {
    margin-bottom: 5rem !important;
  }
  .mb-md-6x {
    margin-bottom: 6rem !important;
  }
  .mb-md-7x {
    margin-bottom: 7rem !important;
  }
  .mb-md-8x {
    margin-bottom: 8rem !important;
  }
  .mb-md-9x {
    margin-bottom: 9rem !important;
  }
  .mb-md-10x {
    margin-bottom: 10rem !important;
  }
  .mb-md-section {
    margin-bottom: 7rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-xs {
    margin-left: 0.25rem !important;
  }
  .ms-md-sm {
    margin-left: 0.5rem !important;
  }
  .ms-md-md {
    margin-left: 1rem !important;
  }
  .ms-md-lg {
    margin-left: 1.5rem !important;
  }
  .ms-md-2x {
    margin-left: 2rem !important;
  }
  .ms-md-3x {
    margin-left: 3rem !important;
  }
  .ms-md-4x {
    margin-left: 4rem !important;
  }
  .ms-md-5x {
    margin-left: 5rem !important;
  }
  .ms-md-6x {
    margin-left: 6rem !important;
  }
  .ms-md-7x {
    margin-left: 7rem !important;
  }
  .ms-md-8x {
    margin-left: 8rem !important;
  }
  .ms-md-9x {
    margin-left: 9rem !important;
  }
  .ms-md-10x {
    margin-left: 10rem !important;
  }
  .ms-md-section {
    margin-left: 7rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-xs {
    padding: 0.25rem !important;
  }
  .p-md-sm {
    padding: 0.5rem !important;
  }
  .p-md-md {
    padding: 1rem !important;
  }
  .p-md-lg {
    padding: 1.5rem !important;
  }
  .p-md-2x {
    padding: 2rem !important;
  }
  .p-md-3x {
    padding: 3rem !important;
  }
  .p-md-4x {
    padding: 4rem !important;
  }
  .p-md-5x {
    padding: 5rem !important;
  }
  .p-md-6x {
    padding: 6rem !important;
  }
  .p-md-7x {
    padding: 7rem !important;
  }
  .p-md-8x {
    padding: 8rem !important;
  }
  .p-md-9x {
    padding: 9rem !important;
  }
  .p-md-10x {
    padding: 10rem !important;
  }
  .p-md-section {
    padding: 7rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-xs {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-sm {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-md {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-lg {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-2x {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-3x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-4x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-5x {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-6x {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-7x {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-md-8x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-9x {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-10x {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-section {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-lg {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-2x {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-3x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-4x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-5x {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-6x {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-7x {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-8x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-9x {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-10x {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-section {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-xs {
    padding-top: 0.25rem !important;
  }
  .pt-md-sm {
    padding-top: 0.5rem !important;
  }
  .pt-md-md {
    padding-top: 1rem !important;
  }
  .pt-md-lg {
    padding-top: 1.5rem !important;
  }
  .pt-md-2x {
    padding-top: 2rem !important;
  }
  .pt-md-3x {
    padding-top: 3rem !important;
  }
  .pt-md-4x {
    padding-top: 4rem !important;
  }
  .pt-md-5x {
    padding-top: 5rem !important;
  }
  .pt-md-6x {
    padding-top: 6rem !important;
  }
  .pt-md-7x {
    padding-top: 7rem !important;
  }
  .pt-md-8x {
    padding-top: 8rem !important;
  }
  .pt-md-9x {
    padding-top: 9rem !important;
  }
  .pt-md-10x {
    padding-top: 10rem !important;
  }
  .pt-md-section {
    padding-top: 7rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-xs {
    padding-right: 0.25rem !important;
  }
  .pe-md-sm {
    padding-right: 0.5rem !important;
  }
  .pe-md-md {
    padding-right: 1rem !important;
  }
  .pe-md-lg {
    padding-right: 1.5rem !important;
  }
  .pe-md-2x {
    padding-right: 2rem !important;
  }
  .pe-md-3x {
    padding-right: 3rem !important;
  }
  .pe-md-4x {
    padding-right: 4rem !important;
  }
  .pe-md-5x {
    padding-right: 5rem !important;
  }
  .pe-md-6x {
    padding-right: 6rem !important;
  }
  .pe-md-7x {
    padding-right: 7rem !important;
  }
  .pe-md-8x {
    padding-right: 8rem !important;
  }
  .pe-md-9x {
    padding-right: 9rem !important;
  }
  .pe-md-10x {
    padding-right: 10rem !important;
  }
  .pe-md-section {
    padding-right: 7rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-xs {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-sm {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-md {
    padding-bottom: 1rem !important;
  }
  .pb-md-lg {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-2x {
    padding-bottom: 2rem !important;
  }
  .pb-md-3x {
    padding-bottom: 3rem !important;
  }
  .pb-md-4x {
    padding-bottom: 4rem !important;
  }
  .pb-md-5x {
    padding-bottom: 5rem !important;
  }
  .pb-md-6x {
    padding-bottom: 6rem !important;
  }
  .pb-md-7x {
    padding-bottom: 7rem !important;
  }
  .pb-md-8x {
    padding-bottom: 8rem !important;
  }
  .pb-md-9x {
    padding-bottom: 9rem !important;
  }
  .pb-md-10x {
    padding-bottom: 10rem !important;
  }
  .pb-md-section {
    padding-bottom: 7rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-xs {
    padding-left: 0.25rem !important;
  }
  .ps-md-sm {
    padding-left: 0.5rem !important;
  }
  .ps-md-md {
    padding-left: 1rem !important;
  }
  .ps-md-lg {
    padding-left: 1.5rem !important;
  }
  .ps-md-2x {
    padding-left: 2rem !important;
  }
  .ps-md-3x {
    padding-left: 3rem !important;
  }
  .ps-md-4x {
    padding-left: 4rem !important;
  }
  .ps-md-5x {
    padding-left: 5rem !important;
  }
  .ps-md-6x {
    padding-left: 6rem !important;
  }
  .ps-md-7x {
    padding-left: 7rem !important;
  }
  .ps-md-8x {
    padding-left: 8rem !important;
  }
  .ps-md-9x {
    padding-left: 9rem !important;
  }
  .ps-md-10x {
    padding-left: 10rem !important;
  }
  .ps-md-section {
    padding-left: 7rem !important;
  }
  .gap-md-sm {
    gap: 0.5rem !important;
  }
  .gap-md-md {
    gap: 1rem !important;
  }
  .gap-md-lg {
    gap: 1.5rem !important;
  }
  .gap-md-2x {
    gap: 2rem !important;
  }
  .gap-md-3x {
    gap: 3rem !important;
  }
  .gap-md-4x {
    gap: 4rem !important;
  }
  .gap-md-5x {
    gap: 5rem !important;
  }
  .gap-md-6x {
    gap: 6rem !important;
  }
  .gap-md-7x {
    gap: 7rem !important;
  }
  .gap-md-8x {
    gap: 8rem !important;
  }
  .gap-md-9x {
    gap: 9rem !important;
  }
  .gap-md-10x {
    gap: 10rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-xs {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-sm {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-md {
    row-gap: 1rem !important;
  }
  .row-gap-md-lg {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-2x {
    row-gap: 2rem !important;
  }
  .row-gap-md-3x {
    row-gap: 3rem !important;
  }
  .row-gap-md-4x {
    row-gap: 4rem !important;
  }
  .row-gap-md-5x {
    row-gap: 5rem !important;
  }
  .row-gap-md-6x {
    row-gap: 6rem !important;
  }
  .row-gap-md-7x {
    row-gap: 7rem !important;
  }
  .row-gap-md-8x {
    row-gap: 8rem !important;
  }
  .row-gap-md-9x {
    row-gap: 9rem !important;
  }
  .row-gap-md-10x {
    row-gap: 10rem !important;
  }
  .row-gap-md-section {
    row-gap: 7rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-xs {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-sm {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-md {
    column-gap: 1rem !important;
  }
  .column-gap-md-lg {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-2x {
    column-gap: 2rem !important;
  }
  .column-gap-md-3x {
    column-gap: 3rem !important;
  }
  .column-gap-md-4x {
    column-gap: 4rem !important;
  }
  .column-gap-md-5x {
    column-gap: 5rem !important;
  }
  .column-gap-md-6x {
    column-gap: 6rem !important;
  }
  .column-gap-md-7x {
    column-gap: 7rem !important;
  }
  .column-gap-md-8x {
    column-gap: 8rem !important;
  }
  .column-gap-md-9x {
    column-gap: 9rem !important;
  }
  .column-gap-md-10x {
    column-gap: 10rem !important;
  }
  .column-gap-md-section {
    column-gap: 7rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-xs {
    margin: 0.25rem !important;
  }
  .m-lg-sm {
    margin: 0.5rem !important;
  }
  .m-lg-md {
    margin: 1rem !important;
  }
  .m-lg-lg {
    margin: 1.5rem !important;
  }
  .m-lg-2x {
    margin: 2rem !important;
  }
  .m-lg-3x {
    margin: 3rem !important;
  }
  .m-lg-4x {
    margin: 4rem !important;
  }
  .m-lg-5x {
    margin: 5rem !important;
  }
  .m-lg-6x {
    margin: 6rem !important;
  }
  .m-lg-7x {
    margin: 7rem !important;
  }
  .m-lg-8x {
    margin: 8rem !important;
  }
  .m-lg-9x {
    margin: 9rem !important;
  }
  .m-lg-10x {
    margin: 10rem !important;
  }
  .m-lg-section {
    margin: 7rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-xs {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-sm {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-md {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-lg {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-2x {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-3x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-4x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-5x {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-6x {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-7x {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-8x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-9x {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-10x {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-section {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-md {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-lg {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-2x {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-3x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-4x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-5x {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-6x {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-7x {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-8x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-9x {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-10x {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-section {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-xs {
    margin-top: 0.25rem !important;
  }
  .mt-lg-sm {
    margin-top: 0.5rem !important;
  }
  .mt-lg-md {
    margin-top: 1rem !important;
  }
  .mt-lg-lg {
    margin-top: 1.5rem !important;
  }
  .mt-lg-2x {
    margin-top: 2rem !important;
  }
  .mt-lg-3x {
    margin-top: 3rem !important;
  }
  .mt-lg-4x {
    margin-top: 4rem !important;
  }
  .mt-lg-5x {
    margin-top: 5rem !important;
  }
  .mt-lg-6x {
    margin-top: 6rem !important;
  }
  .mt-lg-7x {
    margin-top: 7rem !important;
  }
  .mt-lg-8x {
    margin-top: 8rem !important;
  }
  .mt-lg-9x {
    margin-top: 9rem !important;
  }
  .mt-lg-10x {
    margin-top: 10rem !important;
  }
  .mt-lg-section {
    margin-top: 7rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-xs {
    margin-right: 0.25rem !important;
  }
  .me-lg-sm {
    margin-right: 0.5rem !important;
  }
  .me-lg-md {
    margin-right: 1rem !important;
  }
  .me-lg-lg {
    margin-right: 1.5rem !important;
  }
  .me-lg-2x {
    margin-right: 2rem !important;
  }
  .me-lg-3x {
    margin-right: 3rem !important;
  }
  .me-lg-4x {
    margin-right: 4rem !important;
  }
  .me-lg-5x {
    margin-right: 5rem !important;
  }
  .me-lg-6x {
    margin-right: 6rem !important;
  }
  .me-lg-7x {
    margin-right: 7rem !important;
  }
  .me-lg-8x {
    margin-right: 8rem !important;
  }
  .me-lg-9x {
    margin-right: 9rem !important;
  }
  .me-lg-10x {
    margin-right: 10rem !important;
  }
  .me-lg-section {
    margin-right: 7rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-xs {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-sm {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-md {
    margin-bottom: 1rem !important;
  }
  .mb-lg-lg {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-2x {
    margin-bottom: 2rem !important;
  }
  .mb-lg-3x {
    margin-bottom: 3rem !important;
  }
  .mb-lg-4x {
    margin-bottom: 4rem !important;
  }
  .mb-lg-5x {
    margin-bottom: 5rem !important;
  }
  .mb-lg-6x {
    margin-bottom: 6rem !important;
  }
  .mb-lg-7x {
    margin-bottom: 7rem !important;
  }
  .mb-lg-8x {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9x {
    margin-bottom: 9rem !important;
  }
  .mb-lg-10x {
    margin-bottom: 10rem !important;
  }
  .mb-lg-section {
    margin-bottom: 7rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-xs {
    margin-left: 0.25rem !important;
  }
  .ms-lg-sm {
    margin-left: 0.5rem !important;
  }
  .ms-lg-md {
    margin-left: 1rem !important;
  }
  .ms-lg-lg {
    margin-left: 1.5rem !important;
  }
  .ms-lg-2x {
    margin-left: 2rem !important;
  }
  .ms-lg-3x {
    margin-left: 3rem !important;
  }
  .ms-lg-4x {
    margin-left: 4rem !important;
  }
  .ms-lg-5x {
    margin-left: 5rem !important;
  }
  .ms-lg-6x {
    margin-left: 6rem !important;
  }
  .ms-lg-7x {
    margin-left: 7rem !important;
  }
  .ms-lg-8x {
    margin-left: 8rem !important;
  }
  .ms-lg-9x {
    margin-left: 9rem !important;
  }
  .ms-lg-10x {
    margin-left: 10rem !important;
  }
  .ms-lg-section {
    margin-left: 7rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-xs {
    padding: 0.25rem !important;
  }
  .p-lg-sm {
    padding: 0.5rem !important;
  }
  .p-lg-md {
    padding: 1rem !important;
  }
  .p-lg-lg {
    padding: 1.5rem !important;
  }
  .p-lg-2x {
    padding: 2rem !important;
  }
  .p-lg-3x {
    padding: 3rem !important;
  }
  .p-lg-4x {
    padding: 4rem !important;
  }
  .p-lg-5x {
    padding: 5rem !important;
  }
  .p-lg-6x {
    padding: 6rem !important;
  }
  .p-lg-7x {
    padding: 7rem !important;
  }
  .p-lg-8x {
    padding: 8rem !important;
  }
  .p-lg-9x {
    padding: 9rem !important;
  }
  .p-lg-10x {
    padding: 10rem !important;
  }
  .p-lg-section {
    padding: 7rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-xs {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-sm {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-md {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-lg {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-2x {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-3x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-4x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-5x {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-6x {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-7x {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-lg-8x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-9x {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-10x {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-section {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-lg {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-2x {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-3x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-4x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-5x {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-6x {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-7x {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-lg-8x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-9x {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-10x {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-section {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-xs {
    padding-top: 0.25rem !important;
  }
  .pt-lg-sm {
    padding-top: 0.5rem !important;
  }
  .pt-lg-md {
    padding-top: 1rem !important;
  }
  .pt-lg-lg {
    padding-top: 1.5rem !important;
  }
  .pt-lg-2x {
    padding-top: 2rem !important;
  }
  .pt-lg-3x {
    padding-top: 3rem !important;
  }
  .pt-lg-4x {
    padding-top: 4rem !important;
  }
  .pt-lg-5x {
    padding-top: 5rem !important;
  }
  .pt-lg-6x {
    padding-top: 6rem !important;
  }
  .pt-lg-7x {
    padding-top: 7rem !important;
  }
  .pt-lg-8x {
    padding-top: 8rem !important;
  }
  .pt-lg-9x {
    padding-top: 9rem !important;
  }
  .pt-lg-10x {
    padding-top: 10rem !important;
  }
  .pt-lg-section {
    padding-top: 7rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-xs {
    padding-right: 0.25rem !important;
  }
  .pe-lg-sm {
    padding-right: 0.5rem !important;
  }
  .pe-lg-md {
    padding-right: 1rem !important;
  }
  .pe-lg-lg {
    padding-right: 1.5rem !important;
  }
  .pe-lg-2x {
    padding-right: 2rem !important;
  }
  .pe-lg-3x {
    padding-right: 3rem !important;
  }
  .pe-lg-4x {
    padding-right: 4rem !important;
  }
  .pe-lg-5x {
    padding-right: 5rem !important;
  }
  .pe-lg-6x {
    padding-right: 6rem !important;
  }
  .pe-lg-7x {
    padding-right: 7rem !important;
  }
  .pe-lg-8x {
    padding-right: 8rem !important;
  }
  .pe-lg-9x {
    padding-right: 9rem !important;
  }
  .pe-lg-10x {
    padding-right: 10rem !important;
  }
  .pe-lg-section {
    padding-right: 7rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-xs {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-sm {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-md {
    padding-bottom: 1rem !important;
  }
  .pb-lg-lg {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-2x {
    padding-bottom: 2rem !important;
  }
  .pb-lg-3x {
    padding-bottom: 3rem !important;
  }
  .pb-lg-4x {
    padding-bottom: 4rem !important;
  }
  .pb-lg-5x {
    padding-bottom: 5rem !important;
  }
  .pb-lg-6x {
    padding-bottom: 6rem !important;
  }
  .pb-lg-7x {
    padding-bottom: 7rem !important;
  }
  .pb-lg-8x {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9x {
    padding-bottom: 9rem !important;
  }
  .pb-lg-10x {
    padding-bottom: 10rem !important;
  }
  .pb-lg-section {
    padding-bottom: 7rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-xs {
    padding-left: 0.25rem !important;
  }
  .ps-lg-sm {
    padding-left: 0.5rem !important;
  }
  .ps-lg-md {
    padding-left: 1rem !important;
  }
  .ps-lg-lg {
    padding-left: 1.5rem !important;
  }
  .ps-lg-2x {
    padding-left: 2rem !important;
  }
  .ps-lg-3x {
    padding-left: 3rem !important;
  }
  .ps-lg-4x {
    padding-left: 4rem !important;
  }
  .ps-lg-5x {
    padding-left: 5rem !important;
  }
  .ps-lg-6x {
    padding-left: 6rem !important;
  }
  .ps-lg-7x {
    padding-left: 7rem !important;
  }
  .ps-lg-8x {
    padding-left: 8rem !important;
  }
  .ps-lg-9x {
    padding-left: 9rem !important;
  }
  .ps-lg-10x {
    padding-left: 10rem !important;
  }
  .ps-lg-section {
    padding-left: 7rem !important;
  }
  .gap-lg-sm {
    gap: 0.5rem !important;
  }
  .gap-lg-md {
    gap: 1rem !important;
  }
  .gap-lg-lg {
    gap: 1.5rem !important;
  }
  .gap-lg-2x {
    gap: 2rem !important;
  }
  .gap-lg-3x {
    gap: 3rem !important;
  }
  .gap-lg-4x {
    gap: 4rem !important;
  }
  .gap-lg-5x {
    gap: 5rem !important;
  }
  .gap-lg-6x {
    gap: 6rem !important;
  }
  .gap-lg-7x {
    gap: 7rem !important;
  }
  .gap-lg-8x {
    gap: 8rem !important;
  }
  .gap-lg-9x {
    gap: 9rem !important;
  }
  .gap-lg-10x {
    gap: 10rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-xs {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-sm {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-md {
    row-gap: 1rem !important;
  }
  .row-gap-lg-lg {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-2x {
    row-gap: 2rem !important;
  }
  .row-gap-lg-3x {
    row-gap: 3rem !important;
  }
  .row-gap-lg-4x {
    row-gap: 4rem !important;
  }
  .row-gap-lg-5x {
    row-gap: 5rem !important;
  }
  .row-gap-lg-6x {
    row-gap: 6rem !important;
  }
  .row-gap-lg-7x {
    row-gap: 7rem !important;
  }
  .row-gap-lg-8x {
    row-gap: 8rem !important;
  }
  .row-gap-lg-9x {
    row-gap: 9rem !important;
  }
  .row-gap-lg-10x {
    row-gap: 10rem !important;
  }
  .row-gap-lg-section {
    row-gap: 7rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-xs {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-sm {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-md {
    column-gap: 1rem !important;
  }
  .column-gap-lg-lg {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-2x {
    column-gap: 2rem !important;
  }
  .column-gap-lg-3x {
    column-gap: 3rem !important;
  }
  .column-gap-lg-4x {
    column-gap: 4rem !important;
  }
  .column-gap-lg-5x {
    column-gap: 5rem !important;
  }
  .column-gap-lg-6x {
    column-gap: 6rem !important;
  }
  .column-gap-lg-7x {
    column-gap: 7rem !important;
  }
  .column-gap-lg-8x {
    column-gap: 8rem !important;
  }
  .column-gap-lg-9x {
    column-gap: 9rem !important;
  }
  .column-gap-lg-10x {
    column-gap: 10rem !important;
  }
  .column-gap-lg-section {
    column-gap: 7rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-xs {
    margin: 0.25rem !important;
  }
  .m-xl-sm {
    margin: 0.5rem !important;
  }
  .m-xl-md {
    margin: 1rem !important;
  }
  .m-xl-lg {
    margin: 1.5rem !important;
  }
  .m-xl-2x {
    margin: 2rem !important;
  }
  .m-xl-3x {
    margin: 3rem !important;
  }
  .m-xl-4x {
    margin: 4rem !important;
  }
  .m-xl-5x {
    margin: 5rem !important;
  }
  .m-xl-6x {
    margin: 6rem !important;
  }
  .m-xl-7x {
    margin: 7rem !important;
  }
  .m-xl-8x {
    margin: 8rem !important;
  }
  .m-xl-9x {
    margin: 9rem !important;
  }
  .m-xl-10x {
    margin: 10rem !important;
  }
  .m-xl-section {
    margin: 7rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-xs {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-sm {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-md {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-lg {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-2x {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-3x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-4x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-5x {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-6x {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-7x {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-8x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-9x {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-10x {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-section {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-md {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-lg {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-2x {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-3x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-4x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-5x {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-6x {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-7x {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-8x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-9x {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-10x {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-section {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-xs {
    margin-top: 0.25rem !important;
  }
  .mt-xl-sm {
    margin-top: 0.5rem !important;
  }
  .mt-xl-md {
    margin-top: 1rem !important;
  }
  .mt-xl-lg {
    margin-top: 1.5rem !important;
  }
  .mt-xl-2x {
    margin-top: 2rem !important;
  }
  .mt-xl-3x {
    margin-top: 3rem !important;
  }
  .mt-xl-4x {
    margin-top: 4rem !important;
  }
  .mt-xl-5x {
    margin-top: 5rem !important;
  }
  .mt-xl-6x {
    margin-top: 6rem !important;
  }
  .mt-xl-7x {
    margin-top: 7rem !important;
  }
  .mt-xl-8x {
    margin-top: 8rem !important;
  }
  .mt-xl-9x {
    margin-top: 9rem !important;
  }
  .mt-xl-10x {
    margin-top: 10rem !important;
  }
  .mt-xl-section {
    margin-top: 7rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-xs {
    margin-right: 0.25rem !important;
  }
  .me-xl-sm {
    margin-right: 0.5rem !important;
  }
  .me-xl-md {
    margin-right: 1rem !important;
  }
  .me-xl-lg {
    margin-right: 1.5rem !important;
  }
  .me-xl-2x {
    margin-right: 2rem !important;
  }
  .me-xl-3x {
    margin-right: 3rem !important;
  }
  .me-xl-4x {
    margin-right: 4rem !important;
  }
  .me-xl-5x {
    margin-right: 5rem !important;
  }
  .me-xl-6x {
    margin-right: 6rem !important;
  }
  .me-xl-7x {
    margin-right: 7rem !important;
  }
  .me-xl-8x {
    margin-right: 8rem !important;
  }
  .me-xl-9x {
    margin-right: 9rem !important;
  }
  .me-xl-10x {
    margin-right: 10rem !important;
  }
  .me-xl-section {
    margin-right: 7rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-xs {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-sm {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-md {
    margin-bottom: 1rem !important;
  }
  .mb-xl-lg {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-2x {
    margin-bottom: 2rem !important;
  }
  .mb-xl-3x {
    margin-bottom: 3rem !important;
  }
  .mb-xl-4x {
    margin-bottom: 4rem !important;
  }
  .mb-xl-5x {
    margin-bottom: 5rem !important;
  }
  .mb-xl-6x {
    margin-bottom: 6rem !important;
  }
  .mb-xl-7x {
    margin-bottom: 7rem !important;
  }
  .mb-xl-8x {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9x {
    margin-bottom: 9rem !important;
  }
  .mb-xl-10x {
    margin-bottom: 10rem !important;
  }
  .mb-xl-section {
    margin-bottom: 7rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-xs {
    margin-left: 0.25rem !important;
  }
  .ms-xl-sm {
    margin-left: 0.5rem !important;
  }
  .ms-xl-md {
    margin-left: 1rem !important;
  }
  .ms-xl-lg {
    margin-left: 1.5rem !important;
  }
  .ms-xl-2x {
    margin-left: 2rem !important;
  }
  .ms-xl-3x {
    margin-left: 3rem !important;
  }
  .ms-xl-4x {
    margin-left: 4rem !important;
  }
  .ms-xl-5x {
    margin-left: 5rem !important;
  }
  .ms-xl-6x {
    margin-left: 6rem !important;
  }
  .ms-xl-7x {
    margin-left: 7rem !important;
  }
  .ms-xl-8x {
    margin-left: 8rem !important;
  }
  .ms-xl-9x {
    margin-left: 9rem !important;
  }
  .ms-xl-10x {
    margin-left: 10rem !important;
  }
  .ms-xl-section {
    margin-left: 7rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-xs {
    padding: 0.25rem !important;
  }
  .p-xl-sm {
    padding: 0.5rem !important;
  }
  .p-xl-md {
    padding: 1rem !important;
  }
  .p-xl-lg {
    padding: 1.5rem !important;
  }
  .p-xl-2x {
    padding: 2rem !important;
  }
  .p-xl-3x {
    padding: 3rem !important;
  }
  .p-xl-4x {
    padding: 4rem !important;
  }
  .p-xl-5x {
    padding: 5rem !important;
  }
  .p-xl-6x {
    padding: 6rem !important;
  }
  .p-xl-7x {
    padding: 7rem !important;
  }
  .p-xl-8x {
    padding: 8rem !important;
  }
  .p-xl-9x {
    padding: 9rem !important;
  }
  .p-xl-10x {
    padding: 10rem !important;
  }
  .p-xl-section {
    padding: 7rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-xs {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-sm {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-md {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-lg {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-2x {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-3x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-4x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-5x {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-6x {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-7x {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xl-8x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-9x {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-10x {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-section {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-lg {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-2x {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-3x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-4x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-5x {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-6x {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-7x {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xl-8x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-9x {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-10x {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-section {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-xs {
    padding-top: 0.25rem !important;
  }
  .pt-xl-sm {
    padding-top: 0.5rem !important;
  }
  .pt-xl-md {
    padding-top: 1rem !important;
  }
  .pt-xl-lg {
    padding-top: 1.5rem !important;
  }
  .pt-xl-2x {
    padding-top: 2rem !important;
  }
  .pt-xl-3x {
    padding-top: 3rem !important;
  }
  .pt-xl-4x {
    padding-top: 4rem !important;
  }
  .pt-xl-5x {
    padding-top: 5rem !important;
  }
  .pt-xl-6x {
    padding-top: 6rem !important;
  }
  .pt-xl-7x {
    padding-top: 7rem !important;
  }
  .pt-xl-8x {
    padding-top: 8rem !important;
  }
  .pt-xl-9x {
    padding-top: 9rem !important;
  }
  .pt-xl-10x {
    padding-top: 10rem !important;
  }
  .pt-xl-section {
    padding-top: 7rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-xs {
    padding-right: 0.25rem !important;
  }
  .pe-xl-sm {
    padding-right: 0.5rem !important;
  }
  .pe-xl-md {
    padding-right: 1rem !important;
  }
  .pe-xl-lg {
    padding-right: 1.5rem !important;
  }
  .pe-xl-2x {
    padding-right: 2rem !important;
  }
  .pe-xl-3x {
    padding-right: 3rem !important;
  }
  .pe-xl-4x {
    padding-right: 4rem !important;
  }
  .pe-xl-5x {
    padding-right: 5rem !important;
  }
  .pe-xl-6x {
    padding-right: 6rem !important;
  }
  .pe-xl-7x {
    padding-right: 7rem !important;
  }
  .pe-xl-8x {
    padding-right: 8rem !important;
  }
  .pe-xl-9x {
    padding-right: 9rem !important;
  }
  .pe-xl-10x {
    padding-right: 10rem !important;
  }
  .pe-xl-section {
    padding-right: 7rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-xs {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-sm {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-md {
    padding-bottom: 1rem !important;
  }
  .pb-xl-lg {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-2x {
    padding-bottom: 2rem !important;
  }
  .pb-xl-3x {
    padding-bottom: 3rem !important;
  }
  .pb-xl-4x {
    padding-bottom: 4rem !important;
  }
  .pb-xl-5x {
    padding-bottom: 5rem !important;
  }
  .pb-xl-6x {
    padding-bottom: 6rem !important;
  }
  .pb-xl-7x {
    padding-bottom: 7rem !important;
  }
  .pb-xl-8x {
    padding-bottom: 8rem !important;
  }
  .pb-xl-9x {
    padding-bottom: 9rem !important;
  }
  .pb-xl-10x {
    padding-bottom: 10rem !important;
  }
  .pb-xl-section {
    padding-bottom: 7rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-xs {
    padding-left: 0.25rem !important;
  }
  .ps-xl-sm {
    padding-left: 0.5rem !important;
  }
  .ps-xl-md {
    padding-left: 1rem !important;
  }
  .ps-xl-lg {
    padding-left: 1.5rem !important;
  }
  .ps-xl-2x {
    padding-left: 2rem !important;
  }
  .ps-xl-3x {
    padding-left: 3rem !important;
  }
  .ps-xl-4x {
    padding-left: 4rem !important;
  }
  .ps-xl-5x {
    padding-left: 5rem !important;
  }
  .ps-xl-6x {
    padding-left: 6rem !important;
  }
  .ps-xl-7x {
    padding-left: 7rem !important;
  }
  .ps-xl-8x {
    padding-left: 8rem !important;
  }
  .ps-xl-9x {
    padding-left: 9rem !important;
  }
  .ps-xl-10x {
    padding-left: 10rem !important;
  }
  .ps-xl-section {
    padding-left: 7rem !important;
  }
  .gap-xl-sm {
    gap: 0.5rem !important;
  }
  .gap-xl-md {
    gap: 1rem !important;
  }
  .gap-xl-lg {
    gap: 1.5rem !important;
  }
  .gap-xl-2x {
    gap: 2rem !important;
  }
  .gap-xl-3x {
    gap: 3rem !important;
  }
  .gap-xl-4x {
    gap: 4rem !important;
  }
  .gap-xl-5x {
    gap: 5rem !important;
  }
  .gap-xl-6x {
    gap: 6rem !important;
  }
  .gap-xl-7x {
    gap: 7rem !important;
  }
  .gap-xl-8x {
    gap: 8rem !important;
  }
  .gap-xl-9x {
    gap: 9rem !important;
  }
  .gap-xl-10x {
    gap: 10rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-xs {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-sm {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-md {
    row-gap: 1rem !important;
  }
  .row-gap-xl-lg {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-2x {
    row-gap: 2rem !important;
  }
  .row-gap-xl-3x {
    row-gap: 3rem !important;
  }
  .row-gap-xl-4x {
    row-gap: 4rem !important;
  }
  .row-gap-xl-5x {
    row-gap: 5rem !important;
  }
  .row-gap-xl-6x {
    row-gap: 6rem !important;
  }
  .row-gap-xl-7x {
    row-gap: 7rem !important;
  }
  .row-gap-xl-8x {
    row-gap: 8rem !important;
  }
  .row-gap-xl-9x {
    row-gap: 9rem !important;
  }
  .row-gap-xl-10x {
    row-gap: 10rem !important;
  }
  .row-gap-xl-section {
    row-gap: 7rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-xs {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-sm {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-md {
    column-gap: 1rem !important;
  }
  .column-gap-xl-lg {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-2x {
    column-gap: 2rem !important;
  }
  .column-gap-xl-3x {
    column-gap: 3rem !important;
  }
  .column-gap-xl-4x {
    column-gap: 4rem !important;
  }
  .column-gap-xl-5x {
    column-gap: 5rem !important;
  }
  .column-gap-xl-6x {
    column-gap: 6rem !important;
  }
  .column-gap-xl-7x {
    column-gap: 7rem !important;
  }
  .column-gap-xl-8x {
    column-gap: 8rem !important;
  }
  .column-gap-xl-9x {
    column-gap: 9rem !important;
  }
  .column-gap-xl-10x {
    column-gap: 10rem !important;
  }
  .column-gap-xl-section {
    column-gap: 7rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-xs {
    margin: 0.25rem !important;
  }
  .m-xxl-sm {
    margin: 0.5rem !important;
  }
  .m-xxl-md {
    margin: 1rem !important;
  }
  .m-xxl-lg {
    margin: 1.5rem !important;
  }
  .m-xxl-2x {
    margin: 2rem !important;
  }
  .m-xxl-3x {
    margin: 3rem !important;
  }
  .m-xxl-4x {
    margin: 4rem !important;
  }
  .m-xxl-5x {
    margin: 5rem !important;
  }
  .m-xxl-6x {
    margin: 6rem !important;
  }
  .m-xxl-7x {
    margin: 7rem !important;
  }
  .m-xxl-8x {
    margin: 8rem !important;
  }
  .m-xxl-9x {
    margin: 9rem !important;
  }
  .m-xxl-10x {
    margin: 10rem !important;
  }
  .m-xxl-section {
    margin: 7rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-xs {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-sm {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-md {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-lg {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-2x {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-3x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-4x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-5x {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-6x {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-7x {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-8x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-9x {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-10x {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-section {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-xs {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-md {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-lg {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-2x {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-3x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-4x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-5x {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-6x {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-7x {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-8x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-9x {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-10x {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-section {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-xs {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-sm {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-md {
    margin-top: 1rem !important;
  }
  .mt-xxl-lg {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-2x {
    margin-top: 2rem !important;
  }
  .mt-xxl-3x {
    margin-top: 3rem !important;
  }
  .mt-xxl-4x {
    margin-top: 4rem !important;
  }
  .mt-xxl-5x {
    margin-top: 5rem !important;
  }
  .mt-xxl-6x {
    margin-top: 6rem !important;
  }
  .mt-xxl-7x {
    margin-top: 7rem !important;
  }
  .mt-xxl-8x {
    margin-top: 8rem !important;
  }
  .mt-xxl-9x {
    margin-top: 9rem !important;
  }
  .mt-xxl-10x {
    margin-top: 10rem !important;
  }
  .mt-xxl-section {
    margin-top: 7rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-xs {
    margin-right: 0.25rem !important;
  }
  .me-xxl-sm {
    margin-right: 0.5rem !important;
  }
  .me-xxl-md {
    margin-right: 1rem !important;
  }
  .me-xxl-lg {
    margin-right: 1.5rem !important;
  }
  .me-xxl-2x {
    margin-right: 2rem !important;
  }
  .me-xxl-3x {
    margin-right: 3rem !important;
  }
  .me-xxl-4x {
    margin-right: 4rem !important;
  }
  .me-xxl-5x {
    margin-right: 5rem !important;
  }
  .me-xxl-6x {
    margin-right: 6rem !important;
  }
  .me-xxl-7x {
    margin-right: 7rem !important;
  }
  .me-xxl-8x {
    margin-right: 8rem !important;
  }
  .me-xxl-9x {
    margin-right: 9rem !important;
  }
  .me-xxl-10x {
    margin-right: 10rem !important;
  }
  .me-xxl-section {
    margin-right: 7rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-xs {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-sm {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-md {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-lg {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-2x {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-3x {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-4x {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-5x {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-6x {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-7x {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-8x {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9x {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-10x {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-section {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-xs {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-sm {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-md {
    margin-left: 1rem !important;
  }
  .ms-xxl-lg {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-2x {
    margin-left: 2rem !important;
  }
  .ms-xxl-3x {
    margin-left: 3rem !important;
  }
  .ms-xxl-4x {
    margin-left: 4rem !important;
  }
  .ms-xxl-5x {
    margin-left: 5rem !important;
  }
  .ms-xxl-6x {
    margin-left: 6rem !important;
  }
  .ms-xxl-7x {
    margin-left: 7rem !important;
  }
  .ms-xxl-8x {
    margin-left: 8rem !important;
  }
  .ms-xxl-9x {
    margin-left: 9rem !important;
  }
  .ms-xxl-10x {
    margin-left: 10rem !important;
  }
  .ms-xxl-section {
    margin-left: 7rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-xs {
    padding: 0.25rem !important;
  }
  .p-xxl-sm {
    padding: 0.5rem !important;
  }
  .p-xxl-md {
    padding: 1rem !important;
  }
  .p-xxl-lg {
    padding: 1.5rem !important;
  }
  .p-xxl-2x {
    padding: 2rem !important;
  }
  .p-xxl-3x {
    padding: 3rem !important;
  }
  .p-xxl-4x {
    padding: 4rem !important;
  }
  .p-xxl-5x {
    padding: 5rem !important;
  }
  .p-xxl-6x {
    padding: 6rem !important;
  }
  .p-xxl-7x {
    padding: 7rem !important;
  }
  .p-xxl-8x {
    padding: 8rem !important;
  }
  .p-xxl-9x {
    padding: 9rem !important;
  }
  .p-xxl-10x {
    padding: 10rem !important;
  }
  .p-xxl-section {
    padding: 7rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-xs {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-sm {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-md {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-lg {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-2x {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-3x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-4x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-5x {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-6x {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-7x {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xxl-8x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-9x {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-10x {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-section {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-xs {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-lg {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-2x {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-3x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-4x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-5x {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-6x {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-7x {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xxl-8x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-9x {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-10x {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-section {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-xs {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-sm {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-md {
    padding-top: 1rem !important;
  }
  .pt-xxl-lg {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-2x {
    padding-top: 2rem !important;
  }
  .pt-xxl-3x {
    padding-top: 3rem !important;
  }
  .pt-xxl-4x {
    padding-top: 4rem !important;
  }
  .pt-xxl-5x {
    padding-top: 5rem !important;
  }
  .pt-xxl-6x {
    padding-top: 6rem !important;
  }
  .pt-xxl-7x {
    padding-top: 7rem !important;
  }
  .pt-xxl-8x {
    padding-top: 8rem !important;
  }
  .pt-xxl-9x {
    padding-top: 9rem !important;
  }
  .pt-xxl-10x {
    padding-top: 10rem !important;
  }
  .pt-xxl-section {
    padding-top: 7rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-xs {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-sm {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-md {
    padding-right: 1rem !important;
  }
  .pe-xxl-lg {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-2x {
    padding-right: 2rem !important;
  }
  .pe-xxl-3x {
    padding-right: 3rem !important;
  }
  .pe-xxl-4x {
    padding-right: 4rem !important;
  }
  .pe-xxl-5x {
    padding-right: 5rem !important;
  }
  .pe-xxl-6x {
    padding-right: 6rem !important;
  }
  .pe-xxl-7x {
    padding-right: 7rem !important;
  }
  .pe-xxl-8x {
    padding-right: 8rem !important;
  }
  .pe-xxl-9x {
    padding-right: 9rem !important;
  }
  .pe-xxl-10x {
    padding-right: 10rem !important;
  }
  .pe-xxl-section {
    padding-right: 7rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-xs {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-sm {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-md {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-lg {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-2x {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-3x {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-4x {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-5x {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-6x {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-7x {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-8x {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-9x {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-10x {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-section {
    padding-bottom: 7rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-xs {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-sm {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-md {
    padding-left: 1rem !important;
  }
  .ps-xxl-lg {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-2x {
    padding-left: 2rem !important;
  }
  .ps-xxl-3x {
    padding-left: 3rem !important;
  }
  .ps-xxl-4x {
    padding-left: 4rem !important;
  }
  .ps-xxl-5x {
    padding-left: 5rem !important;
  }
  .ps-xxl-6x {
    padding-left: 6rem !important;
  }
  .ps-xxl-7x {
    padding-left: 7rem !important;
  }
  .ps-xxl-8x {
    padding-left: 8rem !important;
  }
  .ps-xxl-9x {
    padding-left: 9rem !important;
  }
  .ps-xxl-10x {
    padding-left: 10rem !important;
  }
  .ps-xxl-section {
    padding-left: 7rem !important;
  }
  .gap-xxl-sm {
    gap: 0.5rem !important;
  }
  .gap-xxl-md {
    gap: 1rem !important;
  }
  .gap-xxl-lg {
    gap: 1.5rem !important;
  }
  .gap-xxl-2x {
    gap: 2rem !important;
  }
  .gap-xxl-3x {
    gap: 3rem !important;
  }
  .gap-xxl-4x {
    gap: 4rem !important;
  }
  .gap-xxl-5x {
    gap: 5rem !important;
  }
  .gap-xxl-6x {
    gap: 6rem !important;
  }
  .gap-xxl-7x {
    gap: 7rem !important;
  }
  .gap-xxl-8x {
    gap: 8rem !important;
  }
  .gap-xxl-9x {
    gap: 9rem !important;
  }
  .gap-xxl-10x {
    gap: 10rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-xs {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-sm {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-md {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-lg {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-2x {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-3x {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-4x {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-5x {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-6x {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-7x {
    row-gap: 7rem !important;
  }
  .row-gap-xxl-8x {
    row-gap: 8rem !important;
  }
  .row-gap-xxl-9x {
    row-gap: 9rem !important;
  }
  .row-gap-xxl-10x {
    row-gap: 10rem !important;
  }
  .row-gap-xxl-section {
    row-gap: 7rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-xs {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-sm {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-md {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-lg {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-2x {
    column-gap: 2rem !important;
  }
  .column-gap-xxl-3x {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-4x {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-5x {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-6x {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-7x {
    column-gap: 7rem !important;
  }
  .column-gap-xxl-8x {
    column-gap: 8rem !important;
  }
  .column-gap-xxl-9x {
    column-gap: 9rem !important;
  }
  .column-gap-xxl-10x {
    column-gap: 10rem !important;
  }
  .column-gap-xxl-section {
    column-gap: 7rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.75rem !important;
  }
  .fs-2 {
    font-size: 2.25rem !important;
  }
  .fs-3 {
    font-size: 2rem !important;
  }
  .fs-4 {
    font-size: 1.75rem !important;
  }
  .fs-5 {
    font-size: 1.5rem !important;
  }
  .fs-6 {
    font-size: 1.25rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* inter-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("../../../../public/fonts/inter-v18-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../../../../public/fonts/inter-v13-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../../../../public/fonts/inter-v18-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../../../../public/fonts/inter-v13-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../../../../public/fonts/inter-v13-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "DCC Ash";
  font-style: normal;
  font-weight: normal;
  src: url("../../../../public/fonts/dcc-ash.otf") format("opentype"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
:root {
  overscroll-behavior: none;
}

body {
  --bs-body-font-weight: 300;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-font-variant-ligatures: none;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body main {
  flex-grow: 1;
  overflow-x: hidden;
}

a,
a[href^="mailto:"],
a[href^="tel:"] {
  text-decoration: none;
}

p:only-child, p:last-child {
  margin-bottom: 0;
}
p:empty {
  display: none;
}

ul {
  padding-left: 1rem;
}

* {
  scroll-margin-top: 200px;
}

/**
 * @mixin responsive spacing
 *
 * @description A mixin for applyinng margin & padding in different directions using Bootstraps rfs-fluid-value Function.
 *
 * @param {string} $property - The CSS property to apply like margin or padding.
 * @param {string} $direction - The direction in which to apply the spacing. Possible values: 'all', 'y', 'x', 'top', 'bottom', 'left', 'right'.
 * @param {string} $value - The value of the spacing to apply.
 *
 * @example Usage:
 *   .my-element {
 *     @include spacing('margin', 'all', '20px');
 *   }
 */
.headline-section-ash {
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
}
@media (min-width: 1200px) {
  .headline-section-ash {
    font-size: 2.5rem;
  }
}

.headline-section {
  font-family: "Inter";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 1200px) {
  .headline-section {
    font-size: 2rem;
  }
}

.image-left {
  object-position: left;
}

.image-leftTop {
  object-position: left top;
}

.image-leftBottom {
  object-position: left bottom;
}

.image-right {
  object-position: right;
}

.image-rightTop {
  object-position: right top;
}

.image-rightBottom {
  object-position: right bottom;
}

.image-top {
  object-position: top;
}

.image-middle {
  object-position: center;
}

.image-bottom {
  object-position: bottom;
}

.btn-custom {
  display: flex;
  border-image-source: url("~/public/img/btn-outline.svg");
  border-image-repeat: stretch;
  border-image-slice: 1;
  border-style: inset;
  border-width: 0.2rem;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}
.btn-custom a {
  padding-block: 0.5rem;
  padding-inline: calc(0.915rem + 0.78vw);
  color: #0068b5;
  transition: color 0.3s ease-in-out;
  font-weight: 300;
}
@media (min-width: 1200px) {
  .btn-custom a {
    padding-inline: 1.5rem;
  }
}
.btn-custom:hover {
  background-color: #0068b5;
}
.btn-custom:hover a {
  color: #fff;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding: 0;
}
@media (max-width: 575.98px) {
  .container {
    padding-inline: 1rem;
  }
}

.container-header {
  --bs-gutter-x: calc(8.3333333333% + 1.5rem);
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  padding-inline: 0;
  max-width: 1550px;
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  position: relative;
}

.footer-main {
  background-color: #3d3e3e;
  padding-block: calc(0.865rem + 0.18vw);
}
@media (min-width: 1200px) {
  .footer-main {
    padding-block: 1rem;
  }
}
.footer-main .nav {
  --bs-nav-link-color: #fff;
  --bs-nav-link-hover-color: #fff;
  --bs-nav-link-padding-x: 0px;
  column-gap: calc(0.92125rem + 0.855vw);
}
@media (min-width: 1200px) {
  .footer-main .nav {
    column-gap: 1.5625rem;
  }
}
@media (max-width: 575.98px) {
  .footer-main .nav {
    column-gap: 0.75rem;
  }
}

.header {
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}
.header-logo {
  transform-origin: left;
  transition: transform 0.2s ease-in-out;
}

.navbar-header {
  --bs-navbar-padding-y: 0.5rem;
  padding-inline: calc(1.065rem + 2.58vw);
  background-color: #fff;
  transition: padding 0.2s ease-in-out;
}
@media (min-width: 1200px) {
  .navbar-header {
    padding-inline: 3rem;
  }
}
.navbar-header .navbar-brand {
  --bs-navbar-brand-padding-y: 1rem;
}
.navbar-header .navbar-toggler {
  border-radius: 0;
  border: 0;
  outline: 0;
  line-height: normal;
  cursor: pointer;
  width: 35px;
  height: 40px;
  border: solid 1px transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.navbar-header .navbar-toggler.open span:nth-child(1) {
  transform: rotate(45deg) translate(10px, 9px);
}
.navbar-header .navbar-toggler.open span:nth-child(2) {
  opacity: 0;
}
.navbar-header .navbar-toggler.open span:nth-child(3) {
  transform: rotate(-45deg) translate(8px, -8px);
}
.navbar-header .navbar-toggler span {
  display: block;
  height: 5px;
  width: 100%;
  border-radius: 50rem;
  background-color: #0068b5;
}
.navbar-header .navbar-toggler i {
  color: #0068b5;
}
.navbar-header .nav-item {
  border-bottom: solid 1px #fff;
}
.navbar-header .nav-item:last-child {
  border-bottom: 0;
}
.navbar-header .nav-link {
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-nav-link-padding-y: 1rem;
  --bs-nav-link-font-weight: 400;
  transition: padding 0.2s ease-in-out;
}
.navbar-header .nav-link,
.navbar-header .dropdown-link {
  font-family: "Inter";
  font-size: calc(0.865rem + 0.18vw);
  font-weight: 300;
  line-height: normal;
  color: #fff;
}
@media (min-width: 1200px) {
  .navbar-header .nav-link,
  .navbar-header .dropdown-link {
    font-size: 1rem;
  }
}
.navbar-header .nav-link.active, .navbar-header .nav-link:hover,
.navbar-header .dropdown-link.active,
.navbar-header .dropdown-link:hover {
  font-family: "Inter";
  font-size: calc(0.865rem + 0.18vw);
  font-weight: 300;
  line-height: normal;
  color: #ff6600;
  text-shadow: 0px 0px 1px #ff6600;
}
@media (min-width: 1200px) {
  .navbar-header .nav-link.active, .navbar-header .nav-link:hover,
  .navbar-header .dropdown-link.active,
  .navbar-header .dropdown-link:hover {
    font-size: 1rem;
  }
}
.navbar-header .dropdown-menu {
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  border-top: solid 1px #fff;
  padding-left: 2rem;
}
.navbar-header .dropdown-item {
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-item-padding-x: 0;
  --bs-dropdown-padding-y: 1rem;
  padding-block: 1rem;
  border-bottom: solid 1px #fff;
}
.navbar-header .dropdown-item:last-child {
  border-bottom: 0;
}
@media (max-width: 575.98px) {
  .navbar-header {
    padding-inline: 1rem;
  }
}

.page-section {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
@media (max-width: 1399.98px) {
  .page-section {
    padding-top: calc(1.465rem + 7.38vw);
    padding-bottom: calc(1.465rem + 7.38vw);
  }
}
.page-section.bg-primary .headline-content,
.page-section.bg-primary .text-content, .page-section.bg-secondary .headline-content,
.page-section.bg-secondary .text-content, .page-section.bg-green .headline-content,
.page-section.bg-green .text-content, .page-section.bg-red .headline-content,
.page-section.bg-red .text-content {
  color: #fff;
}
.page-section.has-graphic {
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
}
.page-section.has-graphic.is-last-block {
  mask-image: url("~/public/img/bg-section-border-top.svg");
  margin-top: -15px;
}
.page-section.sibling-after {
  mask-image: url("~/public/img/bg-section-border-top.svg");
}
.page-section.sibling-before {
  mask-image: url("~/public/img/bg-section-border-both.svg");
  margin-block: -15px;
}
.page-section.sibling-both {
  mask-image: url("~/public/img/bg-section-border-both.svg");
  margin-block: -15px;
  position: relative;
  z-index: 1;
}

.article h1, .article .h1 {
  font-size: 2rem;
}
.article h2, .article .h2 {
  font-size: 1.75rem;
}
.article h3, .article .h3 {
  font-size: 1.5rem;
}
.article h4, .article .h4 {
  font-size: 1.25rem;
}
.article h5, .article .h5 {
  font-size: 1rem;
}
.article h6, .article .h6 {
  font-size: 0.75rem;
}
.article h1, .article .h1,
.article h2,
.article .h2,
.article h3,
.article .h3,
.article h4,
.article .h4,
.article h5,
.article .h5,
.article h6,
.article .h6 {
  font-family: "Oxanium", sans-serif;
  color: #404040;
  margin-top: 2rem;
}

.btn {
  --bs-btn-border-width: 1px;
  --bs-btn-border-radius: 0;
  font-family: "Inter";
  font-size: calc(0.865rem + 0.18vw);
  font-weight: 300;
  line-height: normal;
  padding-block: 0.8125rem;
  padding-inline: calc(0.89rem + 0.48vw);
}
@media (min-width: 1200px) {
  .btn {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .btn {
    padding-inline: 1.25rem;
  }
}

.btn-white {
  --bs-btn-color: #0068b5;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d8d9da;
  --bs-btn-disabled-border-color: #d8d9da;
}

.btn-white-primary {
  --bs-btn-color: #0068b5;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d8d9da;
  --bs-btn-disabled-border-color: #d8d9da;
}

.btn-white-secondary {
  --bs-btn-color: #ff6600;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #ff6600;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d8d9da;
  --bs-btn-disabled-border-color: #d8d9da;
}

.btn-white-green {
  --bs-btn-color: #384e30;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #384e30;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d8d9da;
  --bs-btn-disabled-border-color: #d8d9da;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ff6600;
  --bs-btn-border-color: #ff6600;
  --bs-btn-hover-color: #ff6600;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #ff6600;
  --bs-btn-focus-shadow-rgb: #fff;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #ff6600;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d8d9da;
  --bs-btn-disabled-border-color: #d8d9da;
}

.btn-outline-primary {
  --bs-btn-color: #0068b5;
  --bs-btn-border-color: #0068b5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0068b5;
  --bs-btn-hover-border-color: #0068b5;
  --bs-btn-focus-shadow-rgb: 0, 104, 181;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0068b5;
  --bs-btn-active-border-color: #0068b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #0068b5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0068b5;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #ff6600;
  --bs-btn-border-color: #ff6600;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff6600;
  --bs-btn-hover-border-color: #ff6600;
  --bs-btn-focus-shadow-rgb: 255, 102, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff6600;
  --bs-btn-active-border-color: #ff6600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #ff6600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff6600;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #384e30;
  --bs-btn-border-color: #384e30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #384e30;
  --bs-btn-hover-border-color: #384e30;
  --bs-btn-focus-shadow-rgb: 56, 78, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #384e30;
  --bs-btn-active-border-color: #384e30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #384e30;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #384e30;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #cc0000;
  --bs-btn-border-color: #cc0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cc0000;
  --bs-btn-hover-border-color: #cc0000;
  --bs-btn-focus-shadow-rgb: 204, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc0000;
  --bs-btn-active-border-color: #cc0000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #cc0000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cc0000;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f7f8f9;
  --bs-btn-border-color: #f7f8f9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f7f8f9;
  --bs-btn-hover-border-color: #f7f8f9;
  --bs-btn-focus-shadow-rgb: 247, 248, 249;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f7f8f9;
  --bs-btn-active-border-color: #f7f8f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #f7f8f9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f7f8f9;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #333435;
  --bs-btn-border-color: #333435;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333435;
  --bs-btn-hover-border-color: #333435;
  --bs-btn-focus-shadow-rgb: 51, 52, 53;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333435;
  --bs-btn-active-border-color: #333435;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #333435;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333435;
  --bs-gradient: none;
}

.btn-outline-light-blue {
  --bs-btn-color: #cfe7ff;
  --bs-btn-border-color: #cfe7ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cfe7ff;
  --bs-btn-hover-border-color: #cfe7ff;
  --bs-btn-focus-shadow-rgb: 207, 231, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cfe7ff;
  --bs-btn-active-border-color: #cfe7ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #cfe7ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cfe7ff;
  --bs-gradient: none;
}

.btn-outline-light-gray {
  --bs-btn-color: #edf0f0;
  --bs-btn-border-color: #edf0f0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #edf0f0;
  --bs-btn-hover-border-color: #edf0f0;
  --bs-btn-focus-shadow-rgb: 237, 240, 240;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #edf0f0;
  --bs-btn-active-border-color: #edf0f0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #edf0f0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #edf0f0;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #384e30;
  --bs-btn-border-color: #384e30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #384e30;
  --bs-btn-hover-border-color: #384e30;
  --bs-btn-focus-shadow-rgb: 56, 78, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #384e30;
  --bs-btn-active-border-color: #384e30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #384e30;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #384e30;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #3d3e3e;
  --bs-btn-border-color: #3d3e3e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3d3e3e;
  --bs-btn-hover-border-color: #3d3e3e;
  --bs-btn-focus-shadow-rgb: 61, 62, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d3e3e;
  --bs-btn-active-border-color: #3d3e3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #3d3e3e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3d3e3e;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #cc0000;
  --bs-btn-border-color: #cc0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cc0000;
  --bs-btn-hover-border-color: #cc0000;
  --bs-btn-focus-shadow-rgb: 204, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc0000;
  --bs-btn-active-border-color: #cc0000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 26, 26, 0.125);
  --bs-btn-disabled-color: #cc0000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cc0000;
  --bs-gradient: none;
}

.btn-group {
  display: flex;
  gap: 1rem;
}

.button-fullwidth {
  width: 100%;
  mask-image: url("~/public/img/button-fullwidth.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
  display: flex;
}
.button-fullwidth.bg-light-blue a.ash,
.button-fullwidth.bg-light-blue a.inter, .button-fullwidth.bg-light-gray a.ash,
.button-fullwidth.bg-light-gray a.inter {
  color: #001a1a;
}
.button-fullwidth a {
  flex: 1;
  text-align: center;
  padding-block: calc(0.98375rem + 1.605vw);
  line-height: 1;
  color: #fff;
  font-size: calc(1.04rem + 2.28vw);
  display: block;
}
@media (min-width: 1200px) {
  .button-fullwidth a {
    padding-block: 2.1875rem;
  }
}
@media (min-width: 1200px) {
  .button-fullwidth a {
    font-size: 2.75rem;
  }
}
.button-fullwidth a.ash {
  font-family: "DCC Ash";
  font-weight: 400;
  mask-image: url("~/public/img/bg-white.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
}
.button-fullwidth a.inter {
  font-family: "Inter";
  font-weight: 700;
}

.link.with-graphic .btn {
  mask-image: url("~/public/img/button.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
}

textarea.form-control {
  min-height: 7.5rem;
}

.form-select,
.form-control {
  border-radius: 0;
  background-color: transparent;
  line-height: 1;
  padding-inline: 1.0625rem;
  padding-block: 1.25rem;
  font-weight: 300;
  color: #fff;
}
.form-select:focus,
.form-control:focus {
  box-shadow: none;
  background-color: transparent;
  color: #fff;
}
.form-select:focus::placeholder,
.form-control:focus::placeholder {
  opacity: 0.5;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E");
  color: #fff;
}
.form-select option:not(:first-of-type) {
  color: #001a1a;
}
.form-select option[value=""][disabled] {
  display: none;
}

.form-control {
  border-color: #fff;
}

.form-label {
  font-family: "Inter";
  font-size: 0.85rem;
  font-weight: 300;
  line-height: 1.65;
  margin-bottom: 0;
}

input[type=file]::before {
  content: "\e09a";
  font-family: "Font Awesome 5 Pro";
  padding-right: 2rem;
}
input[type=file]::file-selector-button {
  padding-inline: 1.5rem;
  display: none;
}
input[type=file]::file-selector-button::before {
  content: "\e09a";
  font-family: "Font Awesome 5 Pro";
}

.accept-privacy {
  margin-top: 0.3em;
}
.accept-privacy-text {
  font-family: "Inter";
  font-size: 0.85rem;
  font-weight: 300;
  line-height: 1.65;
}

.col-12:has(> input[id*=_function]) {
  display: none;
}
.col-12:has(> input[id*=_function]) input {
  display: none;
  pointer-events: none;
}

.headline {
  margin-bottom: calc(0.965rem + 1.38vw);
}
@media (min-width: 1200px) {
  .headline {
    margin-bottom: 2rem;
  }
}
.headline-accent {
  color: #0068b5;
}
.headline-content.section {
  font-family: "Inter";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 1200px) {
  .headline-content.section {
    font-size: 2rem;
  }
}
.headline-content.section-ash {
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
}
@media (min-width: 1200px) {
  .headline-content.section-ash {
    font-size: 2.5rem;
  }
}

.swiper-container {
  position: relative;
}
@media (max-width: 767.98px) {
  .swiper-container {
    padding: 0 2rem;
  }
}
.swiper-container:hover .swiper-button {
  opacity: 1;
}

.swiper .swiper-slide {
  height: auto;
  display: flex;
  cursor: grab;
}

.swiper-button {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 26, 26, 0.3);
  cursor: pointer;
  z-index: 2;
  padding: 0.5rem;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
}
.swiper-button:hover {
  background-color: #001a1a;
}
.swiper-button.swiper-button-disabled {
  cursor: default;
  background-color: rgba(0, 26, 26, 0.5);
}
.swiper-button i {
  color: #fff;
  font-size: 1rem;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}

.nav-pills {
  gap: 1.5rem;
}
.nav-pills .nav-link {
  --bs-nav-link-padding-x: 0;
  font-family: "Inter";
  font-size: 0.8rem;
  font-weight: 300;
  line-height: normal;
}

.tab-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.tab-content .tab-pane {
  grid-column: 1;
  grid-row: 1;
  display: block;
  visibility: hidden;
}
.tab-content .tab-pane.active {
  visibility: visible;
}

.text-boxed {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.text-boxed .headline {
  margin-bottom: 0;
}
.text-boxed .headline .headline-content {
  text-align: center;
  margin-bottom: 0;
}
.text-boxed .headline .headline-content.headline-section-ash {
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
}
@media (min-width: 1200px) {
  .text-boxed .headline .headline-content.headline-section-ash {
    font-size: 2.5rem;
  }
}
.text-boxed .headline .headline-content.headline-section {
  font-family: "Inter";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 1200px) {
  .text-boxed .headline .headline-content.headline-section {
    font-size: 2rem;
  }
}

.offcanvas {
  background-color: transparent;
}
.offcanvas-backdrop {
  top: var(--header-height);
  background-color: #000;
}
.offcanvas.offcanvas-end {
  border: 0;
  top: 98px;
}
@media (max-width: 1199.98px) {
  .offcanvas.offcanvas-end {
    top: 83px;
  }
}
.offcanvas-header, .offcanvas-body {
  background-color: #0068b5;
}
.offcanvas-body {
  flex-grow: unset;
}
@media (max-width: 575.98px) {
  .offcanvas.offcanvas-end {
    width: 100vw;
  }
}

.image {
  position: relative;
  margin-bottom: 0;
}
.image-copyright {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  color: #001a1a;
  padding: 0.1rem 0.5rem;
  font-size: 0.7rem;
}
.image-description {
  font-style: italic;
}

.educations-list,
.job-list {
  display: grid;
  gap: calc(0.9525rem + 1.23vw);
  grid-template-columns: repeat(3, minmax(0, 366px));
  grid-auto-rows: 1fr;
}
@media (min-width: 1200px) {
  .educations-list,
  .job-list {
    gap: 1.875rem;
  }
}
.educations-list-item,
.job-list-item {
  background-color: #cfe7ff;
  mask-image: url("~/public/img/bg-card.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
  position: relative;
  display: flex;
  flex-direction: column;
}
.educations-list-item-head img,
.job-list-item-head img {
  min-height: 120px;
  width: 100%;
  object-fit: cover;
}
.educations-list-item-body,
.job-list-item-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-block: calc(0.9525rem + 1.23vw);
  padding-left: calc(0.9525rem + 1.23vw);
  padding-right: calc(1.165rem + 3.78vw);
  position: relative;
}
@media (min-width: 1200px) {
  .educations-list-item-body,
  .job-list-item-body {
    padding-block: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .educations-list-item-body,
  .job-list-item-body {
    padding-left: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .educations-list-item-body,
  .job-list-item-body {
    padding-right: 4rem;
  }
}
.educations-list-item-body::after,
.job-list-item-body::after {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  color: #0068b5;
  position: absolute;
  right: calc(0.9525rem + 1.23vw);
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1200px) {
  .educations-list-item-body::after,
  .job-list-item-body::after {
    right: 1.875rem;
  }
}
.educations-list-item-headline,
.job-list-item-headline {
  font-size: calc(0.89rem + 0.48vw);
  color: #001a1a;
  font-weight: 500;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .educations-list-item-headline,
  .job-list-item-headline {
    font-size: 1.25rem;
  }
}
.educations-list-item-location,
.job-list-item-location {
  margin-top: 1rem;
  color: #3d3e3e;
  font-size: calc(0.8525rem + 0.03vw);
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
@media (min-width: 1200px) {
  .educations-list-item-location,
  .job-list-item-location {
    font-size: 0.875rem;
  }
}
.educations-list-item-location i,
.job-list-item-location i {
  color: #0068b5;
}
.educations-list .no-results,
.job-list .no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 1/-1;
}
.educations-list .no-results h3, .educations-list .no-results .h3,
.job-list .no-results h3,
.job-list .no-results .h3 {
  text-align: center;
  font-family: "DCC Ash";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 400;
  letter-spacing: 0.055rem;
  line-height: normal;
}
@media (min-width: 1200px) {
  .educations-list .no-results h3, .educations-list .no-results .h3,
  .job-list .no-results h3,
  .job-list .no-results .h3 {
    font-size: 2rem;
  }
}
@media (max-width: 991.98px) {
  .educations-list,
  .job-list {
    grid-template-columns: repeat(2, minmax(0, 366px));
  }
}
@media (max-width: 575.98px) {
  .educations-list,
  .job-list {
    grid-template-columns: 1fr;
  }
}

.bg-primary .pagination {
  color: #fff;
}
.bg-primary .pagination-arrows-prev, .bg-primary .pagination-arrows-next {
  color: #fff;
}

.pagination {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "prev pages next";
  margin-top: 2rem;
  color: #0068b5;
  align-items: center;
}
.pagination-arrows-prev, .pagination-arrows-next {
  justify-self: flex-end;
  color: #0068b5;
  line-height: 1;
  padding: 1rem;
}
.pagination-arrows-prev.disabled-arrow, .pagination-arrows-next.disabled-arrow {
  cursor: not-allowed;
  pointer-events: none;
  display: none;
}
.pagination-arrows-prev {
  grid-area: prev;
  justify-self: flex-end;
}
.pagination-arrows-next {
  grid-area: next;
  justify-self: flex-start;
}
.pagination-pages {
  grid-area: pages;
  justify-self: center;
  display: flex;
  font-size: 2rem;
  font-weight: 500;
  font-family: DCC Ash;
}
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 200px;
}

.custom-select {
  position: relative;
  cursor: pointer;
}

.custom-select-trigger {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: none;
  z-index: 10;
}

.custom-option {
  padding: 10px;
  cursor: pointer;
}

.custom-option:hover {
  background-color: #f1f1f1;
}

.read-more {
  position: relative;
  overflow: hidden;
  max-height: 300px;
}
.read-more.open {
  max-height: 100%;
}
.read-more.open .read-more-btn::before {
  display: none;
}
.read-more.open .read-more-btn::after {
  content: "\f077";
}
.read-more.only-mobile {
  max-height: 100%;
}
.read-more.only-mobile .read-more-btn {
  display: none;
}
@media (max-width: 575.98px) {
  .read-more.only-mobile {
    max-height: 150px;
  }
  .read-more.only-mobile.open {
    max-height: 100%;
  }
  .read-more.only-mobile .read-more-btn {
    display: block;
  }
}
.read-more-btn {
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: #ff6600;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}
.read-more-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  height: 3rem;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), #fff);
}
.read-more-btn::after {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  margin-left: 0.5rem;
}

.news-categories {
  padding-block: 0.75rem;
  padding-inline: 2rem;
  background-color: #0068b5;
  margin-bottom: 3rem;
  display: inline-flex;
}
.news-categories ul {
  flex: 1;
  list-style: none;
  display: inline-flex;
  gap: calc(1.165rem + 3.78vw);
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
}
@media (min-width: 1200px) {
  .news-categories ul {
    gap: 4rem;
  }
}
.news-categories ul li,
.news-categories ul li a {
  color: #fff;
  white-space: nowrap;
}
.news-categories ul li a {
  opacity: 0.5;
  font-size: calc(0.89rem + 0.48vw);
}
@media (min-width: 1200px) {
  .news-categories ul li a {
    font-size: 1.25rem;
  }
}
.news-categories ul li a.active {
  opacity: 1;
  font-weight: 900;
}
@media (max-width: 575.98px) {
  .news-categories {
    padding: 0;
    width: 100%;
  }
  .news-categories ul {
    flex-direction: column;
  }
}

.news-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: min-content;
  gap: 1.5rem;
}
.news-list .news-card {
  background-color: #fff;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
}
.news-list .news-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-list .news-card-body {
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.news-list .news-card-body-headline {
  font-size: calc(0.915rem + 0.78vw);
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .news-list .news-card-body-headline {
    font-size: 1.5rem;
  }
}
.news-list .news-card-body a {
  display: block;
  flex-grow: 1;
}
.news-list .news-card-body time {
  margin-top: 1rem;
  font-style: italic;
  font-size: 0.625rem;
  color: #3d3e3e;
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .news-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 575.98px) {
  .news-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.search-term {
  position: relative;
}
.search-term-toggle {
  cursor: pointer;
}
.search-term-input-group {
  background-color: #f7f8f9;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: calc(100% + 0.75rem + 3px);
  display: flex;
}
.search-term-input {
  background-color: #fff;
  border: solid 1px #0068b5;
  min-width: 150px;
  padding: 0.5rem;
  color: #0068b5;
}
.search-term-input::placeholder {
  color: #0068b5;
}
.search-term-input:focus {
  background-color: #fff;
  color: #0068b5;
}
@media (max-width: 575.98px) {
  .search-term-input-group {
    position: relative;
    padding: 0;
    background-color: transparent;
  }
  .search-term-input {
    border-right: none;
    border-top: none;
    border-left: none;
    border-bottom-color: #fff;
    min-width: 0;
    width: 100%;
    background-color: transparent;
  }
  .search-term-input::placeholder {
    color: #fff;
  }
}

.accordion.accordion-search {
  --bs-accordion-bg: #0068b5;
  --bs-accordion-color: #fff;
  --bs-accordion-active-color: #fff;
  --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E");
  margin-inline: 0;
  width: 100%;
  color: #fff;
}
.accordion.accordion-search .accordion-button {
  --bs-accordion-btn-color: #fff;
}
.accordion.accordion-search .accordion-body ul li {
  width: 100%;
}

.news-detail {
  padding-block: calc(1.115rem + 3.18vw);
}
@media (min-width: 1200px) {
  .news-detail {
    padding-block: 3.5rem;
  }
}
.news-detail-hero time {
  font-style: italic;
  padding-top: 1.5rem;
  display: block;
}
.news-detail-header {
  margin-bottom: calc(1.115rem + 3.18vw);
}
@media (min-width: 1200px) {
  .news-detail-header {
    margin-bottom: 3.5rem;
  }
}
.news-detail-header-title {
  margin-bottom: 0;
}
.news-detail-header-date {
  display: block;
  margin-bottom: 1rem;
  color: #666667;
  font-style: italic;
}
.news-detail-header-teaser-image {
  margin-bottom: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .news-detail-header-teaser-image {
    margin-bottom: 1.5rem;
  }
}
.news-detail-header-teaser-image img {
  width: 100%;
  object-fit: cover;
}
.news-detail-content {
  display: flex;
  flex-direction: column;
  gap: calc(1.115rem + 3.18vw);
}
@media (min-width: 1200px) {
  .news-detail-content {
    gap: 3.5rem;
  }
}

.job-detail-hero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 10rem min-content;
}
.job-detail-hero-background {
  grid-column: 1;
  grid-row: 1/span 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
  height: clamp(450px, 80vh, 940px);
}
.job-detail-hero-background .image,
.job-detail-hero-background img {
  height: 100%;
}
.job-detail-hero-background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.job-detail-hero .container {
  grid-column: 1;
  grid-row: 2/span 2;
  position: relative;
  z-index: 1;
}
.job-detail-hero-intro {
  min-height: 100%;
  text-align: center;
  background-image: url("~/public/img/bg-white.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-inline: calc(1.015rem + 1.98vw);
  padding-block: calc(1.165rem + 3.78vw);
}
@media (min-width: 1200px) {
  .job-detail-hero-intro {
    padding-inline: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .job-detail-hero-intro {
    padding-block: 4rem;
  }
}
.job-detail-hero-intro .headline,
.job-detail-hero-intro .headline-content {
  margin-bottom: 0;
}
.job-detail-hero-intro .headline-content {
  font-family: "DCC Ash";
  font-size: calc(1.165rem + 3.78vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .job-detail-hero-intro .headline-content {
    font-size: 4rem;
  }
}
.job-detail-hero-intro .headline-subline {
  font-family: "DCC Ash";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 400;
  letter-spacing: 0.055rem;
  line-height: normal;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .job-detail-hero-intro .headline-subline {
    font-size: 2rem;
  }
}
@media (max-width: 575.98px) {
  .job-detail-hero {
    padding-inline: 0;
    grid-template-rows: min-content 6rem min-content;
  }
  .job-detail-hero-intro {
    margin-inline: 1.5rem;
  }
  .job-detail-hero .container {
    padding-inline: 1.5rem;
  }
}
.job-detail-cards {
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-image: url("~/public/img/bg-section-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1399.98px) {
  .job-detail-cards {
    padding-top: calc(1.465rem + 7.38vw);
    padding-bottom: calc(1.465rem + 7.38vw);
  }
}
.job-detail-cards-headline {
  text-align: center;
  font-size: calc(1.04rem + 2.28vw);
  font-family: "DCC Ash";
  color: #001a1a;
  font-weight: 400;
  line-height: 1;
  margin-bottom: calc(0.98375rem + 1.605vw);
}
@media (min-width: 1200px) {
  .job-detail-cards-headline {
    font-size: 2.75rem;
  }
}
@media (min-width: 1200px) {
  .job-detail-cards-headline {
    margin-bottom: 2.1875rem;
  }
}
.job-detail-cards-item {
  background-color: #fff;
  padding: calc(1.065rem + 2.58vw);
  flex-grow: 1;
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1200px) {
  .job-detail-cards-item {
    padding: 3rem;
  }
}
.job-detail-cards-item-more {
  background-color: #fff;
  color: #ff6600;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-inline: calc(1.065rem + 2.58vw);
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}
@media (min-width: 1200px) {
  .job-detail-cards-item-more {
    padding-inline: 3rem;
  }
}
.job-detail-cards-item-more::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  height: 3rem;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), #fff);
}
.job-detail-cards-item ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.job-detail-cards-item ul li {
  padding-left: 2rem;
  position: relative;
}
.job-detail-cards-item ul li::before {
  content: "\f00c";
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  color: #0068b5;
  padding-right: 0.5rem;
}
.job-detail-video {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
@media (max-width: 1399.98px) {
  .job-detail-video {
    padding-top: calc(1.465rem + 7.38vw);
    padding-bottom: calc(1.465rem + 7.38vw);
  }
}
.job-detail-contact {
  padding-bottom: 7rem;
}
@media (max-width: 1399.98px) {
  .job-detail-contact {
    padding-bottom: calc(1.465rem + 7.38vw);
  }
}
.job-detail-gallery .swiper-slide {
  width: auto;
}
.job-detail-gallery .swiper-slide a,
.job-detail-gallery .swiper-slide .image,
.job-detail-gallery .swiper-slide img {
  width: 100%;
  height: 100%;
}
.job-detail-gallery .swiper-slide img {
  object-fit: cover;
}
.job-detail-cta-top {
  margin-top: -10px;
  position: relative;
  z-index: 1;
}
.job-detail-cta-bottom {
  margin-top: -10px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .job-detail-cta-top {
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 9;
    margin-top: -10px;
  }
  .job-detail-cta-bottom {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .job-detail-contact .job-contact-form {
    margin-inline: -1rem;
  }
}

.job-benefits {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
@media (max-width: 1399.98px) {
  .job-benefits {
    padding-top: calc(1.465rem + 7.38vw);
    padding-bottom: calc(1.465rem + 7.38vw);
  }
}
.job-benefits .headline {
  text-align: center;
  font-size: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .job-benefits .headline {
    font-size: 1.5rem;
  }
}
.job-benefits .headline-content {
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
}
@media (min-width: 1200px) {
  .job-benefits .headline-content {
    font-size: 2.5rem;
  }
}
.job-benefits-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
}
.job-benefits-item i {
  font-size: calc(1.065rem + 2.58vw);
  color: #0068b5;
}
@media (min-width: 1200px) {
  .job-benefits-item i {
    font-size: 3rem;
  }
}
.job-benefits-item p {
  margin-bottom: 0;
  font-size: calc(0.865rem + 0.18vw);
}
@media (min-width: 1200px) {
  .job-benefits-item p {
    font-size: 1rem;
  }
}

.job-steps .headline {
  text-align: center;
  margin-bottom: 1.5rem;
}
.job-steps .headline-content {
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
}
@media (min-width: 1200px) {
  .job-steps .headline-content {
    font-size: 2.5rem;
  }
}
.job-steps-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 18ch;
}
.job-steps-item i {
  font-size: calc(1.065rem + 2.58vw);
  margin-bottom: 1rem;
  color: #0068b5;
}
@media (min-width: 1200px) {
  .job-steps-item i {
    font-size: 3rem;
  }
}
.job-steps-item strong {
  color: #001a1a;
  font-size: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .job-steps-item strong {
    font-size: 1.5rem;
  }
}
.job-steps-item p {
  margin-bottom: 0;
  color: #001a1a;
  font-size: calc(0.865rem + 0.18vw);
}
@media (min-width: 1200px) {
  .job-steps-item p {
    font-size: 1rem;
  }
}

.job-contact-form {
  background-color: #0068b5;
  color: #fff;
  padding: 1.5rem;
}
.job-contact-form input {
  color: #fff;
}
.job-contact-form .headline-content {
  font-size: calc(1.04rem + 2.28vw);
  font-weight: 400;
  font-family: "DCC Ash";
}
@media (min-width: 1200px) {
  .job-contact-form .headline-content {
    font-size: 2.75rem;
  }
}
.job-contact-form-title {
  font-size: calc(0.89rem + 0.48vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  .job-contact-form-title {
    font-size: 1.25rem;
  }
}
.job-contact-form .submit {
  display: flex;
  justify-content: flex-end;
}
.job-contact-person {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.job-contact-person-data {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.job-contact-person-data i {
  color: #0068b5;
  padding-right: 0.6rem;
}
.job-contact-person-data .name {
  color: #0068b5;
  font-size: calc(0.915rem + 0.78vw);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
@media (min-width: 1200px) {
  .job-contact-person-data .name {
    font-size: 1.5rem;
  }
}

.hero-default {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 10rem min-content;
}
.hero-default.layout_1 .hero-default-intro {
  background-color: #fff;
}
.hero-default.layout_1 .headline-content {
  font-family: "Inter";
  font-size: calc(1.165rem + 3.78vw);
  font-weight: 700;
  line-height: 1;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .hero-default.layout_1 .headline-content {
    font-size: 4rem;
  }
}
.hero-default.layout_1 .headline-subline {
  font-family: "Inter";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 700;
  line-height: normal;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .hero-default.layout_1 .headline-subline {
    font-size: 2rem;
  }
}
.hero-default.layout_2 .hero-default-intro {
  background-image: url("~/public/img/bg-white.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.hero-default.layout_2 .headline-content {
  font-family: "DCC Ash";
  font-size: calc(1.165rem + 3.78vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .hero-default.layout_2 .headline-content {
    font-size: 4rem;
  }
}
.hero-default.layout_2 .headline-subline {
  font-family: "DCC Ash";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 400;
  letter-spacing: 0.055rem;
  line-height: normal;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .hero-default.layout_2 .headline-subline {
    font-size: 2rem;
  }
}
.hero-default-background {
  grid-column: 1;
  grid-row: 1/span 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
  height: clamp(450px, 80vh, 940px);
}
.hero-default-background .image,
.hero-default-background img {
  height: 100%;
}
.hero-default-background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: top;
}
.hero-default .container {
  grid-column: 1;
  grid-row: 2/span 2;
  align-self: center;
  position: relative;
  z-index: 1;
}
.hero-default-intro {
  min-height: 100%;
  padding-inline: calc(1.015rem + 1.98vw);
  padding-block: calc(1.015rem + 1.98vw);
}
@media (min-width: 1200px) {
  .hero-default-intro {
    padding-inline: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .hero-default-intro {
    padding-block: 2.5rem;
  }
}
.hero-default-intro .headline,
.hero-default-intro .headline-content {
  margin-bottom: 0;
}
.hero-default-intro .headline {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.hero-default-intro .headline-content {
  hyphens: auto;
}
.hero-default-intro .text-content {
  margin-top: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .hero-default-intro .text-content {
    margin-top: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .hero-default {
    padding-inline: 0;
    grid-template-rows: min-content 6rem min-content;
  }
  .hero-default-background {
    height: clamp(350px, 75vh, 500px);
  }
  .hero-default .container {
    padding-inline: 1.5rem;
  }
}

.hero-video {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 10rem min-content;
}
.hero-video-video {
  grid-column: 1;
  grid-row: 1/span 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
  height: clamp(450px, 80vh, 940px);
}
.hero-video .container {
  grid-column: 1;
  grid-row: 2/span 2;
  align-self: flex-end;
  position: relative;
  z-index: 1;
}
.hero-video-intro {
  background-color: #fff;
  min-height: 100%;
  padding-inline: calc(1.015rem + 1.98vw);
  padding-block: calc(1.015rem + 1.98vw);
}
@media (min-width: 1200px) {
  .hero-video-intro {
    padding-inline: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .hero-video-intro {
    padding-block: 2.5rem;
  }
}
.hero-video-intro .headline,
.hero-video-intro .headline-content {
  margin-bottom: 0;
}
.hero-video-intro .headline {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.hero-video-intro .headline-content {
  font-family: "Inter";
  font-size: calc(1.24rem + 4.68vw);
  font-weight: 700;
  line-height: 1;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .hero-video-intro .headline-content {
    font-size: 4.75rem;
  }
}
.hero-video-intro .headline-subline {
  font-family: "Inter";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 700;
  line-height: normal;
  color: #001a1a;
}
@media (min-width: 1200px) {
  .hero-video-intro .headline-subline {
    font-size: 2rem;
  }
}
.hero-video-intro .text-content {
  margin-top: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .hero-video-intro .text-content {
    margin-top: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .hero-video {
    padding-inline: 0;
    grid-template-rows: min-content 6rem min-content;
  }
  .hero-video-intro {
    margin-inline: 1.5rem;
  }
  .hero-video-video {
    height: clamp(350px, 75vh, 500px);
  }
}

.hero-filter {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 10rem min-content;
  padding-bottom: 7rem;
}
@media (max-width: 1399.98px) {
  .hero-filter {
    padding-bottom: calc(1.465rem + 7.38vw);
  }
}
.hero-filter .headline {
  text-align: center;
  margin-bottom: 3rem;
}
.hero-filter .headline-content {
  font-family: "DCC Ash";
  font-size: calc(1.165rem + 3.78vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  color: #fff;
}
@media (min-width: 1200px) {
  .hero-filter .headline-content {
    font-size: 4rem;
  }
}
.hero-filter-background {
  grid-column: 1;
  grid-row: 1/span 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
  height: clamp(450px, 80vh, 940px);
}
.hero-filter-background .image,
.hero-filter-background img {
  height: 100%;
}
.hero-filter-background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.hero-filter .container {
  grid-column: 1;
  grid-row: 2/span 2;
  position: relative;
  z-index: 1;
}
.hero-filter-form {
  background-color: #0068b5;
  color: #fff;
  min-height: 100%;
  padding: calc(1.015rem + 1.98vw);
  text-align: center;
  mask-image: url("~/public/img/bg-white.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
}
@media (min-width: 1200px) {
  .hero-filter-form {
    padding: 2.5rem;
  }
}
.hero-filter-form-headline {
  text-align: left;
  margin-bottom: calc(0.915rem + 0.78vw);
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
  color: #fff;
}
@media (min-width: 1200px) {
  .hero-filter-form-headline {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .hero-filter-form-headline {
    font-size: 2.5rem;
  }
}
.hero-filter-form form {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}
.hero-filter-form form .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath fill=%27none%27 stroke=%27%230068b5%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E");
}
.hero-filter-form form .form-control::placeholder {
  color: #0068b5;
}
.hero-filter-form form input[type=text] {
  height: 100%;
  width: 100%;
  color: #0068b5;
  padding: 0.375rem 0.75rem;
  border: 0;
}
.hero-filter-form form .form-select,
.hero-filter-form form .form-control {
  background-color: #fff;
  color: #0068b5;
  height: 100%;
}
.hero-filter-form form .form-control,
.hero-filter-form form .form-select,
.hero-filter-form form input[type=submit] {
  mask-image: url("~/public/img/bg-white.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
}
.hero-filter-form form input[type=submit]:hover {
  background-color: #e65c00;
  color: #fff;
}
.hero-filter-form form select:invalid,
.hero-filter-form form select option[value=""] {
  color: #d8d9da;
}
@media (max-width: 767.98px) {
  .hero-filter-form form {
    grid-template-columns: 1fr 1fr;
  }
  .hero-filter-form form .fieldset-searchterm,
  .hero-filter-form form input[type=submit] {
    grid-column: 1/span 2;
  }
}
@media (max-width: 575.98px) {
  .hero-filter {
    padding-inline: 0;
  }
  .hero-filter .container {
    padding-inline: 1.5rem;
  }
  .hero-filter-form form {
    grid-template-columns: 1fr;
  }
  .hero-filter-form form .fieldset-searchterm,
  .hero-filter-form form input[type=submit] {
    grid-column: 1;
  }
}

.filter-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.filter-tags-label {
  font-weight: 600;
  padding-right: 1rem;
  white-space: nowrap;
  padding-block: 0.5rem;
  align-self: flex-start;
}
.filter-tags-clear {
  cursor: pointer;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  background-color: #cfe7ff;
  mask-image: url("~/public/img/bg-white.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
  font-weight: 300;
}
.filter-tag {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  background-color: #cfe7ff;
  mask-image: url("~/public/img/bg-white.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
  color: #001a1a;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: calc(0.865rem + 0.18vw);
}
@media (min-width: 1200px) {
  .filter-tag {
    font-size: 1rem;
  }
}
.filter-tag-category {
  font-weight: 600;
}
.filter-tag-remove {
  cursor: pointer;
}

.card-numbers {
  padding-inline: calc(1.015rem + 1.98vw);
}
@media (min-width: 1200px) {
  .card-numbers {
    padding-inline: 2.5rem;
  }
}
.card-numbers .row > div:nth-child(odd):nth-child(3n+1) .card-numbers-card {
  background-color: #0068b5;
}
.card-numbers .row > div:nth-child(odd):nth-child(3n+2) .card-numbers-card {
  background-color: #8a9999;
}
.card-numbers .row > div:nth-child(odd):nth-child(3n) .card-numbers-card {
  background-color: #384e30;
}
.card-numbers .row > div:nth-child(even):nth-child(3n+1) .card-numbers-card {
  background-color: #384e30;
}
.card-numbers .row > div:nth-child(even):nth-child(3n+2) .card-numbers-card {
  background-color: #8a9999;
}
.card-numbers .row > div:nth-child(even):nth-child(3n) .card-numbers-card {
  background-color: #0068b5;
}
.card-numbers-card {
  padding: calc(0.965rem + 1.38vw);
  gap: calc(0.865rem + 0.18vw);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .card-numbers-card {
    padding: 2rem;
  }
}
@media (min-width: 1200px) {
  .card-numbers-card {
    gap: 1rem;
  }
}
.card-numbers-card-number {
  font-size: calc(1.265rem + 4.98vw);
  font-weight: 700;
  line-height: 1;
}
@media (min-width: 1200px) {
  .card-numbers-card-number {
    font-size: 5rem;
  }
}
.card-numbers-card-description {
  font-size: calc(0.915rem + 0.78vw);
  line-height: 1;
}
@media (min-width: 1200px) {
  .card-numbers-card-description {
    font-size: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .card-numbers {
    padding-inline: 0;
    margin-inline: -1rem;
  }
}

.video {
  display: flex;
}
.video iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.image-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.check-list ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.check-list ul li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-block: 0.5rem;
  font-size: calc(0.885rem + 0.42vw);
}
@media (min-width: 1200px) {
  .check-list ul li {
    font-size: 1.2rem;
  }
}
.check-list ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  color: #0068b5;
  align-self: flex-start;
}

.image-slider {
  position: relative;
}
.image-slider .swiper-slide {
  width: auto;
}
.image-slider .swiper-slide a,
.image-slider .swiper-slide .image,
.image-slider .swiper-slide img {
  width: 100%;
  height: 100%;
}
.image-slider .swiper-slide img {
  object-fit: cover;
}
@media (max-width: 575.98px) {
  .image-slider {
    margin-inline: -1rem;
  }
}

.image-text .headline-content {
  font-size: 1.25rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
}
@media (max-width: 575.98px) {
  .image-text .image {
    margin-inline: -1rem;
  }
}

.link-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 1.5rem;
}
.link-cards-card {
  background-color: #cfe7ff;
  height: 100%;
}
.link-cards-card .label {
  height: 100%;
  font-size: 1rem;
  font-weight: 700;
  color: #001a1a;
  padding-inline: 1.875rem;
  padding-block: 0.9375rem;
  display: flex;
  hyphens: auto;
  justify-content: space-between;
  align-items: center;
  line-break: strict;
}
.link-cards-card .link {
  height: 100%;
}
.link-cards-card .link a {
  height: 100%;
  font-size: 1rem;
  font-weight: 700;
  color: #001a1a;
  padding-inline: 1.875rem;
  padding-block: 0.9375rem;
  display: flex;
  hyphens: auto;
  justify-content: space-between;
  align-items: center;
  line-break: strict;
}
.link-cards-card .link a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  color: #0068b5;
}
@media (max-width: 767.98px) {
  .link-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .link-cards-card .link a {
    font-size: calc(0.8525rem + 0.03vw);
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .link-cards-card .link a {
    font-size: 0.875rem;
  }
}
@media (max-width: 575.98px) {
  .link-cards {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  .link-cards-card .label {
    justify-content: center;
    text-align: center;
  }
}

.accordion {
  --bs-accordion-bg: #cfe7ff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .accordion {
    gap: 1.5rem;
  }
}
.accordion-item {
  border: 0;
  align-self: flex-start;
}
.accordion-button {
  font-size: calc(0.8775rem + 0.33vw);
  font-weight: 700;
  line-height: 1.2;
  padding: 17px 20px;
  box-shadow: none;
}
@media (min-width: 1200px) {
  .accordion-button {
    font-size: 1.125rem;
  }
}
@media (max-width: 575.98px) {
  .accordion {
    grid-template-columns: 1fr;
    margin-inline: -1rem;
    gap: 0.5rem;
  }
}

.link-banner {
  text-align: center;
  padding-block: calc(1.115rem + 3.18vw);
}
@media (min-width: 1200px) {
  .link-banner {
    padding-block: 3.5rem;
  }
}
.link-banner .headline,
.link-banner .headline-content {
  margin-bottom: 1rem;
}
.link-banner .link {
  padding-top: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .link-banner .link {
    padding-top: 1.5rem;
  }
}

.job-multistep-filter {
  background-color: #0068b5;
  color: #fff;
}
.job-multistep-filter-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, min-content);
  grid-template-areas: "steps" "headline" "items";
  justify-content: center;
}
.job-multistep-filter .step-head {
  text-align: center;
  margin-bottom: 2rem;
}
.job-multistep-filter .step-head h3, .job-multistep-filter .step-head .h3 {
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
  color: #fff;
}
@media (min-width: 1200px) {
  .job-multistep-filter .step-head h3, .job-multistep-filter .step-head .h3 {
    font-size: 2.5rem;
  }
}
.job-multistep-filter .step-progress {
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-block: 3rem;
  display: flex;
}
.job-multistep-filter .step-progress .progress-bar {
  background-color: #fff;
}
.job-multistep-filter .step-body {
  display: grid;
  gap: calc(0.9525rem + 1.23vw);
  justify-content: center;
}
@media (min-width: 1200px) {
  .job-multistep-filter .step-body {
    gap: 1.875rem;
  }
}
.job-multistep-filter .step-body.grid-items-2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-2 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-3 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-3 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-4 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-4 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-5 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-5 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-6 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-6 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-7 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-7 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-8 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-8 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-9 {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-9 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-10 {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-10 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-11 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-11 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-12 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-12 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-13 {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-13 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-14 {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-14 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-15 {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-15 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-16 {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-16 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-17 {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-17 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-18 {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-18 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-19 {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-19 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-20 {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-20 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-21 {
  display: grid;
  grid-template-columns: repeat(11, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-21 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-22 {
  display: grid;
  grid-template-columns: repeat(11, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-22 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-23 {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-23 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-body.grid-items-24 {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .job-multistep-filter .step-body.grid-items-24 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.job-multistep-filter .step-item {
  background-color: #fff;
  color: #0068b5;
  mask-image: url("~/public/img/bg-card.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: calc(0.9775rem + 1.53vw);
  padding-block: calc(1.1025rem + 3.03vw);
  width: clamp(100px, 100%, 366px);
  text-align: center;
  position: relative;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
@media (min-width: 1200px) {
  .job-multistep-filter .step-item {
    padding-inline: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .job-multistep-filter .step-item {
    padding-block: 3.375rem;
  }
}
.job-multistep-filter .step-item.with-link {
  padding: 0;
}
.job-multistep-filter .step-item.with-link a {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  color: #0068b5;
  transition: color 0.2s ease-in-out;
  padding-inline: calc(0.9775rem + 1.53vw);
  padding-block: calc(1.1025rem + 3.03vw);
}
@media (min-width: 1200px) {
  .job-multistep-filter .step-item.with-link a {
    padding-inline: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .job-multistep-filter .step-item.with-link a {
    padding-block: 3.375rem;
  }
}
.job-multistep-filter .step-item:hover {
  background-color: #ff6600;
  color: #fff;
}
.job-multistep-filter .step-item:hover a {
  color: #fff;
}
.job-multistep-filter .step-item i {
  font-size: calc(1.065rem + 2.58vw);
}
@media (min-width: 1200px) {
  .job-multistep-filter .step-item i {
    font-size: 3rem;
  }
}
.job-multistep-filter .step-item p {
  hyphens: auto;
}
.job-multistep-filter .step-item label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0px;
  z-index: 2;
  cursor: pointer;
}
.job-multistep-filter .step-item input[type=radio] {
  display: none;
}
.job-multistep-filter .step-item input[type=radio]:checked + label {
  background-color: #0068b5;
  color: #fff;
}
.job-multistep-filter .step-buttons {
  display: grid;
  margin-top: 1rem;
}
.job-multistep-filter .step-buttons .submit-btn,
.job-multistep-filter .step-buttons .prev-btn,
.job-multistep-filter .step-buttons .next-btn {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  grid-row: 1;
  padding: 0;
}
.job-multistep-filter .step-buttons .submit-btn i,
.job-multistep-filter .step-buttons .prev-btn i,
.job-multistep-filter .step-buttons .next-btn i {
  font-size: 14px;
}
.job-multistep-filter .step-buttons .prev-btn {
  justify-self: start;
}
.job-multistep-filter .step-buttons .submit-btn,
.job-multistep-filter .step-buttons .next-btn {
  justify-self: end;
}
.job-multistep-filter-results .result-head {
  margin-bottom: 3rem;
  margin-bottom: calc(1.065rem + 2.58vw);
  text-align: center;
}
@media (min-width: 1200px) {
  .job-multistep-filter-results .result-head {
    margin-bottom: 3rem;
  }
}
.job-multistep-filter-results .result-head h3, .job-multistep-filter-results .result-head .h3 {
  font-family: "DCC Ash";
  font-size: calc(1.015rem + 1.98vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.055rem;
  color: #fff;
}
@media (min-width: 1200px) {
  .job-multistep-filter-results .result-head h3, .job-multistep-filter-results .result-head .h3 {
    font-size: 2.5rem;
  }
}
.job-multistep-filter-results .results-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.job-multistep-filter-results .results-buttons .btn-back {
  position: absolute;
  left: 0;
  color: #fff;
  white-space: nowrap;
}
.job-multistep-filter-results .results-buttons .btn-back i {
  font-size: 14px;
}
.job-multistep-filter-results .results-buttons .btn-group {
  gap: calc(1.065rem + 2.58vw);
}
@media (min-width: 1200px) {
  .job-multistep-filter-results .results-buttons .btn-group {
    gap: 3rem;
  }
}
@media (max-width: 767.98px) {
  .job-multistep-filter-results .results-buttons {
    flex-direction: column;
  }
  .job-multistep-filter-results .results-buttons .btn-back {
    margin-top: 2rem;
    position: relative;
  }
}
@media (max-width: 575.98px) {
  .job-multistep-filter .step-body.career_level, .job-multistep-filter .step-body.locations, .job-multistep-filter .step-body.fields_of_activity, .job-multistep-filter .step-body.business_divisions {
    grid-template-columns: repeat(2, 1fr);
  }
  .job-multistep-filter .step-item:hover {
    background-color: #fff;
    color: #0068b5;
  }
  .job-multistep-filter .step-item:hover a {
    color: #0068b5;
  }
}

.gallery .image,
.gallery .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.business-division {
  --item-height: 190px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  opacity: 0;
}
.business-division .item {
  height: var(--item-height);
  position: relative;
}
.business-division .item:hover:not(.item-none) .item-inner {
  z-index: 10;
  transform: scale(1.1) !important;
}
.business-division .item-inner {
  height: 100%;
  position: relative;
  z-index: 2;
  display: block;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transition: transform 0.2s ease-in-out;
}
.business-division .item-inner .item-key {
  font-size: calc(1.115rem + 3.18vw);
  font-weight: 900;
  color: #fff;
}
@media (min-width: 1200px) {
  .business-division .item-inner .item-key {
    font-size: 3.5rem;
  }
}
.business-division .item-inner .item-name {
  font-size: calc(0.865rem + 0.18vw);
  color: #fff;
}
@media (min-width: 1200px) {
  .business-division .item-inner .item-name {
    font-size: 1rem;
  }
}
.business-division .item-inner-logo, .business-division .item-inner-content, .business-division .item-inner-image {
  grid-column: 1;
  grid-row: 1;
}
.business-division .item-inner-image {
  height: var(--item-height);
  width: 100%;
  object-fit: cover;
}
.business-division .item-inner-logo {
  justify-self: center;
  align-self: center;
}
.business-division .item-inner-content {
  position: relative;
  z-index: 2;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.business-division .item svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 991.98px) {
  .business-division {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (max-width: 767.98px) {
  .business-division {
    --item-height: 130px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.25rem;
  }
  .business-division .item:hover .item-inner {
    transform: none !important;
  }
  .business-division .item-inner {
    position: relative;
    padding-inline: 25%;
  }
  .business-division .item-inner-image {
    display: none;
  }
  .business-division .item-inner::after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    color: #fff;
    position: absolute;
    right: 1.875rem;
    top: 50%;
    font-size: 1.5rem;
    transform: translateY(-50%);
  }
  .business-division .item.item-none {
    display: none;
  }
  .business-division .item.item-blue .item-inner {
    background-color: #0068b5;
  }
  .business-division .item.item-red .item-inner {
    background-color: #cc0000;
  }
  .business-division .item.item-green .item-inner {
    background-color: #384e30;
  }
}

.multistep-initiative-application-steps {
  background-color: #0068b5;
  color: #fff;
  padding-block: 7rem;
}
.multistep-initiative-application-steps .step-head {
  text-align: center;
  margin-bottom: 2rem;
}
.multistep-initiative-application-steps .step-head h3, .multistep-initiative-application-steps .step-head .h3 {
  font-family: "DCC Ash";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 400;
  letter-spacing: 0.055rem;
  line-height: normal;
  color: #fff;
}
@media (min-width: 1200px) {
  .multistep-initiative-application-steps .step-head h3, .multistep-initiative-application-steps .step-head .h3 {
    font-size: 2rem;
  }
}
.multistep-initiative-application-steps .step-progress {
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-block: 3rem;
  display: flex;
}
.multistep-initiative-application-steps .step-progress .progress-bar {
  background-color: #fff;
}
.multistep-initiative-application-steps .step-body {
  display: grid;
  gap: calc(0.9525rem + 1.23vw);
  justify-content: center;
}
@media (min-width: 1200px) {
  .multistep-initiative-application-steps .step-body {
    gap: 1.875rem;
  }
}
.multistep-initiative-application-steps .step-body.grid-items-2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-2 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-3 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-3 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-4 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-4 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-5 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-5 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-6 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-6 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-7 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-7 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-8 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-8 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-9 {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-9 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-10 {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-10 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-11 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-11 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-12 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-12 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-13 {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-13 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-14 {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-14 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-15 {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-15 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-16 {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-16 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-17 {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-17 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-18 {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-18 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-19 {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-19 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-20 {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-20 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-21 {
  display: grid;
  grid-template-columns: repeat(11, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-21 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-22 {
  display: grid;
  grid-template-columns: repeat(11, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-22 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-23 {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-23 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-body.grid-items-24 {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 260px));
  grid-auto-rows: 1fr;
}
@media (max-width: 991.98px) {
  .multistep-initiative-application-steps .step-body.grid-items-24 {
    grid-template-columns: repeat(2, minmax(150px, 260px));
  }
}
.multistep-initiative-application-steps .step-item {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0068b5;
  gap: 1rem;
  padding-inline: calc(0.9775rem + 1.53vw);
  padding-block: calc(1.1025rem + 3.03vw);
  width: clamp(100px, 100%, 366px);
  text-align: center;
  position: relative;
  mask-image: url("~/public/img/bg-card.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
}
@media (min-width: 1200px) {
  .multistep-initiative-application-steps .step-item {
    padding-inline: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .multistep-initiative-application-steps .step-item {
    padding-block: 3.375rem;
  }
}
.multistep-initiative-application-steps .step-item:hover {
  background-color: #ff6600;
  color: #fff;
}
.multistep-initiative-application-steps .step-item:hover a {
  color: #fff;
}
.multistep-initiative-application-steps .step-item i {
  font-size: calc(1.065rem + 2.58vw);
}
@media (min-width: 1200px) {
  .multistep-initiative-application-steps .step-item i {
    font-size: 3rem;
  }
}
.multistep-initiative-application-steps .step-item p {
  hyphens: auto;
}
.multistep-initiative-application-steps .step-item label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0px;
  z-index: 2;
  cursor: pointer;
}
.multistep-initiative-application-steps .step-item input[type=radio] {
  display: none;
}
.multistep-initiative-application-steps .step-buttons {
  display: grid;
  margin-top: 1rem;
}
.multistep-initiative-application-steps .step-buttons .submit-btn,
.multistep-initiative-application-steps .step-buttons .prev-btn,
.multistep-initiative-application-steps .step-buttons .next-btn {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  grid-row: 1;
  padding: 0;
}
.multistep-initiative-application-steps .step-buttons .submit-btn i,
.multistep-initiative-application-steps .step-buttons .prev-btn i,
.multistep-initiative-application-steps .step-buttons .next-btn i {
  font-size: 14px;
}
.multistep-initiative-application-steps .step-buttons .prev-btn {
  justify-self: start;
}
.multistep-initiative-application-steps .step-buttons .submit-btn,
.multistep-initiative-application-steps .step-buttons .next-btn {
  justify-self: end;
}
.multistep-initiative-application-results {
  background-color: #fff;
  color: #001a1a;
  padding-block: 7rem;
}
.multistep-initiative-application-results .results-form {
  background-color: #0068b5;
  color: #fff;
  padding: 1.5rem;
}
.multistep-initiative-application-results .results-form-headline {
  font-family: "DCC Ash";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 400;
  letter-spacing: 0.055rem;
  line-height: normal;
  color: #fff;
}
@media (min-width: 1200px) {
  .multistep-initiative-application-results .results-form-headline {
    font-size: 2rem;
  }
}
.multistep-initiative-application-results .results-form-intro {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.multistep-initiative-application-results .results-form-data ul {
  list-style: none;
  padding-left: 0;
}
.multistep-initiative-application-results .results-form .wrapper-start {
  display: flex;
  flex-direction: column;
}
.multistep-initiative-application-results .results-form .submit {
  display: grid;
}
.multistep-initiative-application-results .results-form .submit .btn-next {
  justify-self: flex-end;
  grid-row: 1;
}
.multistep-initiative-application-results .results-form .submit .btn-prev {
  justify-self: flex-start;
  grid-row: 1;
}
.multistep-initiative-application-results .results-form .submit button[type=submit] {
  justify-self: flex-end;
  grid-row: 1;
}
.multistep-initiative-application-results .results-form input[type=file] {
  color: #fff;
}
.multistep-initiative-application-results .result-head {
  margin-bottom: 3rem;
  text-align: center;
}
.multistep-initiative-application-results .result-head h3, .multistep-initiative-application-results .result-head .h3 {
  font-family: "DCC Ash";
  font-size: calc(0.965rem + 1.38vw);
  font-weight: 400;
  letter-spacing: 0.055rem;
  line-height: normal;
  color: #fff;
}
@media (min-width: 1200px) {
  .multistep-initiative-application-results .result-head h3, .multistep-initiative-application-results .result-head .h3 {
    font-size: 2rem;
  }
}
.multistep-initiative-application-results .results-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.multistep-initiative-application-results .results-buttons .btn-back {
  position: absolute;
  left: 0;
  color: #fff;
  white-space: nowrap;
}
.multistep-initiative-application-results .results-buttons .btn-back i {
  font-size: 14px;
}
.multistep-initiative-application-results .results-buttons .btn-group {
  gap: calc(1.065rem + 2.58vw);
}
@media (min-width: 1200px) {
  .multistep-initiative-application-results .results-buttons .btn-group {
    gap: 3rem;
  }
}
.multistep-initiative-application-results .results-contact-person {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.multistep-initiative-application-results .results-contact-person .contact-data i {
  color: #0068b5;
  padding-right: 0.6rem;
}
.multistep-initiative-application-results .results-contact-person .contact-data .name {
  color: #0068b5;
  font-size: calc(0.915rem + 0.78vw);
  font-weight: 600;
  margin-bottom: 2rem;
}
@media (min-width: 1200px) {
  .multistep-initiative-application-results .results-contact-person .contact-data .name {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .multistep-initiative-application .step-body.business_divisions {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 767.98px) {
  .multistep-initiative-application-results .results-buttons {
    flex-direction: column;
  }
  .multistep-initiative-application-results .results-buttons .btn-back {
    margin-top: 2rem;
    position: relative;
  }
  .multistep-initiative-application .step-body.business_divisions {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 575.98px) {
  .multistep-initiative-application .step-body.career_level, .multistep-initiative-application .step-body.locations, .multistep-initiative-application .step-body.fields_of_activity, .multistep-initiative-application .step-body.business_divisions {
    grid-template-columns: repeat(2, 1fr);
  }
}

.search-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  mask-image: url("~/public/img/button-fullwidth.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: top;
  background-color: #cc0000;
}
.search-bar-search {
  padding-block: calc(0.915rem + 0.78vw);
}
@media (min-width: 1200px) {
  .search-bar-search {
    padding-block: 1.5rem;
  }
}
.search-bar-search .form-group {
  flex: 1;
}
.search-bar-search .form-group .form-control {
  color: #fff;
  border-width: 3px;
  padding-block: 0.5rem;
  height: 100%;
}
.search-bar-search .form-group .form-control:focus {
  border-color: #fff;
}
.search-bar-search .btn {
  height: 100%;
  display: flex;
  align-items: center;
}
.search-bar-search .btn:hover {
  color: #fff !important;
}
.search-bar-cta {
  flex: 1;
}
.search-bar-cta a {
  padding-block: calc(0.915rem + 0.78vw);
  font-family: "DCC Ash";
  font-weight: 400;
  font-size: calc(1.04rem + 2.28vw);
  text-align: center;
  height: 100%;
  color: #fff;
  width: 100%;
}
@media (min-width: 1200px) {
  .search-bar-cta a {
    padding-block: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .search-bar-cta a {
    font-size: 2.75rem;
  }
}

.address-blocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content min-content;
  gap: 2rem;
}
.address-blocks-intro {
  grid-column: span 2;
}
.address-blocks .address-block {
  display: grid;
  grid-template-rows: subgrid;
  gap: 1rem;
  grid-row: span 1;
}
.address-blocks .address-block:has(ul) {
  grid-row: span 2;
}
.address-blocks .address-block-headline {
  font-size: calc(0.89rem + 0.48vw);
  font-weight: 700;
  grid-row: 1;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .address-blocks .address-block-headline {
    font-size: 1.25rem;
  }
}
.address-blocks .address-block ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media (max-width: 767.98px) {
  .address-blocks {
    grid-template-columns: 1fr;
  }
  .address-blocks-intro {
    grid-column: span 1;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #0068b5;
  color: #fff;
  padding: 1.5rem;
}
.contact-form-headline {
  font-family: "DCC Ash";
  font-size: calc(1.115rem + 3.18vw);
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .contact-form-headline {
    font-size: 3.5rem;
  }
}
.contact-form-intro {
  font-size: 0.8rem;
}
.contact-form form .row {
  gap: 0.75rem;
}
.contact-form .headline-content {
  font-size: calc(1.04rem + 2.28vw);
  font-weight: 400;
  font-family: "DCC Ash";
}
@media (min-width: 1200px) {
  .contact-form .headline-content {
    font-size: 2.75rem;
  }
}
.contact-form-title {
  font-size: calc(0.89rem + 0.48vw);
  font-weight: 600;
}
@media (min-width: 1200px) {
  .contact-form-title {
    font-size: 1.25rem;
  }
}
.contact-form .submit {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 575.98px) {
  .contact-form {
    margin-inline: -1rem;
    padding-inline: 1rem;
  }
}