.image-slider {
    position: relative;

    .swiper-slide {
        width: auto;

        a,
        .image,
        img {
            width: 100%;
            height: 100%;
        }

        img {
            object-fit: cover;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-inline: -1rem;
    }
}
