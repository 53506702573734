.hero-default {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 10rem min-content;

    &.layout_1 {
        .hero-default-intro {
            background-color: $white;
        }

        .headline-content {
            @include use-hero-default($black);
        }

        .headline-subline {
            @include use-hero-subline($black);
        }
    }

    &.layout_2 {
        .hero-default-intro {
            background-image: url($bg-white);
            background-size: cover;
            background-position: top;
            background-repeat: no-repeat;
        }

        .headline-content {
            @include use-hero-default-ash($black);
        }

        .headline-subline {
            @include use-hero-subline-ash($black);
        }
    }

    &-background {
        grid-column: 1;
        grid-row: 1 / span 2;
        @include object-fit(cover);
        height: clamp(450px, 80vh, 940px);

        .image,
        img {
            height: 100%;
        }

        img {
            @include object-fit(cover);
            object-position: top;
        }
    }

    .container {
        grid-column: 1;
        grid-row: 2 / span 2;
        align-self: center;
        position: relative;
        z-index: 1;
    }

    &-intro {
        min-height: 100%;
        @include rfs(2.5rem, padding-inline);
        @include rfs(2.5rem, padding-block);

        .headline,
        .headline-content {
            margin-bottom: 0;
        }

        .headline {
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        .headline-content {
            hyphens: auto;
        }

        .text-content {
            @include rfs(1.5rem, margin-top);
        }
    }

    @include media-breakpoint-down(sm) {
        padding-inline: 0;
        grid-template-rows: min-content 6rem min-content;

        &-intro {
            // margin-inline: 1.5rem;
        }

        &-background {
            height: clamp(350px, 75vh, 500px);
        }

        .container {
            padding-inline: 1.5rem;
        }
    }
}
