.text-boxed {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    // @include font-size(1.125rem);

    .headline {
        margin-bottom: 0;
    }

    .headline .headline-content {
        text-align: center;
        margin-bottom: 0;

        &.headline-section-ash {
            @include use-section-ash();
        }

        &.headline-section {
            @include use-section();
        }
    }
}
