.news-detail {
    @include rfs(3.5rem, padding-block);

    &-hero {
        time {
            font-style: italic;
            padding-top: 1.5rem;
            display: block;
        }
    }

    &-header {
        @include rfs(3.5rem, margin-bottom);

        &-title {
            margin-bottom: 0;
        }

        &-date {
            display: block;
            margin-bottom: 1rem;
            color: $gray-700;
            font-style: italic;
        }

        &-teaser-image {
            @include rfs(1.5rem, margin-bottom);

            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        @include rfs(3.5rem, gap);
    }
}
