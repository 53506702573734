.bg-primary {
    .pagination {
        color: $white;

        &-arrows {
            &-prev,
            &-next {
                color: $white;
            }
        }
    }
}

.pagination {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'prev pages next';
    margin-top: 2rem;
    color: $primary;
    align-items: center;

    &-arrows {
        &-prev,
        &-next {
            justify-self: flex-end;
            color: $primary;
            line-height: 1;
            padding: 1rem;

            &.disabled-arrow {
                cursor: not-allowed;
                pointer-events: none;
                display: none;
            }
        }

        &-prev {
            grid-area: prev;
            justify-self: flex-end;
        }

        &-next {
            grid-area: next;
            justify-self: flex-start;
        }
    }

    &-pages {
        grid-area: pages;
        justify-self: center;
        display: flex;
        font-size: 2rem;
        font-weight: 500;
        font-family: DCC Ash;

        &-current {
        }

        &-total {
        }
    }
}
