.btn-custom {
    display: flex;
    border-image-source: url($button-border);
    border-image-repeat: stretch;
    border-image-slice: 1;
    border-style: inset;
    border-width: 0.2rem;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;

    a {
        // padding: 0.5rem 1.5rem;
        @include rfs(0.5rem, padding-block);
        @include rfs(1.5rem, padding-inline);
        color: $primary;
        transition: color 0.3s ease-in-out;
        font-weight: 300;
    }

    &:hover {
        background-color: $primary;

        a {
            color: #fff;
        }
    }
}
