.educations-list,
.job-list {
    display: grid;
    @include rfs(1.875rem, gap);
    grid-template-columns: repeat(3, minmax(0, 366px));
    grid-auto-rows: 1fr;

    &-item {
        background-color: $light-blue;
        mask-image: url($bg-card);
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: top;
        position: relative;
        display: flex;
        flex-direction: column;

        &-head {
            img {
                min-height: 120px;
                width: 100%;
                object-fit: cover;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            @include rfs(1.875rem, padding-block);
            @include rfs(1.875rem, padding-left);
            @include rfs(4rem, padding-right);
            position: relative;

            &::after {
                content: '\f054';
                font-family: 'Font Awesome 5 Pro';
                color: $primary;
                position: absolute;
                @include rfs(1.875rem, right);
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &-headline {
            @include font-size(1.25rem);
            color: $black;
            font-weight: 500;
            margin-bottom: 0;
        }

        &-location {
            margin-top: 1rem;
            color: #3d3e3e;
            @include font-size(0.875rem);
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            i {
                color: $primary;
            }
        }
    }

    .no-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-column: 1 / -1;

        h3 {
            text-align: center;
            @include use-hero-subline-ash();
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, minmax(0, 366px));
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
}
