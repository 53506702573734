.business-division {
    --item-height: 190px;

    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    opacity: 0;

    .item {
        height: var(--item-height);
        position: relative;

        &:hover:not(.item-none) {
            .item-inner {
                z-index: 10;
                transform: scale(
                    1.1
                ) !important; // !important is needed to override the transition property added by anime.js
            }
        }

        &-inner {
            height: 100%;
            position: relative;
            z-index: 2;
            display: block;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            transition: transform $transition-length ease-in-out;

            .item-key {
                @include font-size(3.5rem);
                font-weight: 900;
                color: $white;
            }

            .item-name {
                @include font-size(1rem);
                color: $white;
            }

            &-logo,
            &-content,
            &-image {
                grid-column: 1;
                grid-row: 1;
            }

            &-image {
                height: var(--item-height);
                width: 100%;
                object-fit: cover;
            }

            &-logo {
                justify-self: center;
                align-self: center;
            }

            &-content {
                position: relative;
                z-index: 2;
                align-self: center;
                justify-self: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include media-breakpoint-down(md) {
        --item-height: 130px;

        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0.25rem;

        .item {
            &:hover {
                .item-inner {
                    transform: none !important;
                }
            }

            &-inner {
                position: relative;
                padding-inline: 25%;

                &-image {
                    display: none;
                }

                &::after {
                    content: '\f054';
                    font-family: 'Font Awesome 5 Pro';
                    color: $white;
                    position: absolute;
                    right: 1.875rem;
                    top: 50%;
                    font-size: 1.5rem;
                    transform: translateY(-50%);
                }
            }

            &.item-none {
                display: none;
            }

            &.item-blue {
                .item-inner {
                    background-color: $primary;
                }
            }

            &.item-red {
                .item-inner {
                    background-color: $red;
                }
            }

            &.item-green {
                .item-inner {
                    background-color: $green;
                }
            }
        }
    }
}
