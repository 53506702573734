.job-benefits {
    @include spacing(padding, y, map-get($map: $spacers, $key: section));

    .headline {
        text-align: center;
        @include rfs(1.5rem);

        &-content {
            @include use-section-ash();
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 1rem;

        i {
            @include font-size(3rem);
            color: $primary;
        }

        p {
            margin-bottom: 0;
            @include font-size(1rem);
        }
    }
}
