.job-steps {
    .headline {
        text-align: center;
        margin-bottom: map-get($map: $spacers, $key: lg);

        &-content {
            @include use-section-ash();
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        max-width: 18ch;

        i {
            @include font-size(3rem);
            margin-bottom: 1rem;
            color: $primary;
        }

        strong {
            color: $body-color;
            @include font-size(1.5rem);
        }

        p {
            margin-bottom: 0;
            color: $body-color;
            @include font-size(1rem);
        }
    }
}
