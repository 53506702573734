.address-blocks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content min-content;
    gap: 2rem;

    &-intro {
        grid-column: span 2;
    }

    .address-block {
        display: grid;
        grid-template-rows: subgrid;
        gap: 1rem;
        grid-row: span 1;

        &:has(ul) {
            grid-row: span 2;
        }

        &-headline {
            @include font-size(1.25rem);
            font-weight: 700;
            grid-row: 1;
            margin-bottom: 0;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            grid-row: 2;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;

        &-intro {
            grid-column: span 1;
        }
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: $primary;
    color: $white;
    padding: 1.5rem;

    &-headline {
        font-family: 'DCC Ash';
        @include font-size(3.5rem);
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.1125rem;
        margin-bottom: 0;
    }

    &-intro {
        @include font-size(0.8rem);
    }

    form .row {
        gap: 0.75rem;
    }

    .headline-content {
        @include font-size(2.75rem);
        font-weight: 400;
        font-family: 'DCC Ash';
    }

    &-title {
        @include font-size(1.25rem);
        font-weight: 600;
    }

    .submit {
        display: flex;
        justify-content: flex-end;
    }

    @include media-breakpoint-down(sm) {
        margin-inline: -1rem;
        padding-inline: 1rem;
    }
}
