.search-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    mask-image: url($bg-button-fullwidth);
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: top;
    background-color: $red;

    &-search {
        @include rfs(1.5rem, padding-block);

        .form-group {
            flex: 1;

            .form-control {
                color: $white;
                border-width: 3px;
                padding-block: 0.5rem;
                height: 100%;

                &:focus {
                    border-color: $white;
                }
            }
        }

        .btn {
            height: 100%;
            display: flex;
            align-items: center;

            &:hover {
                color: $white !important; // upps
            }
        }
    }

    &-cta {
        flex: 1;
        a {
            @include rfs(1.5rem, padding-block);
            font-family: 'DCC Ash';
            font-weight: 400;
            @include font-size(2.75rem);
            text-align: center;
            height: 100%;
            color: $white;
            width: 100%;
        }
    }
}
