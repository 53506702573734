.btn {
    --bs-btn-border-width: 1px;
    --bs-btn-border-radius: 0;
    @include use-button-label();
    @include rfs($btn-padding-y, padding-block);
    @include rfs($btn-padding-x, padding-inline);
}

.btn-white {
    --bs-btn-color: #{$primary};
    --bs-btn-bg: #{$white};
    --bs-btn-border-color: #{$white};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$white};
    --bs-btn-focus-shadow-rgb: #{$white};
    --bs-btn-active-color: #{$white};
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #{$white};
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: #{$gray-200};
    --bs-btn-disabled-border-color: #{$gray-200};
}

.btn-white-primary {
    --bs-btn-color: #{$primary};
    --bs-btn-bg: #{$white};
    --bs-btn-border-color: #{$white};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$white};
    --bs-btn-focus-shadow-rgb: #{$white};
    --bs-btn-active-color: #{$white};
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #{$white};
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: #{$gray-200};
    --bs-btn-disabled-border-color: #{$gray-200};
}

.btn-white-secondary {
    --bs-btn-color: #{$secondary};
    --bs-btn-bg: #{$white};
    --bs-btn-border-color: #{$white};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$white};
    --bs-btn-focus-shadow-rgb: #{$white};
    --bs-btn-active-color: #{$secondary};
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #{$white};
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: #{$gray-200};
    --bs-btn-disabled-border-color: #{$gray-200};
}

.btn-white-green {
    --bs-btn-color: #{$green};
    --bs-btn-bg: #{$white};
    --bs-btn-border-color: #{$white};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$white};
    --bs-btn-focus-shadow-rgb: #{$white};
    --bs-btn-active-color: #{$green};
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #{$white};
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: #{$gray-200};
    --bs-btn-disabled-border-color: #{$gray-200};
}

.btn-secondary {
    --bs-btn-color: #{$white};
    --bs-btn-bg: #{$secondary};
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #{$secondary};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$secondary};
    --bs-btn-focus-shadow-rgb: #{$white};
    --bs-btn-active-color: #{$white};
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #{$secondary};
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: #{$gray-200};
    --bs-btn-disabled-border-color: #{$gray-200};
}

// Variant outline
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant(
            $value,
            $color-hover: $btn-color,
            $active-background: $value,
            $active-border: $value,
            $active-color: $btn-color
        );
    }
}

.btn-group {
    display: flex;
    gap: 1rem;
}

.button-fullwidth {
    width: 100%;
    mask-image: url($bg-button-fullwidth);
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: top;
    display: flex;

    &.bg-light-blue,
    &.bg-light-gray {
        a.ash,
        a.inter {
            color: $black;
        }
    }

    a {
        flex: 1;
        text-align: center;
        @include rfs(2.1875rem, padding-block);
        line-height: 1;
        color: $white;
        @include font-size(2.75rem);
        display: block;
    }

    a.ash {
        font-family: 'DCC Ash';
        font-weight: 400;
        mask-image: url($bg-white);
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: top;
    }

    a.inter {
        font-family: 'Inter';
        font-weight: 700;
    }
}

.link.with-graphic {
    .btn {
        mask-image: url($bg-button);
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: top;
    }
}
